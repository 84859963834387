import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { GammaShortStrategy__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useCachedPrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { useChainId } from '../network'

type DepositToStrategyParams = {
  strategyId: number
  initialMarginAmount: BigNumberish
  initialPerpAmount: BigNumberish
  initialSquartAmount: BigNumberish
  priceThreshold: BigNumberish
}

export function useInitializeStrategy(pairId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const deadline = useDeadline()
  const chainId = useChainId()
  const price = useCachedPrice(chainId, pairId)
  const addresses = useAddresses(chainId)

  return useMutation(async (params: DepositToStrategyParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!chainId) throw new Error('chainId not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')

    const contract = GammaShortStrategy__factory.connect(
      addresses.GammaShortStrategy,
      provider.getSigner()
    )

    return await contract.depositForPositionInitialization(
      params.strategyId,
      pairId,
      params.initialMarginAmount,
      params.initialPerpAmount,
      params.initialSquartAmount,
      {
        lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
        upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
        deadline: deadline.data
      },
      params.priceThreshold
    )
  })
}

type UpdateGammaParams = {
  strategyId: number
  squartAmount: BigNumberish
}

export function useUpdateGamma(pairId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const deadline = useDeadline()
  const chainId = useChainId()
  const price = useCachedPrice(chainId, pairId)
  const addresses = useAddresses(chainId)

  return useMutation(async (params: UpdateGammaParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!chainId) throw new Error('chainId not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')

    const contract = GammaShortStrategy__factory.connect(
      addresses.GammaShortStrategy,
      provider.getSigner()
    )

    return await contract.updateGamma(params.strategyId, params.squartAmount, {
      lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
      upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
      deadline: deadline.data
    })
  })
}



type RefreshStrategyParams = {
  strategyId: number
  ratio: BigNumberish
}

export function useRefreshStrategy(pairId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const deadline = useDeadline()
  const chainId = useChainId()
  const price = useCachedPrice(chainId, pairId)
  const addresses = useAddresses(chainId)

  return useMutation(async (params: RefreshStrategyParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!chainId) throw new Error('chainId not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')

    const contract = GammaShortStrategy__factory.connect(
      addresses.GammaShortStrategy,
      provider.getSigner()
    )

    return await contract.refresh(params.strategyId, params.ratio, {
      lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
      upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
      deadline: deadline.data
    })
  })
}
