import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Router, Route, Redirect } from 'wouter'
import { useEagerConnect } from './hooks/wallet'
import AssetView from './pages/AssetView'
import VaultView from './pages/VaultView'
import LendingView from './pages/LendingView'
import ManageStrategyView from './pages/ManageStrategyView'
import MainView from './pages/MainView'
import IsolatedView from './pages/IsolatedView'
import TradeStrategyView from './pages/TradeStrategyView'
import ProductListView from './pages/ProductListView'
import FaucetView from './pages/FaucetView'
import PortfolioView from './pages/PortfolioView'
import LoadingIndicator from './components/common/LoadingIndicator'
import NetworkErrorLabel from './components/common/NetworkErrorLabel'
import Header from './components/header/Header'
import { ApolloProvider } from '@apollo/client'
import { PredyClient } from './utils/apollo-client'
import { DEFAULT_CHAIN } from './constants'
import { useChainId, useIsSupportedChain } from './hooks/network'
import UnsupportedNetwork from './components/UnsupportedNetwork'
import NotFound from './components/NotFound'

const App = () => {
  const [loaded, setLoaded] = useState(false)

  const chainId = useChainId()
  const supported = useIsSupportedChain()

  const client = useMemo(() => PredyClient[chainId || DEFAULT_CHAIN], [chainId])

  useEffect(() => {
    ; (async () => {
      // check residence once. if value is set to true, proceed
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const tried = useEagerConnect()
  if (!tried || !loaded) return <LoadingIndicator />

  return (
    <ApolloProvider client={client || PredyClient[DEFAULT_CHAIN]}>
      <div>
        <NetworkErrorLabel />
        <Router>
          <Header />
          {supported ? (
            <div className="mx-auto mt-20">
              <Switch>
                <Route path="/">
                  <Redirect to="/arbitrum/trade/usdce/main/1" />
                </Route>

                <Route path="/arbitrum">
                  <Redirect to="/arbitrum/trade/usdce/main/1" />
                </Route>

                <Route path="/base">
                  <Redirect to="/base/trade/usdbc/main/1" />
                </Route>

                <Route path="/trade/:pairGroup/main/:pairId">
                  <Redirect to="/arbitrum/trade/usdce/main/1" />
                </Route>
                <Route path="/trade/:pairGroup/isolated/:pairId">
                  <Redirect to="/arbitrum/trade/usdce/isolated/1" />
                </Route>
                <Route path="/trade/:pairGroup/lending/:pairId">
                  <Redirect to="/arbitrum/trade/usdce/lending/1" />
                </Route>
                <Route path="/trade/:pairGroup/strategy/:pairId">
                  <Redirect to="/arbitrum/trade/usdce/strategy/1" />
                </Route>

                <Route path="/manage/asset" component={AssetView} />
                <Route path="/:chain/manage/strategy" component={ManageStrategyView} />
                <Route path="/vaults/:vaultId" component={VaultView} />
                <Route path="/faucet" component={FaucetView} />

                <Route path="/:chain/trade/:pairGroup/lending/:pairId" component={LendingView} />
                <Route path="/:chain/trade/:pairGroup/main/:pairId" component={MainView} />
                <Route path="/:chain/trade/:pairGroup/isolated/:pairId" component={IsolatedView} />
                <Route path="/:chain/trade/:pairGroup/strategy/:strategyId" component={TradeStrategyView} />


                <Route path="/trade/:pairGroup/lending/:pairId" component={LendingView} />
                <Route path="/trade/:pairGroup/main/:pairId" component={MainView} />
                <Route path="/trade/:pairGroup/isolated/:pairId" component={IsolatedView} />
                <Route path="/trade/:pairGroup/strategy/:strategyId" component={TradeStrategyView} />
                <Route path="/:chain/portfolio/:pairGroup" component={PortfolioView} />
                <Route path="/:chain/products" component={ProductListView} />
                <Route path="/:rest*" ><NotFound /></Route>
              </Switch>
            </div>
          ) : (<UnsupportedNetwork />)}
        </Router>
      </div>
    </ApolloProvider >
  )
}

export default App
