export enum AssetType {
  WETH = 1,
  WBTC = 2
}

export enum TradeSymbol {
  PERP = 'PERP',
  SQRT = 'SQRT',
  FEE = 'Fee',
  MARGIN = 'Margin',
  LIQUIDATION = 'Liquidation',
  UNDEFINED = '-'
}

export enum TradeSide {
  BUY = 'Buy',
  SELL = 'Sell',
  UNDEFINED = '-'
}

export enum Action {
  TRADE = 'TRADE',
  DEPOSIT = 'DEPOSIT_MARGIN',
  WITHDRAW = 'WITHDRAW_MARGIN',
  LIQUIDATE = 'LIQUIDATE'
}

export enum Product {
  PERP = 'PERP',
  SQRT = 'SQRT'
}

export enum ChartRange {
  Hour,
  Day,
  Week,
  Month,
  Year
}

export enum MyPositionTab {
  Positions = 0,
  TradeHistory = 1
}

export enum PositionStyle {
  Bearish25,
  Neutral,
  Bullish25
}
