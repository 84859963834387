import React from 'react'
import ChartTop from './ChartTop'
import PnLChartWrapper from './PnLChartWrapper'
import { AssetSelector } from './AssetSelector'

const CenterArea = ({
  chainId,
  pairGroupId,
  vaultId,
  assetId,
  isIsolated
}: {
  chainId: number
  pairGroupId: number
  vaultId: number
  assetId: number
  isIsolated?: boolean
}) => {
  return (
    <div>
      <ChartTop chainId={chainId} assetId={assetId}>
        <AssetSelector
          chainId={chainId}
          pairGroupId={pairGroupId}
          pairId={assetId}
          isIsolated={isIsolated || false}
        />
      </ChartTop>
      <PnLChartWrapper
        chainId={chainId}
        assetId={assetId}
        vaultId={vaultId}
        showAfterTraded
      />
    </div>
  )
}

export default CenterArea
