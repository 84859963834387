import React from 'react'
import { BigNumber } from 'ethers'
import { toUnscaled } from '../../utils/bn'
import { toTimeString } from '../../utils/string'
import { PairScalers } from '../../utils/helpers/scaler'
import { usePairContext } from '../../hooks/usePairContext'
import { ASSET_INFOS } from '../../constants/assets'

export const PositionTable = ({
  children,
  fontSize,
  isHistory
}: {
  children?: React.ReactNode
  fontSize?: string
  isHistory: boolean
}) => {
  return (
    <table>
      <thead
        className={`block font-normal text-left text-sm md:${
          fontSize || 'text-base'
        }`}
      >
        <tr className="h-8">
          <th className="w-40 pb-2">Time</th>
          <th className="w-36 pb-2">Symbol</th>
          <th className="w-36 pb-2">Side</th>
          <th className="w-36 pb-2">Price</th>
          <th className="w-36 pb-2">Quantity</th>
          <th className="w-36 pb-2">
            <span className="hidden md:block">
              {isHistory ? 'Profit Realized' : 'Profit Unrealized'}
            </span>
            <span className="block md:hidden">PnL</span>
          </th>
        </tr>
      </thead>
      {children}
    </table>
  )
}

type OpenPositionTableProps = {
  chainId: number
  assetId: number
  tradeAmount: BigNumber
  sqrtTradeAmount: BigNumber
  entryPrice: BigNumber
  squartEntryPrice: BigNumber
  perpPayoff?: BigNumber
  sqrtPayoff?: BigNumber
  unrealizedFee?: BigNumber
  perpUpdatedAt: number
  squartUpdatedAt: number
  fontSize?: string
}

export const OpenPositionTable = ({
  chainId,
  assetId,
  tradeAmount,
  sqrtTradeAmount,
  entryPrice,
  squartEntryPrice,
  perpPayoff,
  sqrtPayoff,
  unrealizedFee,
  perpUpdatedAt,
  squartUpdatedAt,
  fontSize
}: OpenPositionTableProps) => {
  const scalers = usePairContext(chainId, assetId)
  const pairInfo = ASSET_INFOS[chainId][assetId]

  return (
    <PositionTable fontSize={fontSize} isHistory={false}>
      <tbody className="block text-xs">
        <tr className="h-8">
          <td className="w-40">{toTimeString(perpUpdatedAt)}</td>
          <td className="w-36">{pairInfo.name}</td>
          <td className="w-36">{tradeAmount.gt(0) ? 'Buy' : 'Sell'}</td>
          <td className="w-36">
            {tradeAmount.eq(0) || !scalers.isSuccess
              ? '-'
              : scalers.data.toPriceString(entryPrice)}
          </td>
          <td className="w-36">
            {scalers.isSuccess
              ? scalers.data.toUnderlyingString(tradeAmount.abs(), {
                  showFull: true,
                  symbolRequired: false
                })
              : '-'}
          </td>
          <td className="w-36">
            {scalers.isSuccess ? scalers.data.toMarginString(perpPayoff) : '-'}
          </td>
        </tr>
        <tr className="h-8">
          <td>{toTimeString(squartUpdatedAt)}</td>
          <td>√{pairInfo.name}</td>
          <td>{sqrtTradeAmount.gt(0) ? 'Buy' : 'Sell'}</td>
          <td>
            {sqrtTradeAmount.eq(0) || !scalers.isSuccess
              ? '-'
              : scalers.data.toPriceString(squartEntryPrice)}
          </td>
          <td>
            {scalers.isSuccess
              ? scalers.data.toSquartString(sqrtTradeAmount.mul(2).abs(), {
                  showFull: true,
                  symbolRequired: false
                })
              : '-'}
          </td>
          <td>
            {scalers.isSuccess ? scalers.data.toMarginString(sqrtPayoff) : '-'}
          </td>
        </tr>
        <tr className="h-8">
          <td>
            {perpUpdatedAt > squartUpdatedAt
              ? toTimeString(perpUpdatedAt)
              : toTimeString(squartUpdatedAt)}
          </td>
          <td>Fee</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>
            {scalers.isSuccess
              ? scalers.data.toMarginString(unrealizedFee)
              : '-'}
          </td>
        </tr>
      </tbody>
    </PositionTable>
  )
}

type HistoryTableProps = {
  blockExplorerLink: string
  enableScroll: boolean
  scalers: PairScalers
  fontSize: string
  items: {
    symbol: string
    side: string
    size: string
    price: BigNumber
    payoff: BigNumber
    timestamp: number
    txHash: string
  }[]
}

export const HistoryTable = ({
  blockExplorerLink,
  enableScroll,
  scalers,
  fontSize,
  items
}: HistoryTableProps) => {
  return (
    <PositionTable fontSize={fontSize} isHistory={true}>
      <tbody
        className={`block ${
          enableScroll ? 'overflow-x-hidden overflow-y-scroll h-40' : ''
        } text-xs`}
      >
        {items.map((item, i) => {
          return (
            <tr key={i} className="h-8">
              <td className="w-40">
                <a
                  href={`https://${blockExplorerLink}/tx/${item.txHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {toTimeString(item.timestamp)}
                </a>
              </td>
              <td className="w-36">{item.symbol}</td>
              <td className="w-36">{item.side}</td>
              <td className="w-36">{scalers.toPriceString(item.price)}</td>
              <td className="w-36">{item.size}</td>
              <td className="w-36">
                {toUnscaled(item.payoff, scalers.marginDecimals)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </PositionTable>
  )
}
