import React from 'react'
import { BigNumber } from 'ethers'
import { usePairContext } from '../../hooks/usePairContext'
import { PairBadge } from '../common/PairBadge'
import { AiOutlineExport } from 'react-icons/ai'
import { Link } from 'wouter'
import { useFeeEst } from '../../hooks/query/interest/useFeeEst'
import { ZERO } from '../../constants'
import { Position } from '../../utils/helpers/position'
import { ASSET_INFOS } from '../../constants/assets'
import { useGreeks } from '../../hooks/query/useGreeks'

export const TradePosition = ({
  chainId,
  isolated,
  pairId,
  vaultId,
  position,
  utilizing,
  available
}: {
  chainId: number
  isolated: boolean
  pairId: number
  vaultId: number
  position: Position
  utilizing: BigNumber
  available: BigNumber
}) => {
  const feeEst = useFeeEst(chainId, pairId, [vaultId], ZERO, ZERO)
  const pairContext = usePairContext(chainId, pairId)
  const pairInfo = ASSET_INFOS[chainId][pairId]
  const greeks = useGreeks(chainId, pairId, position)

  return (
    <div className="flex justify-between justify-items-start items-center text-left">
      <div className="w-[260px] flex justify-start">
        <PairBadge chainId={chainId} assetInfo={pairInfo} />
      </div>
      <div className="w-40">
        {position.squart.eq(0)
          ? '-'
          : position.squart.gt(0)
          ? 'Short Strangle'
          : 'Long Strangle'}
      </div>
      <div className="w-40">
        {pairContext.isSuccess ? pairContext.data.toMarginString(utilizing) : 0}
      </div>
      <div className="w-40">
        {pairContext.isSuccess ? pairContext.data.toMarginString(available) : 0}
      </div>
      <div className="w-40">{greeks.data?.delta || 0}</div>
      <div className="w-40">{greeks.data?.gamma || 0}</div>
      <div className="w-40">
        {pairContext.isSuccess && feeEst.isSuccess
          ? pairContext.data.toMarginString(feeEst.data?.total)
          : '-'}
      </div>
      <div className="w-32">
        <Link
          href={`/trade/${pairInfo.pairGroupId}/${
            isolated ? 'isolated' : 'main'
          }/${pairId}`}
        >
          <AiOutlineExport className="cursor-pointer" />
        </Link>
      </div>
    </div>
  )
}
