import React, { useCallback, useState } from 'react'
import { toUnscaled } from '../../utils/bn'
import { useAsset, useUtilizationRatio } from '../../hooks/query/useAsset'
import { TickMath } from '@uniswap/v3-sdk'
import { BigNumber, ethers } from 'ethers'
import { Q96 } from '../../constants'
import { getAmountsForLiquidity } from '../../utils/uniswap'
import { usePrice } from '../../hooks/usePrice'
import { useRebalanceHistory } from '../../hooks/query/useRebalanceHistory'
import { toTimeString } from '../../utils/string'
import { toPriceString } from '../../utils/number'
import { useAddPair } from '../../hooks/contracts/useAddPair'
import { PrimaryButton } from '../common/Button'
import { useReallocate } from '../../hooks/contracts/useReallocate'
import { computePrice } from '../../utils/helpers/position'
import {
  useUpdateAssetRiskParams,
  useUpdateIRMParams
} from '../../hooks/contracts/useUpdateAssetRiskParams'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'
import { PairScalers } from '../../utils/helpers/scaler'
import { useRebalanceCounts } from '../../hooks/query/useRebalanceCounts'
import { useChainId } from '../../hooks/network'
import { useWeb3Provider } from '../../hooks/useWeb3Provider'

function tickToPrice(tick: number, scalers: PairScalers) {
  const sqrtPrice = TickMath.getSqrtRatioAtTick(tick)

  return computePrice(BigNumber.from(sqrtPrice.toString()), scalers).div(Q96)
}

const DEFAULT_IRM_PARAMS = {
  baseRate: BigNumber.from('10000000000000000'),
  kinkRate: BigNumber.from('900000000000000000'),
  slope1: BigNumber.from('40000000000000000'),
  slope2: BigNumber.from('1600000000000000000')
}

const MEDIUM_IRM_PARAMS = {
  baseRate: BigNumber.from('10000000000000000'),
  kinkRate: BigNumber.from('850000000000000000'),
  slope1: BigNumber.from('56000000000000000'),
  slope2: BigNumber.from('1600000000000000000')
}

const HIGH_IRM_PARAMS = {
  baseRate: BigNumber.from('60000000000000000'),
  kinkRate: BigNumber.from('800000000000000000'),
  slope1: BigNumber.from('140000000000000000'),
  slope2: BigNumber.from('2000000000000000000')
}

const DEFAULT_PAIR_RISK_PARAMS = {
  riskRatio: BigNumber.from('109544511'),
  rangeSize: 720,
  rebalanceThreshold: 360
}

const AssetInfo = ({
  chainId,
  assetId,
  decimals
}: {
  chainId: number
  assetId: number
  decimals: number
}) => {
  const asset = useAsset(chainId, assetId)
  const utilization = useUtilizationRatio(chainId, assetId)
  const pairInfo = ASSET_INFOS[chainId][assetId]

  if (!asset.isSuccess || !utilization.isSuccess) {
    return <div>loading</div>
  }
  return (
    <div className="p-2 flex justify-center items-center">
      <div className="p-2 w-1/4 text-right">{pairInfo.name}</div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(utilization.data.underlying.supply, decimals, 3)}
      </div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(utilization.data.underlying.borrow, decimals, 3)}
      </div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(utilization.data.underlying.utilization, 16, 2)}%
      </div>
    </div>
  )
}

const AssetSquartInfo = ({
  chainId,
  assetId
}: {
  chainId: number
  assetId: number
}) => {
  const asset = useAsset(chainId, assetId)
  const utilization = useUtilizationRatio(chainId, assetId)
  const pairInfo = ASSET_INFOS[chainId][assetId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  if (!asset.isSuccess || !utilization.isSuccess) {
    return <div>loading</div>
  }

  const scalers = new PairScalers(assetId, chainId)

  const symbol = pairInfo.name + '/' + marginInfo.name

  return (
    <div className="flex justify-center items-center">
      <div className="p-2 w-1/4 text-right">{symbol}</div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(
          utilization.data.sqrt.supply.mul(2),
          scalers.squartDecimals,
          2
        )}
      </div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(
          utilization.data.sqrt.borrow.mul(2),
          scalers.squartDecimals,
          2
        )}
      </div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(utilization.data.sqrt.utilization, 16, 2)}%
      </div>
    </div>
  )
}

const AssetSquartExtraInfo = ({
  chainId,
  assetId,
  count
}: {
  chainId: number
  assetId: number
  count: number
}) => {
  const asset = useAsset(chainId, assetId)
  const sqrtPrice = usePrice(chainId, assetId)
  const pairInfo = ASSET_INFOS[chainId][assetId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  if (!asset.isSuccess || !sqrtPrice.isSuccess) {
    return <div>loading</div>
  }

  const symbol = pairInfo.name + '/' + marginInfo.name

  const scalers = new PairScalers(assetId, chainId)

  const amounts = getAmountsForLiquidity(
    sqrtPrice.data.sqrtPrice,
    BigNumber.from(
      TickMath.getSqrtRatioAtTick(
        asset.data.sqrtAssetStatus.tickLower
      ).toString()
    ),
    BigNumber.from(
      TickMath.getSqrtRatioAtTick(
        asset.data.sqrtAssetStatus.tickUpper
      ).toString()
    ),
    asset.data.sqrtAssetStatus.totalAmount.sub(
      asset.data.sqrtAssetStatus.borrowedAmount
    )
  )

  return (
    <div className="flex justify-center items-center">
      <div className="p-2 w-1/4 text-right">{symbol}</div>
      <div className="p-2 w-1/4 text-right">
        <div>
          {toUnscaled(
            tickToPrice(asset.data.sqrtAssetStatus.tickLower, scalers),
            scalers.marginDecimals,
            pairInfo.fractionDigits
          )}
          -
          {toUnscaled(
            tickToPrice(asset.data.sqrtAssetStatus.tickUpper, scalers),
            scalers.marginDecimals,
            pairInfo.fractionDigits
          )}
        </div>
      </div>
      <div className="p-2 w-1/4 text-right">
        <div>
          {toUnscaled(
            tickToPrice(
              asset.data.sqrtAssetStatus.tickLower +
                asset.data.riskParams.rebalanceThreshold,
              scalers
            ),
            scalers.marginDecimals,
            pairInfo.fractionDigits
          )}
          -
          {toUnscaled(
            tickToPrice(
              asset.data.sqrtAssetStatus.tickUpper -
                asset.data.riskParams.rebalanceThreshold,
              scalers
            ),
            scalers.marginDecimals,
            pairInfo.fractionDigits
          )}
        </div>
      </div>

      <div className="p-2 w-1/4 text-right">
        {toPriceString(toUnscaled(amounts[1], 6, 0))} USDC,{' '}
        {toUnscaled(amounts[0], 18, 3)} ETH
      </div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(
          asset.data.sqrtAssetStatus.rebalancePositionStable.positionAmount,
          scalers.marginDecimals,
          2
        )}
        ,
        {toUnscaled(
          asset.data.sqrtAssetStatus.rebalancePositionUnderlying.positionAmount,
          scalers.underlyingDecimals,
          3
        )}
      </div>
      <div className="p-2 w-1/4 text-right">{count}</div>
    </div>
  )
}

const AssetSquartRebalanceHistory = () => {
  const chainId = useChainId()
  const rebalanceHistory = useRebalanceHistory(chainId)

  if (rebalanceHistory === null) {
    return <div>loading</div>
  }

  return (
    <div>
      {rebalanceHistory.map((item, i) => {
        const pairId = item.pairId
        const scalers = new PairScalers(pairId, chainId)
        const pairInfo = ASSET_INFOS[chainId][pairId]
        const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]
        const symbol = pairInfo.name + '/' + marginInfo.name

        return (
          <div key={i} className="flex justify-center items-center">
            <div className="p-2 w-1/4 text-right">{symbol}</div>
            <div className="p-2 w-1/4 text-right">
              {toTimeString(item.createdAt)}
            </div>
            <div className="p-2 w-1/4 text-right">
              {toPriceString(
                toUnscaled(
                  tickToPrice(item.tickLower, scalers),
                  marginInfo.decimals,
                  pairInfo.fractionDigits
                )
              )}
              -
              {toPriceString(
                toUnscaled(
                  tickToPrice(item.tickUpper, scalers),
                  marginInfo.decimals,
                  pairInfo.fractionDigits
                )
              )}
            </div>
            <div className="p-2 w-1/4 text-right">
              {toUnscaled(
                item.profit,
                marginInfo.decimals,

                2
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const RISK_RATIO_2000 = '109544511'
const RISK_RATIO_1000 = '104880884'
const RANGE_SIZE_120 = 120
const RANGE_SIZE_220 = 220
const RANGE_SIZE_490 = 480

const AssetSummary = ({ chainId }: { chainId: number }) => {
  const web3provider = useWeb3Provider(chainId)
  const addPair = useAddPair()
  const updateAssetRiskParams = useUpdateAssetRiskParams()
  const updateIRMParams = useUpdateIRMParams()
  const rebalanceCounts = useRebalanceCounts(chainId)

  const reallocate = useReallocate()
  const [uniswapPool, setUniswapPool] = useState(ethers.constants.AddressZero)
  const [isIsolatedMode, setIsIsolatedMode] = useState(true)

  const onAddPair = useCallback(async () => {
    if (!web3provider.isSuccess) {
      return
    }
    await addPair.mutateAsync({
      pairGroupId: 3,
      uniswapPool: '0x35218a1cbac5bbc3e57fd9bd38219d37571b3537',
      poolOwner: web3provider.data.account,
      isIsolatedMode,
      assetRiskParams: {
        riskRatio: BigNumber.from(RISK_RATIO_2000),
        rangeSize: 60,
        rebalanceThreshold: 30
      },
      stableIrmParams: DEFAULT_IRM_PARAMS,
      underlyingIrmParams: DEFAULT_IRM_PARAMS,
      fee: 0
    })
  }, [addPair, isIsolatedMode])

  const onUpdateAssetRiskParams = useCallback(async () => {
    await updateAssetRiskParams.mutateAsync({
      pairId: 14,
      riskParams: {
        riskRatio: BigNumber.from(RISK_RATIO_2000),
        rangeSize: 2,
        rebalanceThreshold: 1
      },
      changeToIsolatedMode: false
    })
  }, [updateAssetRiskParams])

  const onUpdateIRMParams = useCallback(async () => {
    await updateIRMParams.mutateAsync({
      pairId: 6,
      stableIrmParams: HIGH_IRM_PARAMS,
      underlyingIrmParams: HIGH_IRM_PARAMS
    })
  }, [updateIRMParams])

  const onReallocate = useCallback(async () => {
    await reallocate.mutateAsync(1)
  }, [reallocate])

  return (
    <div className="m-10 rounded-xl bg-white1">
      <div className="p-6 flex flex-wrap justify-between items-center space-x-0">
        <div className="w-32">Asset Summary</div>
      </div>

      <div className="flex justify-center items-center">
        <div className="w-80 space-y-2">
          <div className="px-2 flex justify-between">
            <span>Uniswap Pool</span>
            <input
              type="text"
              className="text-black"
              value={uniswapPool}
              onChange={e => {
                setUniswapPool(e.target.value)
              }}
            />
          </div>
          <div className="px-2 flex justify-between">
            <span>Isolated</span>
            <input
              type="checkbox"
              checked={isIsolatedMode}
              onChange={e => {
                setIsIsolatedMode(e.target.checked)
              }}
            />
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onAddPair} disabled={false}>
              Add Pair
            </PrimaryButton>
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onReallocate} disabled={false}>
              reallocate
            </PrimaryButton>
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onUpdateAssetRiskParams} disabled={false}>
              Update Risk Params
            </PrimaryButton>
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onUpdateIRMParams} disabled={false}>
              Update IRM Params
            </PrimaryButton>
          </div>
        </div>
      </div>

      <div className="p-10">
        <h2 className="text-lg">Utilization</h2>
        <div className="flex justify-center items-center">
          <div className="p-2 w-1/4 text-right">Name</div>
          <div className="p-2 w-1/4 text-right">Supply</div>
          <div className="p-2 w-1/4 text-right">Borrow</div>
          <div className="p-2 w-1/4 text-right">Utilization</div>
        </div>
        <AssetInfo chainId={chainId} assetId={1} decimals={18} />
        <AssetSquartInfo chainId={chainId} assetId={1} />
      </div>

      <div className="p-10">
        <h2 className="text-lg">LP Position</h2>
        <div className="flex justify-center items-center">
          <div className="p-2 w-1/4 text-right">Name</div>
          <div className="p-2 w-1/4 text-right">Range(Threshold)</div>
          <div className="p-2 w-1/4 text-right">Required Asset</div>
          <div className="p-2 w-1/4 text-right">Locked Funds</div>
          <div className="p-2 w-1/4 text-right">Rebalance Funds</div>
        </div>

        <AssetSquartExtraInfo
          chainId={chainId}
          assetId={1}
          count={rebalanceCounts.data?.get(1) || 0}
        />
      </div>

      <div className="p-10">
        <h2 className="text-lg">Rebalance History</h2>
        <div className="flex justify-center items-center">
          <div className="p-2 w-1/4 text-right">Name</div>
          <div className="p-2 w-1/4 text-right">Time</div>
          <div className="p-2 w-1/4 text-right">Range</div>
          <div className="p-2 w-1/4 text-right">PnL</div>
        </div>
        <AssetSquartRebalanceHistory />
      </div>
    </div>
  )
}

export default AssetSummary
