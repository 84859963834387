import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { metaMask } from '../../connectors'
import ArbitrumIcon from '../../assets/chain/arbitrum.svg'
import BaseIcon from '../../assets/chain/base.svg'
import { usePrevious } from 'react-use'
import { switchNetwork } from '../../utils/switchNetwork'
import { ChainDropdownMenu } from './ChainDropdownMenu'
import { SUPPORTED_CHAIN_IDS } from '../../constants'
import { chainIdToName } from '../../hooks/useTradeRoute'
import strategyStore from '../../store/strategy'
import { useLocation } from 'wouter'
import { AiOutlineUp, AiOutlineDown } from 'react-icons/ai'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

export const HeaderConnectButton = ({
  chainId,
  children
}: {
  chainId: number
  children?: React.ReactNode
}) => {
  return (
    <div className="flex justify-center space-x-2">
      <div className="w-16 h-8 rounded-md border-[1px] border-white">
        <NetworkButton specifiedChainId={chainId} />
      </div>
      <div className="w-32 md:w-40 h-8 rounded-md border-[1px] border-white">
        <BaseConnectButton specifiedChainId={chainId}>
          {children}
        </BaseConnectButton>
      </div>
    </div>
  )
}

export const ConnectButton = ({
  chainId,
  children
}: {
  chainId: number
  children?: React.ReactNode
}) => {
  return (
    <>
      <button className="w-full h-10 final-gradient rounded-md text-xl font-normal">
        <BaseConnectButton specifiedChainId={chainId}>
          {children}
        </BaseConnectButton>
      </button>
    </>
  )
}

const BaseConnectButton = ({
  specifiedChainId,
  children
}: {
  specifiedChainId: number
  children?: React.ReactNode
}) => {
  const { chainId, account, isActive } = useWeb3React()
  const [activateClicked, setActivateClicked] = useState(false)
  const prevClicked = usePrevious(activateClicked)
  const isSupported = specifiedChainId === chainId

  useEffect(() => {
    if (!prevClicked && activateClicked && isActive && account) {
      // To tract user connection, please put code here
      localStorage.setItem('predy.connected', 'true')
    }
  }, [activateClicked, isActive]) // eslint-disable-line

  const onConnectClicked = async () => {
    await switchNetwork(specifiedChainId)
    await metaMask.activate()
    // await activate(injected)
    setActivateClicked(true)
  }

  return (
    <>
      <div className="w-full h-full px-2 background-black text-white flex flex-row justify-center items-center text-sm text-center">
        {isActive && account && isSupported ? (
          <>
            <Jazzicon diameter={22} seed={jsNumberForAddress(account)} />
            <div className="hidden md:block basis-5/6 my-auto">
              {account.slice(0, 6)}…{account.slice(38)}
            </div>
            <div className="block md:hidden basis-5/6 my-auto">
              {account.slice(0, 4)}…{account.slice(40)}
            </div>
          </>
        ) : (
          <div className="my-auto cursor-pointer " onClick={onConnectClicked}>
            {isSupported
              ? children
                ? children
                : 'Connect Wallet'
              : 'Switch Network'}
          </div>
        )}
      </div>
    </>
  )
}

const NetworkButton = ({ specifiedChainId }: { specifiedChainId: number }) => {
  const { selectStrategyId } = strategyStore()
  const [, setLocation] = useLocation()

  // dropdown menu
  const [walletMenuOpened, setWalletMenuOpened] = useState(false)
  const [isMouseOnMenu, setIsMouseOnMenu] = useState(false)

  return (
    <>
      <div
        className="w-full h-full px-2 background-black text-white flex flex-row items-center text-sm"
        onClick={() => {
          setWalletMenuOpened(!walletMenuOpened)
        }}
        onMouseEnter={() => {
          setWalletMenuOpened(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setWalletMenuOpened(false)
          }, 200)
        }}
      >
        <img
          className="mr-1 basis-3/6 my-auto cursor-pointer"
          width={24}
          src={
            specifiedChainId === SUPPORTED_CHAIN_IDS[0]
              ? ArbitrumIcon
              : BaseIcon
          }
        />
        {walletMenuOpened || isMouseOnMenu ? (
          <AiOutlineUp className="basis-3/6 my-auto pointer-events-none" />
        ) : (
          <AiOutlineDown className="basis-3/6 my-auto pointer-events-none" />
        )}
      </div>
      <ChainDropdownMenu
        isShow={walletMenuOpened || isMouseOnMenu}
        onMouseEnter={() => {
          setIsMouseOnMenu(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsMouseOnMenu(false)
          }, 200)
        }}
        onSelect={chainTag => {
          if (chainIdToName(specifiedChainId) !== chainTag) {
            selectStrategyId(1)
            setLocation(`/${chainTag}`)
          }
        }}
      />
    </>
  )
}
