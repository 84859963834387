import React, { ReactNode } from 'react'

type Props = {
  leverage: number
  onChange: (leverage: number) => void
  maxLeverage: number
}

const LeverageFormButton = ({
  selected,
  children,
  onClick
}: {
  selected: boolean
  children: ReactNode
  onClick: () => void
}) => {
  return (
    <button
      className={`w-7 h-7 rounded-lg border-[1px] bg-primary text-base ${
        selected ? 'border-white' : 'border-primary'
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const LeverageForm = ({ leverage, onChange, maxLeverage }: Props) => {
  if (maxLeverage === 6) {
    return (
      <div className="flex justify-between gap-2">
        <LeverageFormButton
          selected={leverage === 1}
          onClick={() => {
            onChange(1)
          }}
        >
          1x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 2}
          onClick={() => {
            onChange(2)
          }}
        >
          2x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 3}
          onClick={() => {
            onChange(3)
          }}
        >
          3x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 4}
          onClick={() => {
            onChange(4)
          }}
        >
          4x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 5}
          onClick={() => {
            onChange(5)
          }}
        >
          5x
        </LeverageFormButton>
      </div>
    )
  } else {
    return (
      <div className="flex justify-between gap-2">
        <LeverageFormButton
          selected={leverage === 1}
          onClick={() => {
            onChange(1)
          }}
        >
          1x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 4}
          onClick={() => {
            onChange(4)
          }}
        >
          4x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 6}
          onClick={() => {
            onChange(6)
          }}
        >
          6x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 8}
          onClick={() => {
            onChange(8)
          }}
        >
          8x
        </LeverageFormButton>
        <LeverageFormButton
          selected={leverage === 10}
          onClick={() => {
            onChange(10)
          }}
        >
          10x
        </LeverageFormButton>
      </div>
    )
  }
}
