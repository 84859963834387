import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ReferenceLine,
  Legend
} from 'recharts'
import { toGreeksAmount } from '../../../utils/number'

export enum ChartMode {
  Value,
  Delta,
  Gamma
}

function createTrianglePath(x: number, y: number) {
  return `${x},${y} ${x - 5},${y + 8} ${x + 5},${y + 8}`
}

type Prop = {
  price: number
  style: number
  data: { price: number; value: number }[]
  mode: ChartMode
  hasCurrentPosition: boolean
  showAfterTraded: boolean
}

export const PnLChart = ({
  price,
  style,
  data,
  mode,
  hasCurrentPosition,
  showAfterTraded
}: Prop) => {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dataDefs = (payload as { dataKey: string; value: number }[]).map(
        p => ({
          name: p.dataKey,
          value: p.value
        })
      )

      // sort by value
      dataDefs.sort((a: any, b: any) => b.value - a.value)

      return (
        <div className="text-xs rounded-lg p-1">
          {dataDefs.map((dataDef, i) => {
            return (
              <div key={i} className="text-white text-xs p-1">
                <p>
                  {dataDef.name.indexOf('After') > 0
                    ? 'After Created'
                    : 'Current Position'}
                  : {toGreeksAmount(dataDef.value)}
                </p>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const CustomizedLabel = (params: any) => {
    const x = params.viewBox.x
    const y = params.viewBox.y + 260

    const points = createTrianglePath(x, y)

    return (
      <g>
        <polygon points={points} fill={params.content.props.fill} />
      </g>
    )
  }

  const CustomizedXAxisTick = (props: any) => {
    const x = props.x || 0
    const y = props.y || 0
    const payload = props.payload

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          x={0}
          y={0}
          dy={14}
          textAnchor="middle"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {payload ? payload.value.toLocaleString() : ''}
        </text>
      </g>
    )
  }

  const CustomizedYAxisTick = (props: any) => {
    const x = props.x || 0
    const y = props.y || 0
    const payload = props.payload

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={3}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {payload ? payload.value : ''}
        </text>
      </g>
    )
  }

  const CustomizedCursor = (props: any) => {
    const { points, payload } = props

    const triangle = createTrianglePath(points[1].x, points[1].y)

    return (
      <g>
        <path
          d={`M ${points[0].x} ${points[0].y} L ${points[1].x} ${points[1].y}`}
          stroke="#ccc"
        ></path>
        <polygon points={triangle} fill="#ccc" />
        <text
          x={points[1].x + 10}
          y={points[1].y + 17}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={10}
        >
          ${payload && payload.length > 0 ? payload[0].payload.price : 0}
        </text>
      </g>
    )
  }

  const CustomizedLegend = (props: any) => {
    const { payload } = props

    return (
      <ul>
        {payload.map((entry: any, index: number) => (
          <li
            key={`item-${index}`}
            className={`my-2 flex justify-start items-center space-x-1`}
            style={{ color: entry.color }}
          >
            <div
              className="w-1 h-1 p-1 rounded-full"
              style={{ backgroundColor: entry.color }}
            ></div>
            <p>{entry.value}</p>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div>
      <div className="my-1 flex items-center justify-end text-[11px] space-x-2 py-1 pr-40">
        <div className="rounded-full bg-green w-2 h-2"></div>
        <div>Current Price</div>
        <div className="rounded-full bg-blue w-2 h-2"></div>
        <div>Target Price</div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid stroke="#4c525f" />
          <XAxis
            type="number"
            dataKey="price"
            tickCount={19}
            interval={3}
            tickLine={false}
            domain={['dataMin', 'dataMax']}
            tick={<CustomizedXAxisTick />}
          />
          <YAxis
            type="number"
            tickCount={5}
            tick={<CustomizedYAxisTick />}
            tickLine={false}
          />
          <Tooltip cursor={<CustomizedCursor />} content={<CustomTooltip />} />
          <Legend
            iconType="circle"
            layout={'vertical'}
            verticalAlign="middle"
            align="right"
            iconSize={10}
            wrapperStyle={{ right: -5 }}
            content={<CustomizedLegend />}
          />
          <ReferenceLine
            x={style}
            stroke="#5259FA"
            strokeDasharray="5 5"
            opacity={0.5}
            label={
              <CustomizedLabel price={style} fill="#7279FA">
                Strike Price
              </CustomizedLabel>
            }
          />
          <ReferenceLine
            x={price || 0}
            stroke="#04ECA6"
            strokeDasharray="5 5"
            opacity={0.5}
            label={
              <CustomizedLabel price={price} fill="#04ECA6">
                Current Price
              </CustomizedLabel>
            }
          />
          {hasCurrentPosition ? (
            <Line
              type="monotone"
              name="Current Position"
              dataKey={
                mode === ChartMode.Value
                  ? 'value'
                  : mode === ChartMode.Delta
                  ? 'delta'
                  : 'gamma'
              }
              stroke="#55F28A"
              strokeWidth={1}
              dot={false}
            />
          ) : (
            <></>
          )}
          {showAfterTraded ? (
            <Line
              type="monotone"
              name="After Traded"
              dataKey={
                mode === ChartMode.Value
                  ? 'valueAfter'
                  : mode === ChartMode.Delta
                  ? 'deltaAfter'
                  : 'gammaAfter'
              }
              stroke="#8526FF"
              strokeWidth={1}
              dot={false}
            />
          ) : (
            <></>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PnLChart
