import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { IRMParams } from '../../utils/irm'
import { useChainId } from '../network'

type UpdateAssetRiskParams = {
  pairId: number
  riskParams: {
    riskRatio: BigNumberish
    rangeSize: BigNumberish
    rebalanceThreshold: BigNumberish
  }
  changeToIsolatedMode: boolean
}

export function useUpdateAssetRiskParams() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const addresses = useAddresses(chainId)

  return useMutation(async (params: UpdateAssetRiskParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.updateAssetRiskParams(
      params.pairId,
      params.riskParams,
      params.changeToIsolatedMode
    )
  })
}

type UpdateIRMParams = {
  pairId: number
  stableIrmParams: IRMParams
  underlyingIrmParams: IRMParams
}

export function useUpdateIRMParams() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const addresses = useAddresses(chainId)

  return useMutation(async (params: UpdateIRMParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.updateIRMParams(
      params.pairId,
      params.stableIrmParams,
      params.underlyingIrmParams
    )
  })
}
