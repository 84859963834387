import React from 'react'
import { toUnscaled } from '../../utils/bn'
import { useStrategySummary } from '../../hooks/query/strategy/useStrategySummary'
import { useStrategyApr } from '../../hooks/query/strategy/useStrategyApr'
import { BigNumber } from 'ethers'
import { Link } from 'wouter'
import { useLendingSummary } from '../../hooks/query/lending/useLendingSummary'
import { AiOutlineExport } from 'react-icons/ai'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  MARGIN_INFO_COUNTS,
  STRATEGY_INFOS
} from '../../constants/assets'

const AprComponent = ({ apr }: { apr: BigNumber }) => {
  return (
    <td
      className={`w-12 text-center align-middle ${
        apr.gt(0) ? 'text-green' : 'text-red'
      }`}
    >
      {apr.gt(0) ? '+' : '-'}
      {toUnscaled(apr.abs(), 16, 3)}%
    </td>
  )
}

const StrategyListItem = ({
  chainId,
  strategyId
}: {
  chainId: number
  strategyId: number
}) => {
  const apr = useStrategyApr(chainId, strategyId)
  const summary = useStrategySummary(chainId, strategyId)
  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  if (!summary.isSuccess) {
    return (
      <tr>
        <td>...</td>
      </tr>
    )
  }

  return (
    <tr className="my-1 px-2 space-x-2">
      <td className="w-12 px-2">
        <img
          className="mx-auto w-8 rounded-full"
          src={marginInfo.icon}
          width={30}
        />
        <div className="text-center">{marginInfo.name}</div>
      </td>
      <td className="w-12 px-2">
        <img
          className="mx-auto w-8 rounded-full"
          src={strategyInfo.icon}
          width={30}
        />
        <div className="text-center">{strategyInfo.name}</div>
      </td>
      <AprComponent apr={apr.dayApr} />
      <AprComponent apr={apr.yearApr} />
      <td className="w-[108px] align-middle text-center">
        {(summary.data?.price || 0).toFixed(3)} {marginInfo.name}
      </td>
      <td className="w-8 align-middle text-center">
        {summary.data.nextHedgeIn.hours}H {summary.data.nextHedgeIn.minutes}m
      </td>
      <td className="w-[120px] align-middle text-center">
        {summary.data.lowerPrice.toFixed(pairInfo.fractionDigits)},{' '}
        {summary.data.upperPrice.toFixed(pairInfo.fractionDigits)}
      </td>
      <td className="w-16 align-middle text-center">
        <Link
          href={`/trade/${pairInfo.pairGroupId}/strategy/${strategyInfo.id}/`}
        >
          <AiOutlineExport className="mx-auto cursor-pointer" />
        </Link>
      </td>
    </tr>
  )
}

export const StrategyList = ({ chainId }: { chainId: number }) => {
  const strategyIDs = MARGIN_INFO_COUNTS[chainId]
    .map(pairGroupId => {
      return MARGIN_INFOS[chainId][pairGroupId].strategies
    })
    .flat()

  return (
    <div className="w-[328px] md:w-full overflow-x-scroll">
      <table className="w-[540px] md:w-[580px] text-xs md:text-sm">
        <thead className="mb-4">
          <tr>
            <th className="w-12 h-8">Quote</th>
            <th className="w-12">Risk</th>
            <th className="w-8">24h</th>
            <th className="w-8">APY</th>
            <th className="w-[108px]">Price</th>
            <th className="w-8">Next Hedge</th>
            <th className="w-[120px]">Price Trigger</th>
            <th className="w-16">Link</th>
          </tr>
        </thead>
        <tbody>
          {strategyIDs.map(strategyID => (
            <StrategyListItem
              chainId={chainId}
              key={strategyID}
              strategyId={strategyID}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const LendingListItem = ({
  chainId,
  pairId
}: {
  chainId: number
  pairId: number
}) => {
  const riskApr = useLendingSummary(chainId, pairId, false)
  const stableApr = useLendingSummary(chainId, pairId, true)

  const pairInfo = ASSET_INFOS[chainId][pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <>
      <tr className="px-2 space-x-2">
        <td className="w-16 pt-2 align-middle" rowSpan={2}>
          <div className="flex justify-center items-center">
            <img className="w-6 rounded-full" src={pairInfo.icon} />
            <img className="w-6 rounded-full" src={marginInfo.icon} />
          </div>
          <div className="text-center">
            {pairInfo.name}-{marginInfo.name}
          </div>
        </td>

        <td className="w-12 align-middle">{pairInfo.name}</td>

        <td className="w-12 align-middle text-center">
          <div className={`${riskApr.apy >= 1 ? 'text-green' : 'text-white'}`}>
            {riskApr.apy.toFixed(2)}%
          </div>
        </td>

        <td className="w-12 align-middle text-center">
          <div
            className={`${
              riskApr.utilization >= 80 ? 'text-red' : 'text-white'
            }`}
          >
            {riskApr.utilization.toFixed(2)}%
          </div>
        </td>
        <td className="w-12 align-middle text-center" rowSpan={2}>
          <Link href={`/trade/${pairInfo.pairGroupId}/lending/${pairInfo.id}/`}>
            <AiOutlineExport className="mx-auto cursor-pointer" />
          </Link>
        </td>
      </tr>
      <tr className="px-2 space-x-2 border-b border-white5">
        <td className="w-12 align-middle">{marginInfo.name}</td>
        <td className="w-12 align-middle text-center">
          <div
            className={`${stableApr.apy >= 1 ? 'text-green' : 'text-white'}`}
          >
            {stableApr.apy.toFixed(2)}%
          </div>
        </td>
        <td className="w-12 align-middle text-center">
          <div
            className={`${
              stableApr.utilization >= 80 ? 'text-red' : 'text-white'
            }`}
          >
            {stableApr.utilization.toFixed(2)}%
          </div>
        </td>
      </tr>
    </>
  )
}

export const LendingList = ({ chainId }: { chainId: number }) => {
  const pairIDs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

  return (
    <div className="w-[328px] md:w-full overflow-x-scroll">
      <table className="w-[380px] md:w-[400px] text-xs md:text-sm">
        <thead>
          <tr>
            <th className="w-16 h-8">Pair</th>
            <th className="w-12">Asset</th>
            <th className="w-12">APY</th>
            <th className="w-12">UR.</th>
            <th className="w-12">Link</th>
          </tr>
        </thead>
        <tbody>
          {pairIDs.map(pairId => (
            <LendingListItem chainId={chainId} key={pairId} pairId={pairId} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
