import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import { useDeadline } from '../../useBlockTimestamp'
import { useEffect, useState } from 'react'
import { REFETCH_INTERVAL } from '../../../constants'
import { usePrice } from '../../usePrice'
import { GammaShortStrategy__factory } from '../../../typechain'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../../utils'
import { StrategyQuoter__factory } from '../../../typechain'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  STRATEGY_INFOS
} from '../../../constants/assets'
import { toScaled } from '../../../utils/bn'

type QuoterResult =
  | {
      error: string
      data: null
    }
  | {
      error: null
      data: BigNumber
    }

type DepositParams = {
  strategyId: number
  strategyTokenAmount: BigNumber
  maxDepositAmount: BigNumber
}

type WithdrawParams = {
  strategyId: number
  strategyTokenAmount: BigNumber
}

export function useDepositToStrategyQuoter(
  chainId: number,
  assetId: number,
  params: DepositParams
) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)
  const deadline = useDeadline()
  const price = usePrice(chainId, assetId)

  const [amounts, setAmounts] = useState<QuoterResult>({
    error: 'not loaded',
    data: null
  })

  const quoterQuery = useQuery<QuoterResult>(
    ['quoter_deposit_to_st', account, chainId, assetId, params],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const strategyInfo = STRATEGY_INFOS[chainId][params.strategyId]
      const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
      const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

      const contract = StrategyQuoter__factory.connect(
        addresses.StrategyQuoter,
        provider
      )

      try {
        const requiredDepositAmount = await contract.callStatic.quoteDeposit(
          params.strategyId,
          params.strategyTokenAmount,
          account,
          BigNumber.from('10000000000000000'),
          {
            lowerSqrtPrice: computeLowerSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            upperSqrtPrice: computeUpperSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            deadline: deadline.data
          },
          { from: account }
        )

        if (
          requiredDepositAmount.lte(
            toScaled(1, marginInfo.minDepositAmountDecimals)
          )
        ) {
          return {
            error: 'Too Small Amount',
            data: null
          }
        }

        return {
          error: null,
          data: requiredDepositAmount
        }
      } catch (e: any) {
        if (e.error && e.error.message === "Non-200 status code: '429'") {
          throw new Error(e.error.message)
        }
        return {
          error: String(e.reason || e.data.message),
          data: null
        }
      }
    },
    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess &&
        params.strategyTokenAmount.gt(0),
      refetchInterval: REFETCH_INTERVAL
    }
  )

  useEffect(() => {
    if (quoterQuery.isSuccess) {
      setAmounts(quoterQuery.data)
    }
  }, [quoterQuery.isSuccess, quoterQuery.data])

  return amounts
}

export function useWithdrawFromStrategyQuoter(
  chainId: number,
  assetId: number,
  params: WithdrawParams
) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)
  const deadline = useDeadline()
  const price = usePrice(chainId, assetId)

  const [amounts, setAmounts] = useState<QuoterResult>({
    error: 'not loaded',
    data: null
  })

  const quoterQuery = useQuery<QuoterResult>(
    ['quoter_withdraw_from_st', account, chainId, params],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const contract = GammaShortStrategy__factory.connect(
        addresses.GammaShortStrategy,
        provider
      )

      try {
        const finalWithdrawnAmount = await contract.callStatic.withdraw(
          params.strategyId,
          params.strategyTokenAmount,
          account,
          0,
          {
            lowerSqrtPrice: computeLowerSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            upperSqrtPrice: computeUpperSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            deadline: deadline.data
          },
          { from: account }
        )

        return {
          error: null,
          data: finalWithdrawnAmount
        }
      } catch (e: any) {
        if (e.error && e.error.message === "Non-200 status code: '429'") {
          throw new Error(e.error.message)
        }
        return {
          error: String(e.reason || e.data.message),
          data: null
        }
      }
    },
    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess &&
        params.strategyTokenAmount.gt(0),
      refetchInterval: REFETCH_INTERVAL
    }
  )

  useEffect(() => {
    if (quoterQuery.isSuccess) {
      setAmounts(quoterQuery.data)
    }
  }, [quoterQuery.isSuccess, quoterQuery.data])

  return amounts
}
