import { BigNumber } from 'ethers'
import { getNow, useDeltaTimestamps } from '../../../utils/time'
import { Q128, ZERO } from '../../../constants'
import { usePrice } from '../../usePrice'
import { toUnscaled } from '../../../utils/bn'
import { useAsset } from '../useAsset'
import { useInterestGrowthTxs } from './useInterestGrowthTxes'
import { useQuery } from 'react-query'
import { usePairContext } from '../../usePairContext'

export function useFee24h(chainId: number, pairId: number) {
  const [yesterday, ,] = useDeltaTimestamps()
  const feesBetweenTxes = useFeesBetweenTxes(chainId, pairId)
  const price = usePrice(chainId, pairId)
  const scalers = usePairContext(chainId, pairId)

  return useQuery(
    ['fee_24h', chainId, pairId],
    () => {
      if (!price.isSuccess) throw new Error('price is not loaded')
      if (!feesBetweenTxes.isSuccess)
        throw new Error('feesBetweenTxes is not loaded')
      if (!scalers.isSuccess) throw new Error('scalers is not loaded')

      const items24h = sliceLatest24HFee(feesBetweenTxes.data, yesterday)

      const accItems24h = items24h.reduce(
        (acc, item) => {
          return {
            fee0: acc.fee0.add(item.fee0),
            fee1: acc.fee1.add(item.fee1)
          }
        },
        {
          fee0: ZERO,
          fee1: ZERO
        }
      )

      const feesUSDC = accItems24h.fee1.add(
        accItems24h.fee0
          .mul(price.data?.price)
          .div(scalers.data.underlyingScaler)
      )

      return toUnscaled(feesUSDC, scalers.data.marginDecimals)
    },
    {
      enabled:
        price.isSuccess && feesBetweenTxes.isSuccess && scalers.isSuccess,
      staleTime: 30 * 1000
    }
  )
}

export function sliceLatest24HFee(
  feesBetweenTxes: {
    fee0: BigNumber
    fee1: BigNumber
    createdAt: number
  }[],
  yesterday: number
) {
  const items24h = []

  for (const item of feesBetweenTxes) {
    if (item.createdAt < yesterday) {
      const lastItem = items24h[items24h.length - 1]
      const ratio =
        lastItem.createdAt > item.createdAt
          ? Math.floor(
              (1000 * (lastItem.createdAt - yesterday)) /
                (lastItem.createdAt - item.createdAt)
            )
          : 1000

      items24h[items24h.length - 1] = {
        fee0: lastItem.fee0.mul(ratio).div(1000),
        fee1: lastItem.fee1.mul(ratio).div(1000),
        createdAt: lastItem.createdAt
      }
      break
    } else {
      items24h.push(item)
    }
  }
  return items24h
}

function useFeesBetweenTxes(chainId: number, assetId: number) {
  const interestGrowthTxs = useInterestGrowthTxs(chainId, assetId)
  const asset = useAsset(chainId, assetId)

  return useQuery(
    ['fees_between_txes', chainId, assetId],
    () => {
      if (!asset.isSuccess) throw new Error('asset is not loaded')
      if (interestGrowthTxs === null)
        throw new Error('interestGrowthTxs is not loaded')

      const sqrtAssetStatus = asset.data.sqrtAssetStatus

      const fee0 = sqrtAssetStatus.fee0Growth
        .sub(interestGrowthTxs[0].supplySqrtInterest0Growth)
        .mul(sqrtAssetStatus.totalAmount)
        .div(Q128)

      const fee1 = sqrtAssetStatus.fee1Growth
        .sub(interestGrowthTxs[0].supplySqrtInterest1Growth)
        .mul(sqrtAssetStatus.totalAmount)
        .div(Q128)

      const latest = {
        fee0,
        fee1,
        createdAt: getNow()
      }

      const feeTxsHistory = interestGrowthTxs.map(data => {
        return {
          fee0: data.supplySqrtFee0,
          fee1: data.supplySqrtFee1,
          createdAt: data.createdAt
        }
      })

      return [latest].concat(feeTxsHistory)
    },
    {
      enabled:
        !!interestGrowthTxs && interestGrowthTxs.length > 0 && asset.isSuccess
    }
  )
}
