/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type { ISupplyToken, ISupplyTokenInterface } from '../ISupplyToken'

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'burn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export class ISupplyToken__factory {
  static readonly abi = _abi
  static createInterface(): ISupplyTokenInterface {
    return new utils.Interface(_abi) as ISupplyTokenInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISupplyToken {
    return new Contract(address, _abi, signerOrProvider) as ISupplyToken
  }
}
