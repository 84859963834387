import React from 'react'

type Props = {
  side: boolean
  onChange: (side: boolean) => void
}

const ActionSelector = ({ side, onChange }: Props) => {
  return (
    <div className="w-full p-3 rounded-xl bg-black3 text-base font-light shadow-sm">
      <span className="text-subtext">Action</span>
      <div className="flex justify-around">
        <button
          className={`w-[120px] h-8 rounded-md bg-red2 text-white space-y-2 border-[3px] ${
            side ? 'border-red' : 'border-red2'
          }`}
          onClick={() => {
            onChange(true)
          }}
        >
          Withdraw
        </button>
        <button
          className={`w-[120px] h-8 rounded-md bg-green2 text-white space-y-2 border-[3px] ${
            !side ? 'border-green' : 'border-green2'
          }`}
          onClick={() => {
            onChange(false)
          }}
        >
          Deposit
        </button>
      </div>
    </div>
  )
}

export default ActionSelector
