import { useCallback, useEffect } from 'react'
import { useLocation, useRoute } from 'wouter'
import { MARGIN_INFOS, MARGIN_INFO_COUNTS } from '../constants/assets'

export function usePortfolioRoute() {
  const params = useRoute('/:chain/:title')[1]

  const chain = params ? params.chain : 'arbitrum'

  const chainId = chainNameToId(chain)

  return { chainId }
}

export interface TradeRouteResult {
  isNotFound: boolean
  chainId: number
  pairGroupId: number | undefined
  pairId: number
  setPairId: (pairId: number) => void
  pairGroupTag: string
}

export function useTradeRoute(): TradeRouteResult {
  const route = useRoute('/:chain/trade/:pairGroup/:title/:pairId')
  const portfolioRouteParams = useRoute('/:chain/portfolio/:pairGroup')[1]

  const params = route[0] ? route[1] : portfolioRouteParams

  const [, setLocation] = useLocation()

  let isNotFound = false

  const chain = params ? params.chain : 'arbitrum'

  const chainId = chainNameToId(chain)

  const pairGroupTag = params ? params.pairGroup : 'usdce'
  const pairGroupId = tagToPairGroupId(chainId, pairGroupTag)

  isNotFound = isNotFound || pairGroupId === undefined

  const pairId = params ? Number(params.pairId) : 1

  useEffect(() => {
    if (portfolioRouteParams) {
      document.title = 'Portfolio | Predy V5'
    }
  }, [portfolioRouteParams])

  const setPairId = useCallback(
    (pairId: number) => {
      if (params) {
        setLocation(`/${chain}/trade/${pairGroupTag}/${params.title}/${pairId}`)
      }
    },
    [chain, setLocation, params, pairGroupTag]
  )

  return { isNotFound, chainId, pairGroupId, pairId, setPairId, pairGroupTag }
}

export function tagToPairGroupId(chainId: number, tag: string) {
  const idAndTags = MARGIN_INFO_COUNTS[chainId].map(pairGroupId => {
    return {
      id: pairGroupId,
      tag: MARGIN_INFOS[chainId][pairGroupId].tag
    }
  })

  const filteredIdAndTag = idAndTags.filter(idAndTag => {
    return idAndTag.tag === tag
  })

  if (filteredIdAndTag.length > 0) {
    return filteredIdAndTag[0].id
  } else {
    return undefined
  }
}

export function chainIdToName(chainId: number) {
  if (chainId === 42161) {
    return 'arbitrum'
  } else if (chainId === 8453) {
    return 'base'
  }
}

export function chainNameToId(chainName: string) {
  if (chainName === 'arbitrum') {
    return 42161
  } else if (chainName === 'base') {
    return 8453
  } else {
    // default
    return 42161
  }
}
