import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useMintMutation } from '../hooks/contracts/useMint'
import { useERC20BalanceQueryCached } from '../hooks/query/balance'
import { useAddresses } from '../hooks/useAddress'
import { toScaled, toUnscaled } from '../utils/bn'
import { PrimaryButton } from '../components/common/Button'
import { DEFAULT_CHAIN } from '../constants'

const FaucetView = () => {
  const addresses = useAddresses(DEFAULT_CHAIN)
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const balance = useERC20BalanceQueryCached(
    DEFAULT_CHAIN,
    addresses ? addresses.QuoteToken : ''
  )

  const mint = useMintMutation()

  const onMint = async () => {
    if (!addresses || !account) {
      return
    }

    await mint.mutateAsync({
      address: addresses.QuoteToken,
      account: account,
      amount: toScaled(2000, 6)
    })
  }

  return (
    <div>
      <div className="h-[120px]"></div>
      <div className="flex items-center justify-center">
        <div className="w-72 bg-white1 border-[1px] border-white rounded-xl p-4">
          <h3 className="text-xl">Faucet</h3>
          <div className="flex items-center justify-between">
            <div>Balance</div>
            <div>{toUnscaled(balance, 6, 2)} USDC</div>
          </div>
          <div className="flex justify-center mt-10 h-16">
            <PrimaryButton onClick={onMint} disabled={false}>
              Get Mock USDC
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="h-[480px]"></div>
    </div>
  )
}

export default FaucetView
