import React, { useCallback, useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import {
  useInitializeStrategy,
  useRefreshStrategy,
  useUpdateGamma
} from '../../hooks/contracts/useInitializeStrategy'
import { computeTradeAmounts } from '../../utils/helpers/targetStrategy'
import { PositionStyle } from '../../constants/enum'
import { usePrice } from '../../hooks/usePrice'
import { ZERO } from '../../constants'
import { toUnscaledSqrtPrice } from '../../utils/price'
import PnLChartWrapper from '../main/center/PnLChartWrapper'
import { useStrategyVaultStatus } from '../../hooks/query/strategy/useStrategyVaultStatus'
import { useStrategySummary } from '../../hooks/query/strategy/useStrategySummary'
import { PairScalers } from '../../utils/helpers/scaler'
import { useStrategyApr } from '../../hooks/query/strategy/useStrategyApr'
import { BigNumber } from 'ethers'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  STRATEGY_INFOS
} from '../../constants/assets'

const AprComponent = ({ apr }: { apr: BigNumber }) => {
  return (
    <div className={`w-8 ${apr.gt(0) ? 'text-green' : 'text-red'}`}>
      {apr.gt(0) ? '+' : '-'}
      {toUnscaled(apr.abs(), 16, 2)}%
    </div>
  )
}

const StrategyListItem = ({
  chainId,
  isSelected,
  strategyId,
  onSelect
}: {
  chainId: number
  isSelected: boolean
  strategyId: number
  onSelect: (strategyId: number) => void
}) => {
  const apr = useStrategyApr(chainId, strategyId)
  const summary = useStrategySummary(chainId, strategyId)
  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <div
      className={`my-1 px-2 flex justify-between items-center cursor-pointer ${isSelected ? 'bg-white5' : ''
        }`}
      onClick={() => {
        onSelect(strategyId)
      }}
    >
      <img className="w-8 rounded-full" src={strategyInfo.icon} width={30} />
      <div className="w-8">{strategyInfo.name}</div>
      <div className="w-[108px]">
        {(summary.data?.price || 0).toFixed(3)} {marginInfo.name}
      </div>
      <AprComponent apr={apr.yearApr} />
    </div>
  )
}

const StrategyList = ({
  chainId,
  selectedStrategyId,
  onSelect
}: {
  chainId: number
  selectedStrategyId: number
  onSelect: (strategyId: number) => void
}) => {
  const strategyIDs: number[] = MARGIN_INFOS[chainId][1].strategies

  return (
    <div className="w-[320px]">
      {strategyIDs.map(strategyID => (
        <StrategyListItem
          chainId={chainId}
          key={strategyID}
          isSelected={selectedStrategyId === strategyID}
          strategyId={strategyID}
          onSelect={onSelect}
        />
      ))}
    </div>
  )
}

/*
ETH: 180 ,  1036822067670000000
ARB:  7000, 1041633332800000000
LUSD: 24000 1012422836570000000
ETH: 200,    1031988372030000000
ETH-BTC: 2.6: 1024695076600000000
ETH-ARB: 3000: 1029563014100000000
ARB:  6500, 1036822067670000000
*/
const StrategyManager = ({ chainId }: { chainId: number }) => {
  const [pairId, setPairId] = useState(1)
  const [size, setSize] = useState(-200)
  const [strategyId, setStrategyId] = useState(1)

  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  // const pairId = strategyInfo.pairId

  const price = usePrice(chainId, pairId)
  const scalers = new PairScalers(pairId, chainId)

  const initialize = useInitializeStrategy(pairId)
  const updateGamma = useUpdateGamma(pairId)
  const refresh = useRefreshStrategy(pairId)
  const vaultStatus = useStrategyVaultStatus(chainId, strategyId)
  const strategySummary = useStrategySummary(chainId, strategyId)


  const perpAmount = computeTradeAmounts(
    false,
    PositionStyle.Neutral,
    size,
    toUnscaledSqrtPrice(price.data?.sqrtPrice || ZERO, scalers)
  )

  const positionValue = vaultStatus.data?.vaultValue || ZERO
  const squartAmount = (
    vaultStatus.data?.status.openPositionStatusResults[0].sqrtStatus.amount ||
    ZERO
  ).mul(2)
  const sizePerValue = positionValue.gt(ZERO)
    ? squartAmount.div(positionValue)
    : ZERO

  const additionalSquart = positionValue.mul(size).sub(squartAmount)

  const onInitialize = useCallback(async () => {
    await initialize.mutateAsync({
      strategyId: 0,
      initialMarginAmount: toScaled(100, 6),
      initialPerpAmount: toScaled(perpAmount, 18),
      initialSquartAmount: toScaled(size, 12).div(2),
      priceThreshold: '1031988372030000000'
    })
  }, [initialize, size, perpAmount])

  const onUpdate = useCallback(async () => {
    await updateGamma.mutateAsync({
      strategyId,
      squartAmount: BigNumber.from('-23092199999999864').div(1)//toScaled(171600, 12).div(2)
    })
  }, [updateGamma, strategyId])

  const onRefresh = useCallback(async () => {
    await refresh.mutateAsync({
      strategyId,
      ratio: BigNumber.from('10').pow(17).mul(2)
    })
  }, [updateGamma, strategyId])



  const liqPrice1 = toUnscaled(
    vaultStatus.data?.status.openPositionStatusResults[0].liqPrice1 || ZERO,
    scalers.marginDecimals
  )

  const liqPrice2 = toUnscaled(
    vaultStatus.data?.status.openPositionStatusResults[0].liqPrice2 || ZERO,
    scalers.marginDecimals
  )

  return (
    <div className="m-10 rounded-xl bg-white1">
      <div className="p-6 flex flex-wrap justify-between items-center space-x-0">
        <div className="w-32">Strategy Form</div>
      </div>

      <div className="flex justify-center items-center">
        <div className="w-80 space-y-2">
          <div className="px-2 flex justify-between">
            <span>Pair ID</span>
            <input
              type="number"
              className="text-black"
              value={pairId}
              onChange={e => {
                setPairId(Number(e.target.value))
              }}
            />
          </div>
          <div className="px-2 flex justify-between">
            <span>Size</span>
            <input
              type="number"
              className="text-black"
              value={size}
              onChange={e => {
                setSize(Number(e.target.value))
              }}
            />
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onInitialize} disabled={false}>
              Add Strategy
            </PrimaryButton>
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onUpdate} disabled={false}>
              Update
            </PrimaryButton>
          </div>
          <div className="mt-4 h-10">
            <PrimaryButton onClick={onRefresh} disabled={false}>
              Refresh
            </PrimaryButton>
          </div>

        </div>
      </div>

      <div className="flex justify-center">
        <div>Squart Amount</div>
        <div>{toUnscaled(squartAmount, scalers.squartDecimals)}</div>
      </div>
      <div className="flex justify-center">
        <div>Squart Decimals</div>
        <div>{scalers.squartDecimals}</div>
      </div>

      <div className="flex justify-center">
        <div>Squart Per Strategy</div>
        <div>
          {toUnscaled(squartAmount, scalers.squartDecimals) /
            (strategySummary.data?.totalIssuing || 0)}
        </div>
      </div>

      <div>{toUnscaled(sizePerValue, 6)}</div>
      <div>{toUnscaled(additionalSquart, scalers.squartDecimals)}</div>

      <div className="flex justify-center">
        <div>Price Threshold</div>
        <div>{strategySummary.data?.hedgePriceThreshold}</div>
      </div>

      <div className="flex justify-between items-center px-4 py-2">
        <div>
          <div className="my-2">Select Strategy</div>
          <StrategyList
            chainId={chainId}
            selectedStrategyId={strategyId}
            onSelect={setStrategyId}
          />
        </div>
        <div>
          {vaultStatus.isSuccess ? (
            <PnLChartWrapper
              chainId={chainId}
              assetId={pairId}
              vaultId={vaultStatus.data.id}
              showAfterTraded={false}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="m-1 flex justify-center items-center">
        <div>
          <div className="flex justify-between items-center">
            <div>Liq. prices</div>
            <div>
              {liqPrice1},{liqPrice2}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div>Price Threshold</div>
            <div>
              {strategySummary.data?.lowerPrice || 0},
              {strategySummary.data?.upperPrice || 0}
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div>Ratio</div>
            <div>
              {(strategySummary.data?.lowerPrice || 0) / liqPrice1},
              {liqPrice2 / (strategySummary.data?.upperPrice || 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StrategyManager
