import React from 'react'
import { ethers } from 'ethers'
import {
  useVaultStatus,
  useVaultSummary
} from '../../hooks/query/useVaultStatus'
import { usePairContext } from '../../hooks/usePairContext'
import { useWeb3React } from '@web3-react/core'
import { TradePosition } from './TradePosition'
import { TradePositionLite } from './TradePositionLite'
import { StrategyPortfolio } from './StrategyPortfolio'
import { LendingPortfolio } from './LendingPortfolio'
import { StrategyPortfolioLite } from './StrategyPortfolioLite'
import { LendingPortfolioLite } from './LendingPortfolioLite'
import { MARGIN_INFOS } from '../../constants/assets'

const Portfolio = ({
  chainId,
  pairGroupId
}: {
  chainId: number
  pairGroupId: number
}) => {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const summary = useVaultSummary(chainId, pairGroupId)
  const vaultStatus = useVaultStatus(chainId, pairGroupId)
  const pairContext = usePairContext(chainId, pairGroupId)
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

  return (
    <div className="leading-5 p-12 text-xs">
      <div className="mb-6 flex justify-between items-center">
        <div className="space-y-2">
          <div>Vault Summary</div>
          <div>
            {pairContext.data?.toMarginString(summary.data?.totalValue)}
          </div>
        </div>

        <div className="flex justify-between items-center space-x-6">
          <div className="space-y-2">
            <div>Margin Utilizing</div>
            <div>
              {pairContext.data?.toMarginString(summary.data?.minValue)}
            </div>
          </div>
          <div className="space-y-2">
            <div>Margin Available</div>
            <div>
              {pairContext.data?.toMarginString(summary.data?.marginAvailable)}
            </div>
          </div>

          <div className="space-y-2">
            <div>Margin Isolated</div>
            <div>
              {pairContext.data?.toMarginString(
                summary.data?.isolatedVaultValue
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="hidden md:block my-6 w-[1200px] space-y-2">
        <div className="text-base">Trade</div>

        <div className="flex justify-between justify-items-start items-center text-left">
          <div className="w-[240px]">Symbol</div>
          <div className="w-40">Product</div>
          <div className="w-40">Margin Utilizing</div>
          <div className="w-40">Margin Available</div>
          <div className="w-40">Delta</div>
          <div className="w-40">Gamma</div>
          <div className="w-40">Daily Fee Est.(theta)</div>
          <div className="w-32">Link</div>
        </div>

        {vaultStatus.isSuccess ? (
          vaultStatus.data.mainVault.openPositionStatusResults.map(
            (openPosition, index) => {
              return (
                <TradePosition
                  key={index}
                  chainId={chainId}
                  isolated={false}
                  pairId={openPosition.assetId}
                  vaultId={vaultStatus.data.mainVault.id}
                  position={openPosition.position}
                  utilizing={vaultStatus.data.mainVault.minDeposit}
                  available={vaultStatus.data.mainVault.available}
                />
              )
            }
          )
        ) : (
          <></>
        )}
      </div>

      <div className="block md:hidden my-6 w-full space-y-2">
        <div className="text-base">Trade</div>
        {vaultStatus.isSuccess ? (
          vaultStatus.data.mainVault.openPositionStatusResults.map(
            (openPosition, index) => {
              return (
                <TradePositionLite
                  key={index}
                  chainId={chainId}
                  isolated={false}
                  pairId={openPosition.assetId}
                  vaultId={vaultStatus.data.mainVault.id}
                  position={openPosition.position}
                  utilizing={vaultStatus.data.mainVault.minDeposit}
                  available={vaultStatus.data.mainVault.available}
                />
              )
            }
          )
        ) : (
          <></>
        )}
      </div>

      <div className="hidden md:block my-6 w-[1220px] space-y-2">
        <div className="text-base">Isolated</div>

        <div className="flex justify-between justify-items-start items-center text-left">
          <div className="w-[260px]">Symbol</div>
          <div className="w-40">Product</div>
          <div className="w-40">Margin Utilizing</div>
          <div className="w-40">Margin Available</div>
          <div className="w-40">Delta</div>
          <div className="w-40">Gamma</div>
          <div className="w-40">Daily Fee Est.(theta)</div>
          <div className="w-32">Link</div>
        </div>

        {vaultStatus.isSuccess ? (
          vaultStatus.data.isolatedVaults
            .map((isolatedVault, index) => {
              return isolatedVault.openPositionStatusResults.map(
                openPosition => {
                  return (
                    <TradePosition
                      key={index}
                      chainId={chainId}
                      isolated={true}
                      pairId={openPosition.assetId}
                      vaultId={isolatedVault.id}
                      position={openPosition.position}
                      utilizing={isolatedVault.minDeposit}
                      available={isolatedVault.available}
                    />
                  )
                }
              )
            })
            .flat()
        ) : (
          <></>
        )}
      </div>

      <div className="block md:hidden my-6 w-full space-y-2">
        <div className="text-base">Isolated</div>

        {vaultStatus.isSuccess ? (
          vaultStatus.data.isolatedVaults
            .map((isolatedVault, index) => {
              return isolatedVault.openPositionStatusResults.map(
                openPosition => {
                  return (
                    <TradePositionLite
                      key={index}
                      chainId={chainId}
                      isolated={true}
                      pairId={openPosition.assetId}
                      vaultId={isolatedVault.id}
                      position={openPosition.position}
                      utilizing={isolatedVault.minDeposit}
                      available={isolatedVault.available}
                    />
                  )
                }
              )
            })
            .flat()
        ) : (
          <></>
        )}
      </div>

      <div className="hidden md:block w-[880px] space-y-2 my-6">
        <div className="text-base">Strategy</div>
        <div className="flex justify-between justify-items-start items-center text-left">
          <div className="w-[240px]">Symbol</div>
          <div className="w-40">Quantity</div>
          <div className="w-40">Current Value</div>
          <div className="w-40">Unrealized Profit</div>
          <div className="w-40">Link</div>
        </div>
        {account ? (
          marginInfo.strategies.map(strategyId => (
            <StrategyPortfolio
              key={strategyId}
              chainId={chainId}
              strategyId={strategyId}
              account={account}
            />
          ))
        ) : (
          <></>
        )}
      </div>

      <div className="block md:hidden w-full space-y-2 my-6">
        <div className="text-base">Strategy</div>

        {account ? (
          marginInfo.strategies.map(strategyId => {
            return (
              <StrategyPortfolioLite
                key={strategyId}
                chainId={chainId}
                strategyId={strategyId}
                account={account}
              />
            )
          })
        ) : (
          <></>
        )}
      </div>

      <div className="hidden md:block w-[720px] space-y-2 my-6">
        <div className="text-base">Lending</div>
        <div className="flex justify-between justify-items-start items-center text-left">
          <div className="w-[240px]">Symbol</div>
          <div className="w-40">Product</div>
          <div className="w-40">Lending Amount</div>
          <div className="w-40">Link</div>
        </div>
        {marginInfo.pairs.map(pairId => {
          return (
            <div key={pairId} className="space-y-1">
              <LendingPortfolio
                chainId={chainId}
                pairId={pairId}
                isStable={true}
              />
              <LendingPortfolio
                chainId={chainId}
                pairId={pairId}
                isStable={false}
              />
            </div>
          )
        })}
      </div>

      <div className="block md:hidden w-full space-y-2 my-6">
        <div className="text-base">Lending</div>
        {marginInfo.pairs.map(pairId => {
          return (
            <div key={pairId}>
              <LendingPortfolioLite
                chainId={chainId}
                pairId={pairId}
                isStable={true}
              />
              <LendingPortfolioLite
                chainId={chainId}
                pairId={pairId}
                isStable={false}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Portfolio
