import { useQuery } from 'react-query'
import { ONE, ZERO } from '../../../constants'
import {
  IRMParams,
  calculateInterestRate,
  calculatePremiumCurve
} from '../../../utils/irm'
import { Asset, ScaledAssetStatus, useAsset } from '../useAsset'
import { BigNumber } from 'ethers'

function calculateSupply(assetStatus: ScaledAssetStatus) {
  const totalDeposited = assetStatus.totalCompoundDeposited
    .mul(assetStatus.assetScaler)
    .div(ONE)
    .add(assetStatus.totalNormalDeposited)

  return totalDeposited
}

function calculateBorrow(assetStatus: ScaledAssetStatus) {
  const totalBorrowed = assetStatus.totalNormalBorrowed

  return totalBorrowed
}

export function calculateAssetInterest(
  tokenStatus: ScaledAssetStatus,
  irmParams: IRMParams,
  positionAmount: BigNumber,
  tradeAmount?: BigNumber
) {
  let supplyAddiction = ZERO
  let borrowAddiction = ZERO

  if (tradeAmount) {
    const openAndCloseAmounts = calculateOpenAndCloseAmounts(
      positionAmount,
      tradeAmount
    )

    if (openAndCloseAmounts.openAmount.gt(0)) {
      supplyAddiction = openAndCloseAmounts.openAmount
    } else {
      borrowAddiction = openAndCloseAmounts.openAmount.mul(-1)
    }

    if (openAndCloseAmounts.closeAmount.gt(0)) {
      borrowAddiction = openAndCloseAmounts.closeAmount.mul(-1)
    } else {
      supplyAddiction = openAndCloseAmounts.closeAmount
    }
  }

  const supply = calculateSupply(tokenStatus).add(supplyAddiction)
  const borrow = calculateBorrow(tokenStatus).add(borrowAddiction)

  const ur = supply.eq(0) ? ZERO : borrow.mul(ONE).div(supply)
  const borrowInterest = calculateInterestRate(irmParams, ur)
  const supplyInterest = supply.eq(0)
    ? ZERO
    : borrowInterest.mul(borrow).div(supply)

  return {
    supplyInterest,
    borrowInterest
  }
}

export function useAssetInterest(
  chainId: number,
  assetId: number,
  isStable: boolean
) {
  const asset = useAsset(chainId, assetId)

  return useQuery(
    ['perp_interest', chainId, assetId, isStable],

    async () => {
      if (!asset.isSuccess) throw new Error('asset not set')

      const pool = isStable ? asset.data.stablePool : asset.data.underlyingPool

      return calculateAssetInterest(pool.tokenStatus, pool.irmParams, ZERO)
    },
    {
      enabled: asset.isSuccess,
      staleTime: 1000
    }
  )
}

// interest for 2 * sqrt(x)
export function calculateSquartInterest(
  asset: Asset,
  positionAmount: BigNumber,
  tradeAmount?: BigNumber
) {
  let supplyAddiction = ZERO
  let borrowAddiction = ZERO

  if (tradeAmount) {
    const openAndCloseAmounts = calculateOpenAndCloseAmounts(
      positionAmount,
      tradeAmount
    )

    if (openAndCloseAmounts.openAmount.gt(0)) {
      supplyAddiction = openAndCloseAmounts.openAmount
    } else {
      borrowAddiction = openAndCloseAmounts.openAmount.mul(-1)
    }

    if (openAndCloseAmounts.closeAmount.gt(0)) {
      borrowAddiction = openAndCloseAmounts.closeAmount.mul(-1)
    } else {
      supplyAddiction = openAndCloseAmounts.closeAmount
    }
  }

  const supply = asset.sqrtAssetStatus.totalAmount.add(supplyAddiction)
  const borrow = asset.sqrtAssetStatus.borrowedAmount.add(borrowAddiction)

  const ur = supply.eq(0) ? ZERO : borrow.mul(ONE).div(supply)

  const spread = calculatePremiumCurve(ur)

  return {
    supply,
    borrow,
    spread
  }
}

function calculateOpenAndCloseAmounts(
  positionAmount: BigNumber,
  tradeAmount: BigNumber
) {
  let openAmount = ZERO
  let closeAmount = ZERO

  if (positionAmount.isNegative() === tradeAmount.isNegative()) {
    openAmount = tradeAmount
  } else {
    if (positionAmount.abs().gte(tradeAmount.abs())) {
      closeAmount = tradeAmount
    } else {
      openAmount = positionAmount.add(tradeAmount)
      closeAmount = positionAmount.mul(-1)
    }
  }

  return {
    openAmount,
    closeAmount
  }
}
