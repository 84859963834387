import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'
import { useEffect, useState } from 'react'
import { useWeb3Provider } from '../useWeb3Provider'

export function useERC20BalanceQuery(chainId: number, address: string) {
  const web3Provider = useWeb3Provider(chainId)

  return useQuery(
    ['balance', web3Provider.data?.account, chainId, address],

    async () => {
      if (!web3Provider.isSuccess) throw new Error('web3provider not set')

      const { account, provider } = web3Provider.data

      const contract = ERC20__factory.connect(address, provider)
      return await contract.balanceOf(account)
    },

    {
      enabled: web3Provider.isSuccess && web3Provider.data.isConnected
    }
  )
}

export function useERC20BalanceQueryCached(chainId: number, address: string) {
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0))

  const balanceQuery = useERC20BalanceQuery(chainId, address)

  useEffect(() => {
    if (balanceQuery.isSuccess) {
      setBalance(balanceQuery.data)
    }
  }, [balanceQuery.isSuccess, balanceQuery.data])

  return balance
}

export function useEthBalanceQuery() {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()

  return useQuery(
    ['balance', account, chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')

      return await provider.getBalance(account)
    },

    {
      enabled: !!account && supportedChain && !!provider
    }
  )
}
