import React, { useEffect, useState } from 'react'
import { toScaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { calculateMargin } from '../../utils'
import { useERC20BalanceQueryCached } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import pendingStore from '../../store/pending'
import assetTypeStore from '../../store/assetType'
import { useSupply, useWithdraw } from '../../hooks/contracts/useSupply'
import { Asset } from '../../hooks/query/useAsset'
import { Addresses } from '../../constants/addresses'
import StableSelector from './StableSelector'
import { PairScalers } from '../../utils/helpers/scaler'
import SizeForm from '../main/trade/SizeForm'
import { ONE } from '../../constants'
import ActionSelector from './ActionSelector'
import { LoadingIndicatorPt } from '../common/LoadingIndicator'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'

const LendingTradeForm = ({
  chainId,
  pairGroupId,
  assetId,
  addresses,
  asset
}: {
  chainId: number
  pairGroupId: number
  assetId: number
  addresses: Addresses
  asset: Asset
}) => {
  const { isStable, setIsStable } = assetTypeStore()
  const [tradeSide, setTradeSide] = useState(false)
  const [assetAmount, setAssetAmount] = useState(0)
  const { isPendingApproval, isPendingTx, setPendingApproval, setPendingTx } =
    pendingStore()
  const scalers = new PairScalers(assetId, chainId)
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]
  const assetInfo = ASSET_INFOS[chainId][assetId]

  const tokenAddress = isStable
    ? asset.stablePool.token
    : asset.underlyingPool.token
  const tokenDecimals = isStable ? scalers.marginDecimals : assetInfo.decimals

  const poolStatus = isStable ? asset.stablePool : asset.underlyingPool

  const balanceQuery = useERC20BalanceQueryCached(chainId, tokenAddress)
  const supplyTokenBalanceQuery = useERC20BalanceQueryCached(
    chainId,
    poolStatus.supplyTokenAddress
  )

  const allowanceQuery = useAllowanceQuery(
    chainId,
    addresses.Controller,
    tokenAddress
  )

  const supply = useSupply()
  const withdraw = useWithdraw()
  const approve = useApproveMutation()

  useEffect(() => {
    setAssetAmount(0)
  }, [assetId, setAssetAmount])

  const onApprove = async () => {
    if (addresses === undefined) {
      return
    }

    const tx = await approve.mutateAsync({
      spender: addresses.Controller,
      address: tokenAddress,
      amount: calculateMargin(toScaled(assetAmount, tokenDecimals))
    })

    await setPendingApproval(tx)
  }

  const onTrade = async () => {
    if (tradeSide) {
      const tx = await withdraw.mutateAsync({
        assetId,
        supplyAmount: toScaled(assetAmount, tokenDecimals),
        isStable
      })

      await setPendingTx(tx)
      setAssetAmount(0)
    } else {
      const tx = await supply.mutateAsync({
        assetId,
        supplyAmount: toScaled(assetAmount, tokenDecimals),
        isStable
      })

      await setPendingTx(tx)
      setAssetAmount(0)
    }
  }

  const requiredUSDCAmount = toScaled(assetAmount, tokenDecimals)

  const hasEnoughUSDC = balanceQuery.gte(requiredUSDCAmount)
  const enoughUSDCApproved = allowanceQuery.gte(requiredUSDCAmount)

  const value = supplyTokenBalanceQuery
    .mul(poolStatus.tokenStatus.assetScaler)
    .div(ONE)

  const hasEnoughStrategyToken = value.gte(requiredUSDCAmount)

  const requiredTokenName =
    (tradeSide ? 'p' : '') + (isStable ? marginInfo.name : assetInfo.name)

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white leading-5">
        <div className="mx-5 my-4">
          <StableSelector
            chainId={chainId}
            pairGroupId={pairGroupId}
            isStable={isStable}
            pairId={assetId}
            onChange={(isStable: boolean) => {
              setIsStable(isStable)
              setAssetAmount(0)
            }}
          />
        </div>
        <div className="mx-5 my-4">
          <ActionSelector side={tradeSide} onChange={setTradeSide} />
        </div>
        <div className="mx-5 my-4 p-4 rounded-xl bg-black4 shadow-sm">
          <SizeForm
            amount={assetAmount}
            unit={isStable ? marginInfo.name : assetInfo.name}
            step={
              isStable ? marginInfo.lendingMinSize : assetInfo.lendingMinSize
            }
            onChange={setAssetAmount}
          />
        </div>
      </div>

      <div className="mx-5 my-5 h-12 flex justify-between items-center space-x-2">
        {tradeSide ? (
          <>
            <PrimaryButton
              onClick={onTrade}
              disabled={
                !hasEnoughStrategyToken || isPendingTx || assetAmount === 0
              }
            >
              {isPendingTx ? <LoadingIndicatorPt /> : 'Withdraw'}
            </PrimaryButton>
          </>
        ) : (
          <>
            <PrimaryButton
              onClick={onApprove}
              disabled={
                isPendingApproval || enoughUSDCApproved || !hasEnoughUSDC
              }
            >
              {isPendingApproval ? <LoadingIndicatorPt /> : 'Approve'}
            </PrimaryButton>
            <PrimaryButton
              onClick={onTrade}
              disabled={
                !enoughUSDCApproved ||
                isPendingTx ||
                !hasEnoughUSDC ||
                assetAmount === 0
              }
            >
              {isPendingTx ? <LoadingIndicatorPt /> : 'Deposit'}
            </PrimaryButton>
          </>
        )}
      </div>

      {(!tradeSide && hasEnoughUSDC) ||
      (tradeSide && hasEnoughStrategyToken) ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>Insufficient {requiredTokenName} amount in your wallet.</span>
        </div>
      )}
    </div>
  )
}

export default LendingTradeForm
