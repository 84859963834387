import React from 'react'
import longIcon from '../../../assets/gammalong.svg'
import shortIcon from '../../../assets/gammashort.svg'

type Props = {
  side: boolean
  onChange: (side: boolean) => void
}

const StrangleSideSelector = ({ side, onChange }: Props) => {
  return (
    <button className="w-full flex justify-center space-x-2 font-normal">
      <div
        className={`w-2/4 px-2 py-1 rounded-xl bg-white1 border-[1px] ${
          side ? 'border-white' : 'border-black0'
        }`}
        onClick={() => {
          onChange(true)
        }}
      >
        <span className="text-green">Long</span> Strangle
        <div className="mt-1 flex justify-center items-center">
          <img src={longIcon} />
        </div>
      </div>
      <div
        className={`w-2/4 px-2 py-1 rounded-xl bg-white1 border-[1px] ${
          !side ? 'border-white' : 'border-black0'
        }`}
        onClick={() => {
          onChange(false)
        }}
      >
        <span className="text-red">Short</span> Strangle
        <div className="mt-1 flex justify-center items-center">
          <img src={shortIcon} />
        </div>
      </div>
    </button>
  )
}

export default StrangleSideSelector
