import { toUnscaled } from '../../../utils/bn'
import { ONE } from '../../../constants'
import { useAsset, useUtilizationRatio } from '../useAsset'
import { useAssetInterest } from '../interest/useAssetInterest'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../../typechain'
import { usePairContext } from '../../usePairContext'
import { useWeb3Provider } from '../../useWeb3Provider'

export function useLendingSummary(
  chainId: number,
  assetId: number,
  isStable: boolean
) {
  const utilization = useUtilizationRatio(chainId, assetId)
  const assetInterest = useAssetInterest(chainId, assetId, isStable)
  const scalers = usePairContext(chainId, assetId)

  if (utilization.isSuccess && assetInterest.isSuccess && scalers.isSuccess) {
    return {
      apy: toUnscaled(assetInterest.data.supplyInterest, 16),
      supply: isStable
        ? toUnscaled(
            utilization.data.stable.supply,
            scalers.data.marginDecimals
          )
        : toUnscaled(
            utilization.data.underlying.supply,
            scalers.data.underlyingDecimals
          ),
      utilization: toUnscaled(
        isStable
          ? utilization.data.stable.utilization
          : utilization.data.underlying.utilization,
        16
      )
    }
  }

  return {
    apy: 0,
    supply: 0,
    utilization: 0
  }
}

export function useLendingPosition(
  chainId: number,
  pairId: number,
  isStable: boolean
) {
  const web3Provider = useWeb3Provider(chainId)
  const pair = useAsset(chainId, pairId)

  return useQuery(
    ['lending_position', chainId, web3Provider.data?.account, pairId, isStable],

    async () => {
      if (!pair.isSuccess) throw new Error('asset not loaded')
      if (!web3Provider.isSuccess) throw new Error('web3provider not set')

      const { provider, account } = web3Provider.data

      const contract = ERC20__factory.connect(
        isStable
          ? pair.data.stablePool.supplyTokenAddress
          : pair.data.underlyingPool.supplyTokenAddress,
        provider
      )
      const balance = await contract.balanceOf(account)

      return balance
        .mul(
          isStable
            ? pair.data.stablePool.tokenStatus.assetScaler
            : pair.data.underlyingPool.tokenStatus.assetScaler
        )
        .div(ONE)
    },

    {
      enabled:
        web3Provider.isSuccess &&
        web3Provider.data.isConnected &&
        pair.isSuccess
    }
  )
}
