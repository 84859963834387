import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  RebalanceHistoryItems,
  REBALANCE_HISTORY_ITEM_QUERY
} from '../../queries/rebalanceHistoryItemQuery'
import { PredyClient } from '../../utils/apollo-client'

export function useRebalanceHistory(chainId: number) {
  const { data, loading } = useQuery<RebalanceHistoryItems>(
    REBALANCE_HISTORY_ITEM_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        first: 200,
        skip: 0
      },
      pollInterval: 20000,
      client: PredyClient[chainId]
    }
  )

  const priceHistory = useMemo(() => {
    if (data) {
      return data.rebalanceHistoryItems.map(item => ({
        pairId: Number(item.pair.pairId),
        tickLower: Number(item.tickLower),
        tickUpper: Number(item.tickUpper),
        profit: BigNumber.from(item.profit),
        createdAt: Number(item.createdAt)
      }))
    }

    return null
  }, [data])

  if (loading || priceHistory === null) {
    return null
  }

  return [...priceHistory]
}
