import React from 'react'
import { useAddresses } from '../../hooks/useAddress'
import { toUnscaled } from '../../utils/bn'
import { useStrategyHistory } from '../../hooks/query/strategy/useStrategyHistory'
import { toTimeString } from '../../utils/string'
import { useStrategyPosition } from '../../hooks/query/strategy/useStrategyPosition'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  STRATEGY_INFOS
} from '../../constants/assets'
import { useWeb3Provider } from '../../hooks/useWeb3Provider'
import { useERC20BalanceQuery } from '../../hooks/query/balance'

const StrategyHistory = ({
  chainId,
  strategyId,
  account
}: {
  chainId: number
  strategyId: number
  account: string
}) => {
  const strategyHistory = useStrategyHistory(chainId, strategyId, account)

  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <div className="text-xs font-normal">
      <table>
        <thead className="block font-normal text-left">
          <tr>
            <th className="w-40 pb-2">Time</th>
            <th className="w-36 pb-2">Symbol</th>
            <th className="w-36 pb-2">Side</th>
            <th className="w-36 pb-2">Price</th>
            <th className="w-36 pb-2">Quantity</th>
            <th className="w-36 pb-2">Profit Realized</th>
          </tr>
        </thead>
        <tbody className="block overflow-x-hidden overflow-y-scroll h-40 text-xs">
          {strategyHistory.strategyHistory.map((item, i) => {
            return (
              <tr className="h-8" key={i}>
                <td className="w-40 py-2">{toTimeString(item.timestamp)}</td>
                <td className="w-36">Strategy</td>
                <td className="w-36">{item.action}</td>
                <td className="w-36">{toUnscaled(item.price, 18, 4)}</td>
                <td className="w-36">
                  {toUnscaled(item.strategyAmount, marginInfo.decimals)}
                </td>
                <td className="w-36">
                  {toUnscaled(item.payoff, marginInfo.decimals)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const StrategyPositionSummary = ({
  chainId,
  strategyId,
  account
}: {
  chainId: number
  strategyId: number
  account: string
}) => {
  const strategyPosition = useStrategyPosition(chainId, strategyId, account)

  const balance = useERC20BalanceQuery(
    chainId,
    STRATEGY_INFOS[chainId][strategyId].strategyTokenAddress
  )

  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <div className="flex justify-center items-center space-x-1 md:space-x-10">
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Strategy in Wallet</div>
        <div>
          {balance.isSuccess
            ? toUnscaled(balance.data, marginInfo.decimals)
            : 0}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Current Value</div>
        <div>
          {strategyPosition.isSuccess ? strategyPosition.data.currentValue : 0}{' '}
          {marginInfo.name}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Entry Price</div>
        <div>
          {strategyPosition.isSuccess ? strategyPosition.data.price : 0}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Profit Unrealized</div>
        <div>
          {strategyPosition.isSuccess
            ? strategyPosition.data.profitUnrealized
            : 0}{' '}
          {marginInfo.name}
        </div>
      </div>
    </div>
  )
}

const StrategyPosition = ({
  chainId,
  strategyId
}: {
  chainId: number
  strategyId: number
}) => {
  const web3provider = useWeb3Provider(chainId)
  const addresses = useAddresses(chainId)

  return (
    <div className="p-4 rounded-2xl bg-secondaly text-xs font-semibold">
      <div className="mb-2 px-4 md:px-8 flex justify-start items-center space-x-1 md:space-x-10">
        <div className="text-sm">Trade History</div>
        {web3provider.isSuccess &&
        web3provider.data.isConnected &&
        web3provider.data.account &&
        addresses ? (
          <StrategyPositionSummary
            chainId={chainId}
            strategyId={strategyId}
            account={web3provider.data.account}
          />
        ) : (
          <div />
        )}
        <div></div>
      </div>
      <div className="mt-6">
        {web3provider.isSuccess &&
        web3provider.data.isConnected &&
        web3provider.data.account ? (
          <StrategyHistory
            chainId={chainId}
            strategyId={strategyId}
            account={web3provider.data.account}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default StrategyPosition
