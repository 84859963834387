import React, { useState } from 'react'
import { BigNumber } from 'ethers'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useUpdateMargin } from '../../hooks/contracts/useUpdateMargin'
import { useERC20BalanceQueryCached } from '../../hooks/query/balance'
import { useAddresses } from '../../hooks/useAddress'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import { AmountForm } from '../common/AmountForm'
import pendingStore from '../../store/pending'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import PendingIndicator from '../common/PendingIndicator'
import crossIcon from '../../assets/close.png'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { MARGIN_INFOS } from '../../constants/assets'

type Props = {
  chainId: number
  pairGroupId: number
  onClose: () => void
}

export const DepositModal = ({ chainId, pairGroupId, onClose }: Props) => {
  const addresses = useAddresses(chainId)
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

  const quoteToken = marginInfo.tokenAddress

  const balanceQuery = useERC20BalanceQueryCached(chainId, quoteToken)
  const allowanceQuery = useAllowanceQuery(
    chainId,
    addresses.Controller,
    quoteToken
  )

  const updateMargin = useUpdateMargin()
  const approve = useApproveMutation()
  const { isPendingApproval, isPendingTx, setPendingApproval, setPendingTx } =
    pendingStore()

  const vaultStatusQuery = useVaultStatus(chainId, pairGroupId)

  const [amount, setAmount] = useState(0)

  const onApprove = async (amount: BigNumber) => {
    const tx = await approve.mutateAsync({
      amount,
      address: quoteToken,
      spender: addresses.Controller
    })

    await setPendingApproval(tx)
  }

  const onDepositMargin = async (amount: BigNumber) => {
    if (!vaultStatusQuery.isSuccess) {
      return
    }

    const tx = await updateMargin.mutateAsync({
      pairGroupId,
      marginAmount: amount
    })

    await setPendingTx(tx)

    // clear amount

    setAmount(0)
  }

  const enoughUSDCApproved = allowanceQuery.gte(
    toScaled(amount, marginInfo.decimals)
  )

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-end">
        <div
          className="rounded-lg bg-white text-[#809fb8] w-6 h-6 flex items-center justify-center"
          onClick={onClose}
        >
          <img src={crossIcon} />
        </div>
      </div>
      <div className="flex items-center justify-start text-xl">Deposit</div>
      <div className="px-4">
        <div className="flex items-center justify-center text-sm text-subtext font-normal">
          Predy is a non-custodial protocol; only the wallet can access the
          vault account. You are responsible for your wallet security and can
          withdraw the available cryptocurrency anytime.
        </div>
        <div className="flex items-center justify-center pt-8">
          <AmountForm
            title={'Amount'}
            unit={marginInfo.name}
            amount={amount}
            onChange={amount => {
              setAmount(amount)
            }}
          />
        </div>
        <div className="flex items-center justify-between text-subtext px-4 pt-4">
          <div>In Wallet</div>
          <div>
            {toUnscaled(
              balanceQuery,
              marginInfo.decimals,
              marginInfo.fractionDigits
            )}{' '}
            {marginInfo.name}
          </div>
        </div>
        <div className="flex items-center justify-between text-subtext px-4 pt-4">
          {vaultStatusQuery.isSuccess &&
          vaultStatusQuery.data.mainVault === null ? (
            <>
              <div>In Vault</div>
              <div>no vault</div>
            </>
          ) : (
            <>
              <div>In Vault</div>
              <div>
                {vaultStatusQuery.isSuccess && vaultStatusQuery.data.mainVault
                  ? toUnscaled(
                      vaultStatusQuery.data.mainVault.withdrawable,
                      marginInfo.decimals,
                      marginInfo.fractionDigits
                    )
                  : '-'}{' '}
                {marginInfo.name}
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-between pt-8">
          <div className="w-36 h-12 p-1">
            <PrimaryButton
              disabled={isPendingApproval || enoughUSDCApproved}
              onClick={() => {
                onApprove(toScaled(amount, marginInfo.decimals))
              }}
            >
              {isPendingApproval ? <PendingIndicator /> : 'Approve'}
            </PrimaryButton>
          </div>
          <div className="w-36 h-12 p-1">
            <PrimaryButton
              disabled={!enoughUSDCApproved || isPendingTx || amount === 0}
              onClick={() => {
                onDepositMargin(toScaled(amount, marginInfo.decimals))
              }}
            >
              {isPendingTx ? <PendingIndicator /> : 'Deposit'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
