import { BigNumber, BigNumberish } from 'ethers'
import { ONE, ZERO } from '../constants'

export type IRMParams = {
  baseRate: BigNumber
  kinkRate: BigNumber
  slope1: BigNumber
  slope2: BigNumber
}

export function calculateInterestRate(params: IRMParams, ur: BigNumber) {
  if (ur.lt(0)) {
    throw new Error('utilization ratio must be positive value.')
  }

  let ir = params.baseRate

  if (ur.lte(params.kinkRate)) {
    ir = ir.add(ur.mul(params.slope1).div(ONE))
  } else {
    ir = ir.add(params.kinkRate.mul(params.slope1).div(ONE))
    ir = ir.add(params.slope2.mul(ur.sub(params.kinkRate)).div(ONE))
  }

  return ir
}

const SQRT_KINK_UR = BigNumber.from(10).pow(16).mul(10)

/**
 * Calculates premium curve
 * 0 {ur <= 0.1}
 * 1.6 * (UR-0.1)^2 {0.1 < ur}
 * @param _utilization utilization ratio scaled by 1e18
 * @return spread parameter scaled by 1e3
 */
export function calculatePremiumCurve(_utilization: BigNumberish) {
  const ur = BigNumber.from(_utilization)

  if (ur.lte(SQRT_KINK_UR)) {
    return ZERO
  }

  const b = ur.sub(SQRT_KINK_UR)

  return b.mul(b).mul(1600).div(ONE).div(ONE)
}
