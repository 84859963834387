import { BigNumber, BigNumberish, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller, Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { REFETCH_INTERVAL, ZERO } from '../../constants'
import { usePrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { PairScalers } from '../../utils/helpers/scaler'

type QuoteResult = {
  tradeAmount: BigNumber
  squartAmount: BigNumber
  perpEntryPrice: BigNumber
  sqrtEntryPrice: BigNumber
  perpEntryUpdate: BigNumber
  sqrtEntryUpdate: BigNumber
  sqrtRebalanceEntryUpdateUnderlying: BigNumber
  sqrtRebalanceEntryUpdateStable: BigNumber
  perpPayoff: BigNumber
  sqrtPayoff: BigNumber
  fee: BigNumber
  minDeposit: BigNumber
}

type QuoterResult =
  | {
      error: string
      data: null
    }
  | {
      error: null
      data: QuoteResult
    }

function convertToQuoteResult(
  pairId: number,
  data: any,
  tradeAmount: BigNumber,
  squartAmount: BigNumber,
  chainId: number
) {
  const scalers = new PairScalers(pairId, chainId)
  return {
    tradeAmount,
    squartAmount,
    perpEntryPrice: tradeAmount.eq(0)
      ? ZERO
      : data[0][0]
          .add(data[0][4])
          .mul(scalers.underlyingScaler)
          .div(tradeAmount)
          .abs(),
    sqrtEntryPrice: squartAmount.eq(0)
      ? ZERO
      : data[0][1]
          .add(data[0][5])
          .mul(scalers.squartScaler)
          .div(squartAmount)
          .abs(),
    perpEntryUpdate: data[0][0],
    sqrtEntryUpdate: data[0][1],
    sqrtRebalanceEntryUpdateUnderlying: data[0][2],
    sqrtRebalanceEntryUpdateStable: data[0][3],
    perpPayoff: data[0][4],
    sqrtPayoff: data[0][5],
    fee: data[1],
    minDeposit: data[2]
  }
}

type TradePerpParams = {
  vaultId: number
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
}

type IsolatedTradeParams = {
  vaultId: number
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
  marginAmount: BigNumber
}

type BaseTradeParams = {
  vaultId: number
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
}

function useBaseTradeQuoter<T extends BaseTradeParams>(
  chainId: number,
  key: string,
  params: T,
  cb: (
    contract: Controller,
    params: T,
    sqrtPrice: BigNumber,
    deadline: number,
    account: string,
    chainId: number
  ) => Promise<any>,
  enableCb: (params: T) => boolean
) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)
  const deadline = useDeadline()
  const price = usePrice(chainId, params.assetId)

  return useQuery<QuoterResult>(
    [key, account, chainId, params],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      return queryQuoteBase(
        contract,
        params,
        price.data.sqrtPrice,
        deadline.data,
        account,
        chainId,
        cb
      )
    },
    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess &&
        enableCb(params),
      refetchInterval: REFETCH_INTERVAL
    }
  )
}

export function useTradeQuoter(chainId: number, params: TradePerpParams) {
  return useBaseTradeQuoter(
    chainId,
    'quoter_trade_perp',
    params,
    queryTradeQuoter,
    (params: TradePerpParams) => {
      return (
        !BigNumber.from(params.tradeAmount).eq(0) ||
        !BigNumber.from(params.tradeAmountSqrt).eq(0)
      )
    }
  )
}

export async function queryTradeQuoter(
  contract: Controller,
  params: TradePerpParams,
  sqrtPrice: BigNumber,
  deadline: number,
  account: string,
  chainId: number
) {
  const tradeParams = {
    tradeAmount: params.tradeAmount,
    tradeAmountSqrt: params.tradeAmountSqrt,
    lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice, chainId),
    upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice, chainId),
    deadline: deadline,
    enableCallback: false,
    data: '0x'
  }
  if (params.vaultId === undefined) {
    const data = await contract.callStatic.openIsolatedPosition(
      0,
      params.assetId,
      tradeParams,
      1,
      true,
      { from: account }
    )

    return data[1]
  } else {
    const data = await contract.callStatic.tradePerp(
      params.vaultId,
      params.assetId,
      tradeParams,
      { from: account }
    )

    return data
  }
}

export function useIsolatedTradeQuoter(
  chainId: number,
  params: IsolatedTradeParams
) {
  return useBaseTradeQuoter(
    chainId,
    'quoter_open_vault',
    params,
    async (
      contract: Controller,
      params: IsolatedTradeParams,
      sqrtPrice: BigNumber,
      deadline: number,
      account: string,
      chainId: number
    ) => {
      return queryIsolatedTradeQuoter(
        contract,
        params,
        sqrtPrice,
        deadline,
        account,
        chainId
      )
    },
    (params: IsolatedTradeParams) => {
      return (
        !BigNumber.from(params.tradeAmount).eq(0) ||
        !BigNumber.from(params.tradeAmountSqrt).eq(0) ||
        !BigNumber.from(params.marginAmount).eq(0)
      )
    }
  )
}

export async function queryIsolatedTradeQuoter(
  contract: Controller,
  params: IsolatedTradeParams,
  sqrtPrice: BigNumber,
  deadline: number,
  account: string,
  chainId: number
) {
  if (
    BigNumber.from(params.tradeAmount).eq(0) &&
    BigNumber.from(params.tradeAmountSqrt).eq(0) &&
    BigNumber.from(params.marginAmount).eq(0)
  ) {
    throw new Error('AZ')
  }

  const tradeParams = {
    tradeAmount: params.tradeAmount,
    tradeAmountSqrt: params.tradeAmountSqrt,
    lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice, chainId),
    upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice, chainId),
    deadline: deadline,
    enableCallback: false,
    data: '0x'
  }

  if (params.marginAmount.gt(ZERO)) {
    const data = await contract.callStatic.openIsolatedPosition(
      params.vaultId || 0,
      params.assetId,
      tradeParams,
      params.marginAmount,
      params.vaultId === 0,
      { from: account }
    )

    return data[1]
  } else {
    const data = await contract.callStatic.closeIsolatedPosition(
      params.vaultId,
      params.assetId,
      tradeParams,
      params.marginAmount.mul(-1),
      { from: account }
    )

    return data
  }
}

export async function queryQuoteBase<T extends BaseTradeParams>(
  contract: Controller,
  params: T,
  sqrtPrice: BigNumber,
  deadline: number,
  account: string,
  chainId: number,
  cb: (
    contract: Controller,
    params: T,
    sqrtPrice: BigNumber,
    deadline: number,
    account: string,
    chainId: number
  ) => Promise<any>
) {
  try {
    const data = await cb(
      contract,
      params,
      sqrtPrice,
      deadline,
      account,
      chainId
    )

    return {
      error: null,
      data: convertToQuoteResult(
        params.assetId,
        data,
        BigNumber.from(params.tradeAmount),
        BigNumber.from(params.tradeAmountSqrt),
        chainId
      )
    }
  } catch (e: any) {
    if (e.error && e.error.message === "Non-200 status code: '429'") {
      throw new Error(e.error.message)
    }
    if (e.reason || e.data) {
      return {
        error: String(e.reason || e.data.message),
        data: null
      }
    } else {
      return {
        error: e.message,
        data: null
      }
    }
  }
}

export function useCloseVaultQuoter(
  chainId: number,
  params: IsolatedTradeParams
) {
  return useBaseTradeQuoter(
    chainId,
    'quoter_close_vault',
    params,
    async (
      contract: Controller,
      params: IsolatedTradeParams,
      sqrtPrice: BigNumber,
      deadline: number,
      account: string,
      chainId: number
    ) => {
      const data = await contract.callStatic.closeIsolatedPosition(
        params.vaultId,
        params.assetId,
        {
          tradeAmount: params.tradeAmount,
          tradeAmountSqrt: params.tradeAmountSqrt,
          lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice, chainId),
          upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice, chainId),
          deadline: deadline,
          enableCallback: false,
          data: '0x'
        },
        params.marginAmount,
        { from: account }
      )

      return data
    },
    (params: IsolatedTradeParams) => {
      return (
        !BigNumber.from(params.tradeAmount).eq(0) ||
        !BigNumber.from(params.tradeAmountSqrt).eq(0) ||
        !BigNumber.from(params.marginAmount).eq(0)
      )
    }
  )
}
