import React, { useState } from 'react'
import { useFeeEst } from '../../../hooks/query/interest/useFeeEst'
import { VaultStatusResult } from '../../../hooks/query/useVaultStatus'
import { toUnscaled } from '../../../utils/bn'
import OpenPositions from './OpenPositions'
import TradeHistory from './TradeHistory'
import { getDelta, getGamma } from '../../../utils/bs'
import { usePrice } from '../../../hooks/usePrice'
import { ZERO } from '../../../constants'
import { Tab, TabList } from '../../common/Tab'
import { MyPositionTab } from '../../../constants/enum'
import { PrimaryButton } from '../../common/Button'
import { useTradePerp } from '../../../hooks/contracts/useTradePerp'
import { useExtraVaultStatus } from '../../../hooks/query/useExtraVaultStatus'
import { toUnscaledSqrtPrice } from '../../../utils/price'
import { PairScalers } from '../../../utils/helpers/scaler'
import { useCloseIsolatedVault } from '../../../hooks/contracts/useCloseIsolatedVault'
import { usePairContext } from '../../../hooks/usePairContext'

type Prop = {
  chainId: number
  assetId: number
  vaultId: number
}

const InterestEstPanel = ({ chainId, assetId, vaultId }: Prop) => {
  const interestEst = useFeeEst(chainId, assetId, [vaultId], ZERO, ZERO)
  const pairContext = usePairContext(chainId, assetId)

  return (
    <>
      {pairContext.data?.toMarginString(
        interestEst.isSuccess ? interestEst.data.total : undefined,
        {
          showFull: false,
          symbolRequired: true
        }
      )}
    </>
  )
}

export const VaultInterestEstPanel = ({
  chainId,
  vaultId,
  assetId
}: {
  chainId: number
  vaultId: number
  assetId: number
}) => {
  return (
    <InterestEstPanel chainId={chainId} assetId={assetId} vaultId={vaultId} />
  )
}

const MyPosition = ({
  chainId,
  vault,
  assetId,
  isIsolatedVault
}: {
  chainId: number
  vault: VaultStatusResult | null
  assetId: number
  isIsolatedVault?: boolean
}) => {
  const [tab, setTab] = useState(MyPositionTab.Positions)
  const [hasPendingTx, setHasPendingTx] = useState(false)
  const scalers = new PairScalers(assetId, chainId)

  const extraPositionStatus = useExtraVaultStatus(
    chainId,
    assetId,
    isIsolatedVault || false
  )
  const trade = useTradePerp(assetId)
  const closeIsolatedVault = useCloseIsolatedVault(assetId)

  const vaultId = vault ? vault.id : 0

  const position = vault
    ? vault.openPositionStatusResults.find(
        openPosition => openPosition.assetId === assetId
      )
    : undefined

  const price = usePrice(chainId, assetId)

  const onClose = async () => {
    if (vault === null) {
      return
    }

    const tradeAmount = position ? position.perpStatus.amount.mul(-1) : ZERO
    const tradeAmountSqrt = position ? position.sqrtStatus.amount.mul(-1) : ZERO

    let tx = null

    if (isIsolatedVault) {
      tx = await closeIsolatedVault.mutateAsync({
        isolatedVaultId: vault.id,
        assetId,
        tradeAmount,
        tradeAmountSqrt,
        marginAmount: ZERO
      })
    } else {
      tx = await trade.mutateAsync({
        vaultId: vault.id,
        assetId,
        tradeAmount,
        tradeAmountSqrt
      })
    }

    setHasPendingTx(true)
    await tx.wait()
    setHasPendingTx(false)
  }

  return (
    <div className="p-4 rounded-2xl bg-secondaly border-[1px] border-white">
      <div className="md:w-[674px] xl:w-[820px] mb-4 flex justify-between border-b-[1px] border-white">
        <div>
          <TabList
            onSelect={tabIndex => {
              setTab(tabIndex as MyPositionTab)
            }}
          >
            <Tab
              selected={tab === MyPositionTab.Positions}
              tabId={MyPositionTab.Positions}
            >
              Positions
            </Tab>
            <Tab
              selected={tab === MyPositionTab.TradeHistory}
              tabId={MyPositionTab.TradeHistory}
            >
              Trade History
            </Tab>
          </TabList>
        </div>
        <div className="hidden sm:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Vault Value</div>
          <div>
            {scalers.toMarginString(vault ? vault.vaultValue : ZERO, {
              showFull: false,
              symbolRequired: true
            })}
          </div>
        </div>
        <div className="hidden sm:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Margin Utilizing</div>
          <div>
            {scalers.toMarginString(vault ? vault.minDeposit : ZERO, {
              showFull: false,
              symbolRequired: true
            })}
          </div>
        </div>
        <div className="flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Daily Fee est.</div>
          <div>
            <VaultInterestEstPanel
              chainId={chainId}
              vaultId={vaultId}
              assetId={assetId}
            />
          </div>
        </div>
        <div className="hidden xl:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Delta</div>
          <div>
            {position
              ? getDelta(
                  toUnscaled(
                    position.perpStatus.amount,
                    scalers.underlyingDecimals
                  ),
                  toUnscaled(
                    position.sqrtStatus.amount,
                    scalers.squartDecimals
                  ),
                  price.isSuccess
                    ? toUnscaledSqrtPrice(price.data.sqrtPrice, scalers)
                    : 0
                ).toFixed(4)
              : 0}
          </div>
        </div>
        <div className="hidden xl:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Gamma</div>
          <div>
            {position
              ? getGamma(
                  toUnscaled(
                    position.sqrtStatus.amount,
                    scalers.squartDecimals
                  ),
                  price.isSuccess
                    ? toUnscaledSqrtPrice(price.data.sqrtPrice, scalers)
                    : 0
                ).toFixed(4)
              : 0}
          </div>
        </div>

        <div className="hidden xl:block w-[168px] h-8">
          <PrimaryButton onClick={onClose} disabled={hasPendingTx}>
            <span className="text-lg">Close Positions</span>
          </PrimaryButton>
        </div>
      </div>
      {tab === MyPositionTab.Positions ? (
        <OpenPositions
          chainId={chainId}
          vaultId={vaultId}
          assetId={assetId}
          openPosition={position}
          extraPositionStatus={extraPositionStatus.data}
        />
      ) : (
        <TradeHistory chainId={chainId} vaultId={vaultId} scalers={scalers} />
      )}
    </div>
  )
}

export default MyPosition
