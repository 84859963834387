import React from 'react'

const NotFound = () => {
  return (
    <div className="h-[840px] flex justify-center items-center">
      <div>
        <div className="text-lg text-white text-center">Page not found</div>
        <div className="text-base text-subtext text-center">
          Return to{' '}
          <a className="text-white underline" href="/arbitrum">
            Arbitrum Home
          </a>
          or{' '}
          <a className="text-white underline" href="/base">
            Base Home
          </a>
        </div>
      </div>
    </div>
  )
}

export default NotFound
