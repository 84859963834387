import React from 'react'
import { useLendingSummary } from '../../hooks/query/lending/useLendingSummary'
import { toSupplyAmountString } from '../../utils/number'
import InterestRateModelChart from './InterestRateModelChart'
import { useAsset } from '../../hooks/query/useAsset'
import LoadingIndicator from '../common/LoadingIndicator'
import assetTypeStore from '../../store/assetType'
import ChartTop from '../main/center/ChartTop'
import { LendingSelector } from './LendingSelector'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'

const LendingCenterArea = ({
  chainId,
  pairGroupId,
  assetId
}: {
  chainId: number
  pairGroupId: number
  assetId: number
}) => {
  const { isStable } = assetTypeStore()
  const asset = useAsset(chainId, assetId)
  const lendingSummary = useLendingSummary(chainId, assetId, isStable)
  const pairInfo = ASSET_INFOS[chainId][assetId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <div>
      <ChartTop chainId={chainId} assetId={assetId}>
        <LendingSelector pairGroupId={pairGroupId} />
      </ChartTop>
      <div className="mt-4 flex justify-center">
        <div className="w-[696px]">
          <h1 className="text-3xl font-bold">Lending Pool</h1>
          <p className="text-base leading-6">
            This Lending pool is designed similarly to Aave. Combining the
            borrowing side with Futures trading keeps Utilization high and makes
            the APY more attractive.
          </p>
        </div>
      </div>
      <div className="mt-4 flex justify-center items-start space-x-2">
        <div className="w-[520px] h-full mt-2">
          {asset.isSuccess ? (
            <InterestRateModelChart
              chainId={chainId}
              pairId={assetId}
              isStable={isStable}
            />
          ) : (
            <div className="flex justify-center items-center">
              <div className="w-12 h-12">
                <LoadingIndicator />
              </div>
            </div>
          )}
        </div>
        <div className="w-[160px] h-full flex flex-col space-y-2">
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Current APY. est.</div>
            <div className="text-xl text-right">
              {lendingSummary.apy.toFixed(2)}%
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Current Supply</div>
            <div className="text-xl">
              {toSupplyAmountString(lendingSummary.supply)}{' '}
              {isStable ? marginInfo.name : pairInfo.name}
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Utilization Rate</div>
            <div className="text-xl">
              {lendingSummary.utilization.toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LendingCenterArea
