import React from 'react'
import usdcIcon from '../../assets/assets/usdc.svg'
import strategyStore from '../../store/strategy'
import { BasePopup } from '../common/BasePopup'
import { AiFillCaretDown } from 'react-icons/ai'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  STRATEGY_INFOS
} from '../../constants/assets'
import { useStrategyApr } from '../../hooks/query/strategy/useStrategyApr'
import { toUnscaled } from '../../utils/bn'
import { useTradeRoute } from '../../hooks/useTradeRoute'
import InfoTooltip from '../common/InfoTooltip'

const StrategySelectorItem = ({
  chainId,
  strategyId,
  isShortStrangle,
  onSelected
}: {
  chainId: number
  strategyId: number
  isShortStrangle: boolean
  onSelected: (strategyId: number) => void
}) => {
  const strategyApr = useStrategyApr(chainId, strategyId)

  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <div
      className="px-4 py-1 flex justify-between justify-items-end cursor-pointer hover:bg-white5"
      onClick={() => {
        onSelected(strategyId)
      }}
    >
      <div className="w-[140px] flex justify-start items-center">
        <div>
          {pairInfo.name}/{marginInfo.name}
          {pairInfo.exTooltip ? '.ex' : ''}
        </div>
        {pairInfo.exTooltip ? (
          <InfoTooltip placement="bottom">
            <p className="text-center">
              This Perpetual contract is linked with a Lending Pool that has a
              higher Borrow Rate set. It is an experimental attempt to measure
              the supply elasticity of lending in relation to the Borrow Rate.
            </p>
          </InfoTooltip>
        ) : (
          <></>
        )}
      </div>
      <div className="w-28 text-left">
        {isShortStrangle ? 'Short Strangle' : 'Long Strangle'}
      </div>
      <div className="w-24 text-right">{strategyInfo.squartPerSt}</div>
      <div
        className={`w-20 text-right ${
          strategyApr.dayApr.gt(0) ? 'text-green' : 'text-red'
        }`}
      >
        {strategyApr.dayApr.gt(0) ? '+' : '-'}
        {toUnscaled(strategyApr.dayApr.abs(), 16, 2)}%
      </div>
      <div
        className={`w-20 text-right ${
          strategyApr.yearApr.gt(0) ? 'text-green' : 'text-red'
        }`}
      >
        {strategyApr.yearApr.gt(0) ? '+' : '-'}
        {toUnscaled(strategyApr.yearApr.abs(), 16, 2)}%
      </div>
    </div>
  )
}

export const StrategySelector = ({ chainId }: { chainId: number }) => {
  const tradeRoute = useTradeRoute()
  const { selectedStrategyId, selectStrategyId } = strategyStore()

  const strategyInfo = STRATEGY_INFOS[chainId][selectedStrategyId]
  const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]
  const strategyInfos = marginInfo.strategies.map(
    strategyId => STRATEGY_INFOS[chainId][strategyId]
  )

  return (
    <BasePopup
      isWide
      titleContent={
        <div className="flex justify-center items-center space-x-1 cursor-pointer">
          <div className="flex justify-center items-center space-x-0">
            <img src={strategyInfo.icon} width={24} className="rounded-full" />
            <img src={usdcIcon} width={24} />
          </div>
          <span className="text-base">
            {pairInfo.name}/{marginInfo.name}
          </span>
          <AiFillCaretDown />
        </div>
      }
    >
      <div className="w-[520px] px-4 pt-2 flex justify-between justify-items-end text-xs">
        <div className="w-[140px]">Pair</div>
        <div className="w-28 text-left">Position</div>
        <div className="w-24 text-right">Squart/Strategy</div>
        <div className="w-20 text-right">24h</div>
        <div className="w-20 text-right">APR</div>
      </div>
      <div className="py-4 flex flex-col space-y-1">
        {strategyInfos.map(strategyInfo => {
          return (
            <StrategySelectorItem
              key={strategyInfo.id}
              chainId={chainId}
              strategyId={strategyInfo.id}
              isShortStrangle={strategyInfo.isGammaShort}
              onSelected={() => {
                selectStrategyId(strategyInfo.id)
                tradeRoute.setPairId(strategyInfo.id)
              }}
            />
          )
        })}
      </div>
    </BasePopup>
  )
}
