import { ethers, BigNumber, ContractTransaction } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useMutation } from 'react-query'
import { MockERC20__factory } from '../../typechain'

type MintParams = {
  amount: BigNumber
  address: string
  account: string
}

export function useMintMutation() {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()

  return useMutation<ContractTransaction, unknown, MintParams>(
    async ({ address, account, amount }) => {
      if (!provider) throw new Error('not connected')
      /*
      const usdc = ERC20__factory.connect('0xE060e715B6D20b899A654687c445ed8BC35f9dFF', provider.getSigner())
      const wbtc = ERC20__factory.connect('0x163691b2153F4e18F3c3F556426b7f5C74a99FA4', provider.getSigner())
      const nftManAddress = '0x761e43a84f7c246E9dfEF3d0e358de0d4E05016d'

      const contract = INonfungiblePositionManager__factory.connect(nftManAddress, provider.getSigner())
      await usdc.approve(nftManAddress, '1600000000000')
      await wbtc.approve(nftManAddress, '1000000000000000000000')
      return await contract.increaseLiquidity({
        tokenId: 1,
        amount0Desired: '1000000000000000000000',
        amount1Desired: '1600000000000',
        amount0Min: '0',
        amount1Min: '0',
        deadline: 1687434303
      })
      */
      const contract = MockERC20__factory.connect(address, provider.getSigner())
      return await contract.mint(account, amount)
    }
  )
}
