import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { STALE_TIME, ZERO } from '../../constants'
import { Multicall__factory } from '../../typechain/multicall/index'

export type Vault = {
  vaultId: number
  owner: string
  margin: BigNumber
  openPositions: {
    assetId: number
    sqrtPerpPosition: BigNumber
    underlyingAssetOrDebtAmount: BigNumber
    stableAssetOrDebtAmount: BigNumber
    perpPosition: BigNumber
    stableEntryValue: BigNumber
  }[]
}

export function createEmptyPosition(assetId: number) {
  return {
    assetId,
    sqrtPerpPosition: ZERO,
    underlyingAssetOrDebtAmount: ZERO,
    stableAssetOrDebtAmount: ZERO,
    perpPosition: ZERO,
    stableEntryValue: ZERO
  }
}

export function useVault(chainId: number, vaultId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)

  return useQuery<Vault>(
    ['vault', chainId, vaultId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const vault = await contract.getVault(vaultId)

      return {
        vaultId: vault[0].toNumber(),
        owner: vault[2],
        margin: vault[3],
        openPositions: vault[5].map(openPosition => {
          return {
            assetId: openPosition[0].toNumber(),
            sqrtPerpPosition: openPosition[5][0],
            underlyingAssetOrDebtAmount: openPosition[6][0],
            stableAssetOrDebtAmount: openPosition[7][0],
            perpPosition: openPosition[4][0],
            stableEntryValue: openPosition[4][1].add(openPosition[5][1])
          }
        })
      }
    },

    {
      enabled:
        !!account && supportedChain && !!provider && !!addresses && vaultId > 0,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )
}

export function useVaults(chainId: number, vaultIds: number[]) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)

  return useQuery<Vault[]>(
    ['vaults', chainId, vaultIds],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const calls = vaultIds.map(vaultId => ({
        target: addresses.Controller,
        callData: controller.interface.encodeFunctionData('getVault', [vaultId])
      }))

      const result = await multicall.callStatic.aggregate(calls, {
        from: account
      })

      return result.returnData
        .map(returnData => {
          return controller.interface.decodeFunctionResult(
            'getVault',
            returnData
          )[0]
        })
        .map(decodedResult => {
          return {
            vaultId: decodedResult[0],
            owner: decodedResult[2],
            margin: decodedResult[3],
            openPositions: decodedResult[5].map((openPosition: any) => {
              return {
                assetId: openPosition[0].toNumber(),
                sqrtPerpPosition: openPosition[5][0],
                underlyingAssetOrDebtAmount: openPosition[6][0],
                stableAssetOrDebtAmount: openPosition[7][0],
                perpPosition: openPosition[4][0],
                stableEntryValue: openPosition[4][1].add(openPosition[5][1])
              }
            })
          }
        })
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )
}
