import { BigNumber } from 'ethers'
import { ONE, ZERO } from '../../../constants'
import { AggregationInterval } from '../../../queries/aggregatedPriceQuery'
import { useStrategyPriceHistory } from './useStrategyPrices'

type HistoryItem = {
  address: string
  openPrice: BigNumber
  closePrice: BigNumber
  timestamp: number
}

export function useStrategyApr(chainId: number, strategyId: number) {
  const priceHistoryHourly = useStrategyPriceHistory(
    chainId,
    strategyId,
    AggregationInterval.Hourly,
    7 * 24
  )
  const priceHistoryDaily = useStrategyPriceHistory(
    chainId,
    strategyId,
    AggregationInterval.Daily,
    365
  )

  function getApr(latest: HistoryItem, start: HistoryItem, days: number) {
    const apr = latest.closePrice.mul(ONE).div(start.closePrice).sub(ONE)
    const span = latest.timestamp - start.timestamp

    if (span === 0) {
      return ZERO
    }

    return apr.mul(60 * 60 * 24 * days).div(span)
  }

  if (priceHistoryHourly.isSuccess && priceHistoryDaily.isSuccess) {
    if (
      priceHistoryHourly.data.length > 1 &&
      priceHistoryDaily.data.length > 1
    ) {
      const latestHourly = priceHistoryHourly.data.length - 1
      const latestDaily = priceHistoryDaily.data.length - 1

      // hourly
      const dayBefore = latestHourly >= 24 ? latestHourly - 24 : 0
      const weekBefore = latestHourly >= 168 ? latestHourly - 168 : 0

      // daily
      const monthBefore = latestDaily >= 30 ? latestDaily - 30 : 0
      const yearBefore = latestDaily >= 365 ? latestDaily - 365 : 0

      const dayApr = getApr(
        priceHistoryHourly.data[latestHourly],
        priceHistoryHourly.data[dayBefore],
        1
      )
      const weekApr = getApr(
        priceHistoryHourly.data[latestHourly],
        priceHistoryHourly.data[weekBefore],
        7
      )
      const monthApr = getApr(
        priceHistoryDaily.data[latestDaily],
        priceHistoryDaily.data[monthBefore],
        30
      )
      const yearApr = getApr(
        priceHistoryDaily.data[latestDaily],
        priceHistoryDaily.data[yearBefore],
        365
      )

      return {
        dayApr,
        weekApr,
        monthApr,
        yearApr
      }
    }
  }

  return {
    dayApr: ZERO,
    weekApr: ZERO,
    monthApr: ZERO,
    yearApr: ZERO
  }
}
