import React, { useState } from 'react'
import PnLChart, { ChartMode } from './PnLChart'
import { usePnLChartData } from '../../../hooks/query/chart/usePnLChartData'
import { LoadingIndicatorPt } from '../../common/LoadingIndicator'
import positionStore from '../../../store/position'
import gammaStore from '../../../store/gamma'
import { SimpleTab, SimpleTabList } from '../../common/SimpleTab'
import { getStylePrice } from '../trade/StyleSelector'
import { toUnscaled } from '../../../utils/bn'
import { ASSET_INFOS, MARGIN_INFOS } from '../../../constants/assets'

const PnLChartWrapper = ({
  chainId,
  vaultId,
  assetId,
  showAfterTraded
}: {
  chainId: number
  vaultId: number
  assetId: number
  showAfterTraded: boolean
}) => {
  const { afterPerp, after2Squart, afterEntryUpdate, afterMargin } =
    positionStore()
  const { targetStrategy } = gammaStore()

  const assetInfo = ASSET_INFOS[chainId][assetId]
  const pairGroupId = assetInfo.pairGroupId
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

  const [chartMode, setChartMode] = useState(ChartMode.Value)
  const chartData = usePnLChartData(
    chainId,
    assetId,
    vaultId,
    afterPerp,
    after2Squart,
    afterEntryUpdate,
    afterMargin
  )

  return (
    <div className="my-8 mx-auto w-[680px]">
      <SimpleTabList
        onSelect={tabIndex => {
          setChartMode(tabIndex as ChartMode)
        }}
      >
        <SimpleTab
          selected={chartMode === ChartMode.Value}
          tabId={ChartMode.Value}
        >
          P&L
        </SimpleTab>
        <SimpleTab
          selected={chartMode === ChartMode.Delta}
          tabId={ChartMode.Delta}
        >
          Delta
        </SimpleTab>
        <SimpleTab
          selected={chartMode === ChartMode.Gamma}
          tabId={ChartMode.Gamma}
        >
          Gamma
        </SimpleTab>
      </SimpleTabList>
      <div>
        {chartData.isSuccess ? (
          <PnLChart
            price={toUnscaled(chartData.data.price, marginInfo.decimals)}
            style={toUnscaled(
              getStylePrice(
                after2Squart.lt(0),
                targetStrategy,
                chartData.data.price
              ),
              marginInfo.decimals
            )}
            mode={chartMode}
            data={chartData.data.data}
            hasCurrentPosition={chartData.data.hasCurrentPosition}
            showAfterTraded={showAfterTraded}
          />
        ) : (
          <LoadingIndicatorPt />
        )}
      </div>
    </div>
  )
}

export default PnLChartWrapper
