import React, { useEffect } from 'react'
import CenterArea from '../components/main/center/CenterArea'
import MyPosition from '../components/main/positions/MyPosition'
import TradeForm from '../components/main/trade/TradeForm'
import VaultSummary from '../components/positions/VaultSummary'
import useAssetType from '../store/assetType'
import { useVaultStatus, useVaultSummary } from '../hooks/query/useVaultStatus'
import { useTradeRoute } from '../hooks/useTradeRoute'
import NotFound from '../components/NotFound'
import { ASSET_INFOS, MARGIN_INFOS } from '../constants/assets'
import { usePrice } from '../hooks/usePrice'
import { toUnscaled } from '../utils/bn'

const MainView = ({
  chainId,
  pairGroupId,
  pairId
}: {
  chainId: number
  pairGroupId: number
  pairId: number
}) => {
  const { selectedAsset, selectAsset } = useAssetType()
  const vaultSummary = useVaultSummary(chainId, pairGroupId)
  const vaultStatus = useVaultStatus(chainId, pairGroupId)
  const pairInfo = ASSET_INFOS[chainId][pairId]
  const price = usePrice(chainId, pairId)

  useEffect(() => {
    if (price.isSuccess) {
      const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

      const priceString = toUnscaled(
        price.data.price,
        marginInfo.decimals,
        pairInfo.fractionDigits
      )

      const pairName = `${pairInfo.name}/${marginInfo.name} ${
        pairInfo.exTooltip ? '.ex' : ''
      }`

      document.title = `${priceString} | ${pairName} | Main | Predy V5`
    }
  }, [chainId, pairId, pairInfo, pairGroupId, price.isSuccess, price.data])

  useEffect(() => {
    if (
      pairInfo &&
      pairGroupId === pairInfo.pairGroupId &&
      !pairInfo.isIsolated
    ) {
      if (selectedAsset !== pairId) {
        selectAsset(pairId)
      }
    }
  }, [selectAsset, selectedAsset, pairGroupId, pairId, pairInfo])

  if (!pairInfo || pairGroupId !== pairInfo.pairGroupId) {
    return <NotFound />
  }

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-trade grid-rows-trade-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2 mx-1 md:ml-2">
        <TradeForm
          chainId={chainId}
          pairGroupId={pairGroupId}
          vaultId={vaultSummary.data?.mainVaultId || 0}
          assetId={pairId}
        />
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <CenterArea
          chainId={chainId}
          pairGroupId={pairGroupId}
          assetId={pairId}
          vaultId={vaultSummary.data?.mainVaultId || 0}
        />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="md:mx-2">
          <div className="md:mb-4">
            <VaultSummary
              chainId={chainId}
              pairGroupId={pairGroupId}
              vaultSummary={vaultSummary.data}
            />
          </div>
          {vaultStatus.isSuccess ? (
            <MyPosition
              chainId={chainId}
              vault={vaultStatus.data.mainVault}
              assetId={pairId}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

const MainViewWrapper = () => {
  const tradeRoute = useTradeRoute()
  const pairInfo = ASSET_INFOS[tradeRoute.chainId][tradeRoute.pairId]

  if (
    tradeRoute.isNotFound ||
    tradeRoute.pairGroupId === undefined ||
    !pairInfo ||
    tradeRoute.pairGroupId !== pairInfo.pairGroupId
  ) {
    return <NotFound />
  }
  return (
    <MainView
      chainId={tradeRoute.chainId}
      pairGroupId={tradeRoute.pairGroupId}
      pairId={tradeRoute.pairId}
    />
  )
}

export default MainViewWrapper
