import { useQuery } from 'react-query'
import { STALE_TIME, ZERO } from '../../../constants'
import { useAsset, useUtilizationRatio } from '../useAsset'
import { IRMParams, calculateInterestRate } from '../../../utils/irm'
import { toScaled, toUnscaled } from '../../../utils/bn'

type InterestChartData = {
  current: number
  data: {
    ur: number
    interest: number
  }[]
}

export function useInterestChartData(
  chainId: number,
  pairId: number,
  isStable: boolean
) {
  const pairStatus = useAsset(chainId, pairId)
  const utilization = useUtilizationRatio(chainId, pairId)

  return useQuery<InterestChartData>(
    ['interest_chart_data', pairId, isStable],

    async () => {
      if (!pairStatus.isSuccess) throw new Error('pairStatus not set')
      if (!utilization.isSuccess) throw new Error('pairStatus not set')

      let currentBn = ZERO
      let irmParams = null

      if (isStable) {
        currentBn = utilization.data.stable.utilization
        irmParams = pairStatus.data.stablePool.irmParams
      } else {
        currentBn = utilization.data.underlying.utilization
        irmParams = pairStatus.data.underlyingPool.irmParams
      }

      const current = toUnscaled(currentBn, 16, 2)
      const kinkRatio = toUnscaled(irmParams.kinkRate, 16)

      return {
        current,
        data: computeData(current, kinkRatio, irmParams)
      }
    },

    {
      enabled: pairStatus.isSuccess && utilization.isSuccess,
      staleTime: STALE_TIME
    }
  )
}

function computeData(ur: number, kinkRatio: number, params: IRMParams) {
  const xData = Array.from({ length: 51 }, (v, i) => i * 2).concat([
    kinkRatio,
    ur
  ])

  xData.sort((a, b) => a - b)

  const data = xData.map(x => ({
    ur: x,
    interest:
      (toUnscaled(calculateInterestRate(params, toScaled(x, 16)), 16) * x) / 100
  }))

  return data
}
