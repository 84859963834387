import create from 'zustand'

interface State {
  selectedStrategyId: number
  selectStrategyId: (strategyId: number) => void
}

const useStore = create<State>(set => ({
  selectedStrategyId: 1,
  selectStrategyId: (selectedStrategyId: number) => set({ selectedStrategyId })
}))

export default useStore
