import { useQuery } from 'react-query'
import { useWeb3Provider } from '../useWeb3Provider'
import { usePairContext } from '../usePairContext'
import { Position } from '../../utils/helpers/position'
import { usePrice } from '../usePrice'
import { toGreeksAmount } from '../../utils/number'

export function useGreeks(chainId: number, pairId: number, position: Position) {
  const web3Provider = useWeb3Provider(chainId)
  const pairContext = usePairContext(chainId, pairId)
  const price = usePrice(chainId, pairId)

  return useQuery(
    ['greeks', chainId, pairId, position],

    async () => {
      if (!web3Provider.isSuccess) throw new Error('web3provider not set')
      if (!pairContext.isSuccess) throw new Error('pairContext not set')
      if (!price.isSuccess) throw new Error('price not set')

      return {
        delta: pairContext.data.toUnderlyingString(
          position.calculateDelta(price.data.sqrtPrice)
        ),
        gamma: toGreeksAmount(position.calculateGamma(price.data.sqrtPrice))
      }
    },

    {
      enabled:
        web3Provider.isSuccess &&
        web3Provider.data.isConnected &&
        pairContext.isSuccess &&
        price.isSuccess
    }
  )
}
