import React from 'react'
import { VaultSummary } from '../../hooks/query/useVaultStatus'
import { marginAmountToString } from '../../utils/number'
import { MARGIN_INFOS } from '../../constants/assets'

const VaultSummaryPanel = ({
  chainId,
  pairGroupId,
  vaultSummary
}: {
  chainId: number
  pairGroupId: number
  vaultSummary?: VaultSummary
}) => {
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

  return (
    <div className="p-1 md:p-4 w-full md:w-[712px] xl:w-[836px] rounded-2xl bg-secondaly border-[1px] border-white text-xs xl:text-base">
      <div className="flex justify-between justify-items-center divide-x">
        <div className="px-2 flex flex-col space-y-2">
          <div>Vault Summary</div>
          <div>
            <span className="hidden md:inline">Total: </span>
            {marginAmountToString(marginInfo, vaultSummary?.totalValue)}
          </div>
        </div>

        <div className="px-2 flex justify-center items-center space-x-2 md:space-x-4 text-white5">
          <div className="flex flex-col space-y-2">
            <div>Portfolio Vault Value</div>
            <div>
              {marginAmountToString(
                marginInfo,
                vaultSummary?.portfolioVaultValue
              )}
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <div>Min. Value</div>
            <div>
              {marginAmountToString(marginInfo, vaultSummary?.minValue)}
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <div>Margin Available</div>
            <div>
              {marginAmountToString(marginInfo, vaultSummary?.marginAvailable)}
            </div>
          </div>
        </div>

        <div className="px-2 flex flex-col space-y-2 text-white5">
          <div>Isolated Vault Value</div>
          <div>
            {marginAmountToString(marginInfo, vaultSummary?.isolatedVaultValue)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VaultSummaryPanel
