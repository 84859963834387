import { Vault } from '../hooks/query/useVault'

export function checkIsTradeFirstTime(vault: Vault, assetId: number) {
  const openPositions = vault.openPositions.filter(
    openPosition => openPosition.assetId === assetId
  )

  if (openPositions.length === 0) {
    return true
  }

  return (
    openPositions[0].perpPosition.eq(0) &&
    openPositions[0].sqrtPerpPosition.eq(0)
  )
}
