import React, { useEffect } from 'react'
import LendingTradeForm from '../components/lending/LendingTradeForm'
import LendingCenterArea from '../components/lending/LendingCenterArea'
import LendingPosition from '../components/lending/LendingPosition'
import useAssetTypeStore from '../store/assetType'
import { useAddresses } from '../hooks/useAddress'
import { useAsset } from '../hooks/query/useAsset'
import LoadingIndicator from '../components/common/LoadingIndicator'
import assetTypeStore from '../store/assetType'
import { useTradeRoute } from '../hooks/useTradeRoute'
import NotFound from '../components/NotFound'
import { ASSET_INFOS, MARGIN_INFOS } from '../constants/assets'
import { usePrice } from '../hooks/usePrice'
import { toUnscaled } from '../utils/bn'

const LendingView = ({
  chainId,
  pairGroupId,
  pairId
}: {
  chainId: number
  pairGroupId: number
  pairId: number
}) => {
  const { selectedAsset, selectAsset } = useAssetTypeStore()
  const addresses = useAddresses(chainId)
  const asset = useAsset(chainId, selectedAsset)
  const { isStable } = assetTypeStore()
  const price = usePrice(chainId, pairId)
  const pairInfo = ASSET_INFOS[chainId][pairId]

  useEffect(() => {
    if (pairInfo && pairGroupId === pairInfo.pairGroupId) {
      if (pairId !== selectedAsset) {
        selectAsset(pairId)
      }
    }
  }, [selectAsset, selectedAsset, pairGroupId, pairId, pairInfo])

  useEffect(() => {
    if (price.isSuccess) {
      const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

      const priceString = toUnscaled(
        price.data.price,
        marginInfo.decimals,
        pairInfo.fractionDigits
      )

      const pairName = `${pairInfo.name}/${marginInfo.name} ${
        pairInfo.exTooltip ? '.ex' : ''
      }`

      document.title = `${priceString} | ${pairName} | Lending | Predy V5`
    }
  }, [chainId, pairId, pairInfo, pairGroupId, price.isSuccess, price.data])

  if (!pairInfo || pairGroupId !== pairInfo.pairGroupId) {
    return <NotFound />
  }

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-trade grid-rows-trade-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2 mx-1 md:ml-2">
        {addresses && asset.isSuccess ? (
          <LendingTradeForm
            chainId={chainId}
            pairGroupId={pairGroupId}
            assetId={pairId}
            addresses={addresses}
            asset={asset.data}
          />
        ) : (
          <div className="w-12 h-12">
            <LoadingIndicator />
          </div>
        )}
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <LendingCenterArea
          chainId={chainId}
          pairGroupId={pairGroupId}
          assetId={pairId}
        />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="md:mx-4">
          <LendingPosition
            chainId={chainId}
            assetId={pairId}
            addresses={addresses}
            isStable={isStable}
          />
        </div>
      </div>
    </div>
  )
}

const LendingViewWrapper = () => {
  const tradeRoute = useTradeRoute()

  const pairInfo = ASSET_INFOS[tradeRoute.chainId][tradeRoute.pairId]

  if (
    tradeRoute.isNotFound ||
    tradeRoute.pairGroupId === undefined ||
    !pairInfo ||
    tradeRoute.pairGroupId !== pairInfo.pairGroupId
  ) {
    return <NotFound />
  }

  return (
    <LendingView
      chainId={tradeRoute.chainId}
      pairGroupId={tradeRoute.pairGroupId}
      pairId={tradeRoute.pairId}
    />
  )
}

export default LendingViewWrapper
