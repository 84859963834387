import React from 'react'
import { LendingList, StrategyList } from '../components/products/StrategyList'
import { usePortfolioRoute } from '../hooks/useTradeRoute'

const ProductListView = () => {
  const route = usePortfolioRoute()

  return (
    <div className="p-2 flex justify-center items-center">
      <div className="flex flex-col space-y-4">
        <h3 className="my-2">Strategy</h3>
        <StrategyList chainId={route.chainId} />
        <h3 className="my-2">Lending</h3>
        <LendingList chainId={route.chainId} />
      </div>
    </div>
  )
}

export default ProductListView
