import React from 'react'
import StrategyManager from '../components/manage/StrategyManager'
import { useRoute } from 'wouter'
import NotFound from '../components/NotFound'
import { chainNameToId } from '../hooks/useTradeRoute'

const ManageStrategyView = () => {
  const route = useRoute('/:chain/manage/strategy')[1]

  if (route === null) {
    return <NotFound />
  }

  return (
    <>
      <div className="max-w-7xl mx-auto">
        <StrategyManager chainId={chainNameToId(route.chain)} />
      </div>
    </>
  )
}

export default ManageStrategyView
