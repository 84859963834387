import React from 'react'
import InfoTooltip from '../common/InfoTooltip'

const SizeInfo = ({
  symbol,
  sizeToSquart
}: {
  symbol: string
  sizeToSquart: number
}) => {
  return (
    <InfoTooltip placement="top-end">
      <p className="text-center">
        Size represents the number of Squart (√{symbol}) where 1 represents{' '}
        {sizeToSquart}
        Squart (√{symbol}).
        <br />
        <br />
        <span className="text-red">
          Note that Size indicates the number of states after Trade.
        </span>
      </p>
    </InfoTooltip>
  )
}

export default SizeInfo
