import { useQuery } from 'react-query'
import { Q96, STALE_TIME, ZERO } from '../../../constants'
import { createEmptyPosition, useVault } from '../useVault'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { usePrice } from '../../usePrice'
import { BigNumber } from 'ethers'
import { checkIsTradeFirstTime } from '../../../utils/positions'
import { useUnrealizedFee } from '../useVaultStatus'
import { PairScalers } from '../../../utils/helpers/scaler'
import { Position, priceToSqrtPrice } from '../../../utils/helpers/position'

type PnLChartData = {
  hasCurrentPosition: boolean
  price: BigNumber
  data: {
    price: number
    value: number
    delta: number
    gamma: number
    valueAfter: number
    deltaAfter: number
    gammaAfter: number
  }[]
}

const ranges = [
  0, 25, 0.025, 100, 0.0002, 0.01, 25, 25, 0.025, 0.025, 0.5, 0.00001, 0.00001,
  0.0005
]

function generatePrices(pairId: number, price: number) {
  const step = ranges[pairId]
  const basePrice = Math.floor(price / step) * step

  const prices = Array.from(
    { length: 21 },
    (v, i) => (i - 10) * step + basePrice
  )

  return prices
}

export function usePnLChartData(
  chainId: number,
  assetId: number,
  vaultId: number,
  afterPerp: BigNumber,
  after2Squart: BigNumber,
  afterEntryUpdate: BigNumber,
  afterMarginAmount: BigNumber | null
) {
  const vault = useVault(chainId, vaultId)
  const unrealizedFees = useUnrealizedFee(chainId, vaultId)
  const price = usePrice(chainId, assetId)

  return useQuery<PnLChartData>(
    [
      'pnl_chart_data',
      vaultId,
      assetId,
      afterPerp,
      after2Squart,
      afterEntryUpdate,
      afterMarginAmount,
      vault.isSuccess && unrealizedFees.isSuccess
    ],

    async () => {
      if (!price.isSuccess) throw new Error('price not set')

      const scalers = new PairScalers(assetId, chainId)

      let openPosition = createEmptyPosition(assetId)
      let margin = ZERO

      if (vault.isSuccess && unrealizedFees.isSuccess) {
        const openPositions = vault.data.openPositions.filter(
          openPosition => openPosition.assetId === assetId
        )
        const unrealizedFee = unrealizedFees.data.filter(
          unrealizedFee => unrealizedFee.assetId === assetId
        )

        openPosition =
          openPositions.length > 0
            ? openPositions[0]
            : createEmptyPosition(assetId)

        margin = vault.data.margin.add(
          unrealizedFee.length > 0 ? unrealizedFee[0].unrealizedFee : ZERO
        )
      }

      const position = new Position(
        openPosition.stableEntryValue,
        openPosition.sqrtPerpPosition,
        openPosition.perpPosition,
        scalers
      )
      const positionAfter = new Position(
        openPosition.stableEntryValue.add(afterEntryUpdate),
        after2Squart,
        afterPerp,
        scalers
      )

      const xData = generatePrices(
        assetId,
        toUnscaled(price.data.indexPrice, scalers.marginDecimals)
      ).map(price => toScaled(price, scalers.marginDecimals))

      return {
        hasCurrentPosition:
          vault.isSuccess && !checkIsTradeFirstTime(vault.data, assetId),
        price: price.data.price,
        data: xData
          .filter(price => price.gt(0))
          .map(price => {
            const sqrtPrice = priceToSqrtPrice(price.mul(Q96), scalers)

            const value = position.calculateValue(sqrtPrice)
            const delta = position.calculateDelta(sqrtPrice)
            const gamma = position.calculateGamma(sqrtPrice)
            const valueAfter = positionAfter.calculateValue(sqrtPrice)
            const deltaAfter = positionAfter.calculateDelta(sqrtPrice)
            const gammaAfter = positionAfter.calculateGamma(sqrtPrice)

            return {
              price: toUnscaled(price, scalers.marginDecimals),
              value: toUnscaled(value.add(margin), scalers.marginDecimals),
              delta: toUnscaled(delta, scalers.underlyingDecimals),
              gamma,
              valueAfter: toUnscaled(
                valueAfter.add(afterMarginAmount || margin),
                scalers.marginDecimals
              ),
              deltaAfter: toUnscaled(deltaAfter, scalers.underlyingDecimals),
              gammaAfter
            }
          })
      }
    },

    {
      enabled: price.isSuccess,
      staleTime: STALE_TIME
    }
  )
}
