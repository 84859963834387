export type Addresses = {
  Controller: string
  Reader: string
  GammaShortStrategy: string
  StrategyQuoter: string
  assets: {
    UnderlyingToken: string
    UniswapV3Pool: string
    StrategyToken: string
  }[]
  pairGroups: {
    QuoteToken: string
  }[]
  Multicall2: string
  QuoteToken: string
}

export const arbitrum = {
  Controller: '0x06a61E55d4d4659b1A23C0F20AEdfc013C489829',
  Reader: '0xCCE8Aea1761549Aa037fFc86484999c2295DCB64',
  GammaShortStrategy: '0x247d8E00a2714665a5231f4AB165839d943C1838',
  StrategyQuoter: '0xE6fe4e22EF93859A570327D139544cE2D93D954e',
  assets: [
    {
      UnderlyingToken: '0x0000000000000000000000000000000000000000',
      UniswapV3Pool: '0x0000000000000000000000000000000000000000',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    },
    {
      UnderlyingToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      UniswapV3Pool: '0x0000000000000000000000000000000000000000',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    },
    {
      UnderlyingToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      UniswapV3Pool: '0xC31E54c7a869B9FcBEcc14363CF510d1c41fa443',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    }
  ],
  pairGroups: [
    {
      QuoteToken: '0x0000000000000000000000000000000000000000'
    },
    {
      QuoteToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
    },
    {
      QuoteToken: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
    },
    {
      QuoteToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
    }
  ],
  Multicall2: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  QuoteToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
}

export const goerliArbitrum = {
  Controller: '0xb99E91587E42C482AC08d5d4E0600706297C39b1',
  Reader: '0x126eAFC8033FD81A07145f70e41a7c1C4AdBC362',
  GammaShortStrategy: '0xeF5969DA9df608B6B7589Af00F0EF51B8Ee9ACEb',
  StrategyQuoter: '0x2eA651f8926c7C4B5240680Ed2d1330F9e5e93b8',
  assets: [
    {
      UnderlyingToken: '0x0000000000000000000000000000000000000000',
      UniswapV3Pool: '0x0000000000000000000000000000000000000000',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    },
    {
      UnderlyingToken: '0x163691b2153F4e18F3c3F556426b7f5C74a99FA4',
      UniswapV3Pool: '0xe506cCa8C784bF0911D6dF2A3A871B766a6D816E',
      StrategyToken: '0x0111C4d054F1dA30b6D23c6308db1bBCaB7E610D'
    },
    {
      UnderlyingToken: '0x603eFB95394c6cf5b6b29B1c813bd1Ee42A07714',
      UniswapV3Pool: '0x790795655ef5C836B86B30CDbf6279db66660aa8',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    }
  ],
  pairGroups: [],
  Multicall2: '0x108B25170319f38DbED14cA9716C54E5D1FF4623',
  QuoteToken: '0xE060e715B6D20b899A654687c445ed8BC35f9dFF'
}

export const baseMainnet = {
  Controller: '0x71538c9EE5a72E94f82a6EE0b77D49F479422061',
  Reader: '0xBC031b45f5b6e2cE73003aEdB64A4000ad2B4Df7',
  GammaShortStrategy: '0x27605a1b1eC1F6a964A926D43770Cb37E1f295F5',
  StrategyQuoter: '0x58DD7C9486e7DdEF779Ac428cfAB5C6354F6Ba04',
  assets: [
    {
      UnderlyingToken: '0x0000000000000000000000000000000000000000',
      UniswapV3Pool: '0x0000000000000000000000000000000000000000',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    },
    {
      UnderlyingToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      UniswapV3Pool: '0x0000000000000000000000000000000000000000',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    },
    {
      UnderlyingToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      UniswapV3Pool: '0xC31E54c7a869B9FcBEcc14363CF510d1c41fa443',
      StrategyToken: '0x0000000000000000000000000000000000000000'
    }
  ],
  pairGroups: [
    {
      QuoteToken: '0x0000000000000000000000000000000000000000'
    },
    {
      QuoteToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
    },
    {
      QuoteToken: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
    },
    {
      QuoteToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
    }
  ],
  Multicall2: '0xcD03fFA0550815cCdAf08b793016e2E1Ee0e316d',
  QuoteToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
}

export const NETWORKS: { [key: number]: Addresses } = {
  [421613]: goerliArbitrum,
  [42161]: arbitrum,
  [8453]: baseMainnet
}

export const UNI_ETH_USDC_POOL_ADDRESS = [
  '0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8',
  '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640'
]
