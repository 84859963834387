/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type { IController, IControllerInterface } from '../IController'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256'
      }
    ],
    name: 'getAsset',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'pairGroupId',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address'
              },
              {
                internalType: 'address',
                name: 'supplyTokenAddress',
                type: 'address'
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'totalCompoundDeposited',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'totalNormalDeposited',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'totalNormalBorrowed',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'assetScaler',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'assetGrowth',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'debtGrowth',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct ScaledAsset.TokenStatus',
                name: 'tokenStatus',
                type: 'tuple'
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'baseRate',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'kinkRate',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'slope1',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'slope2',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct InterestRateModel.IRMParams',
                name: 'irmParams',
                type: 'tuple'
              }
            ],
            internalType: 'struct DataType.AssetPoolStatus',
            name: 'stablePool',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address'
              },
              {
                internalType: 'address',
                name: 'supplyTokenAddress',
                type: 'address'
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'totalCompoundDeposited',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'totalNormalDeposited',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'totalNormalBorrowed',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'assetScaler',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'assetGrowth',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'debtGrowth',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct ScaledAsset.TokenStatus',
                name: 'tokenStatus',
                type: 'tuple'
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'baseRate',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'kinkRate',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'slope1',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'slope2',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct InterestRateModel.IRMParams',
                name: 'irmParams',
                type: 'tuple'
              }
            ],
            internalType: 'struct DataType.AssetPoolStatus',
            name: 'underlyingPool',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'riskRatio',
                type: 'uint256'
              },
              {
                internalType: 'int24',
                name: 'rangeSize',
                type: 'int24'
              },
              {
                internalType: 'int24',
                name: 'rebalanceThreshold',
                type: 'int24'
              }
            ],
            internalType: 'struct DataType.AssetRiskParams',
            name: 'riskParams',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'uniswapPool',
                type: 'address'
              },
              {
                internalType: 'int24',
                name: 'tickLower',
                type: 'int24'
              },
              {
                internalType: 'int24',
                name: 'tickUpper',
                type: 'int24'
              },
              {
                internalType: 'uint64',
                name: 'numRebalance',
                type: 'uint64'
              },
              {
                internalType: 'uint256',
                name: 'totalAmount',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'borrowedAmount',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'lastRebalanceTotalSquartAmount',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'lastFee0Growth',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'lastFee1Growth',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'borrowPremium0Growth',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'borrowPremium1Growth',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'fee0Growth',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'fee1Growth',
                type: 'uint256'
              },
              {
                components: [
                  {
                    internalType: 'int256',
                    name: 'positionAmount',
                    type: 'int256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'lastFeeGrowth',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct ScaledAsset.UserStatus',
                name: 'rebalancePositionUnderlying',
                type: 'tuple'
              },
              {
                components: [
                  {
                    internalType: 'int256',
                    name: 'positionAmount',
                    type: 'int256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'lastFeeGrowth',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct ScaledAsset.UserStatus',
                name: 'rebalancePositionStable',
                type: 'tuple'
              },
              {
                internalType: 'int256',
                name: 'rebalanceFeeGrowthUnderlying',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'rebalanceFeeGrowthStable',
                type: 'int256'
              }
            ],
            internalType: 'struct Perp.SqrtPerpAssetStatus',
            name: 'sqrtAssetStatus',
            type: 'tuple'
          },
          {
            internalType: 'bool',
            name: 'isMarginZero',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isIsolatedMode',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'lastUpdateTimestamp',
            type: 'uint256'
          }
        ],
        internalType: 'struct DataType.PairStatus',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256'
      }
    ],
    name: 'getPairGroup',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256'
          },
          {
            internalType: 'address',
            name: 'stableTokenAddress',
            type: 'address'
          },
          {
            internalType: 'uint8',
            name: 'marginRoundedDecimal',
            type: 'uint8'
          }
        ],
        internalType: 'struct DataType.PairGroup',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_pairId',
        type: 'uint256'
      }
    ],
    name: 'getSqrtPrice',
    outputs: [
      {
        internalType: 'uint160',
        name: '',
        type: 'uint160'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256'
      }
    ],
    name: 'getVault',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'pairGroupId',
            type: 'uint256'
          },
          {
            internalType: 'address',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'int256',
            name: 'margin',
            type: 'int256'
          },
          {
            internalType: 'bool',
            name: 'autoTransferDisabled',
            type: 'bool'
          },
          {
            components: [
              {
                internalType: 'uint64',
                name: 'pairId',
                type: 'uint64'
              },
              {
                internalType: 'int24',
                name: 'rebalanceLastTickLower',
                type: 'int24'
              },
              {
                internalType: 'int24',
                name: 'rebalanceLastTickUpper',
                type: 'int24'
              },
              {
                internalType: 'uint64',
                name: 'lastNumRebalance',
                type: 'uint64'
              },
              {
                components: [
                  {
                    internalType: 'int256',
                    name: 'amount',
                    type: 'int256'
                  },
                  {
                    internalType: 'int256',
                    name: 'entryValue',
                    type: 'int256'
                  }
                ],
                internalType: 'struct Perp.PositionStatus',
                name: 'perp',
                type: 'tuple'
              },
              {
                components: [
                  {
                    internalType: 'int256',
                    name: 'amount',
                    type: 'int256'
                  },
                  {
                    internalType: 'int256',
                    name: 'entryValue',
                    type: 'int256'
                  },
                  {
                    internalType: 'int256',
                    name: 'stableRebalanceEntryValue',
                    type: 'int256'
                  },
                  {
                    internalType: 'int256',
                    name: 'underlyingRebalanceEntryValue',
                    type: 'int256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'entryTradeFee0',
                    type: 'uint256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'entryTradeFee1',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct Perp.SqrtPositionStatus',
                name: 'sqrtPerp',
                type: 'tuple'
              },
              {
                components: [
                  {
                    internalType: 'int256',
                    name: 'positionAmount',
                    type: 'int256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'lastFeeGrowth',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct ScaledAsset.UserStatus',
                name: 'underlying',
                type: 'tuple'
              },
              {
                components: [
                  {
                    internalType: 'int256',
                    name: 'positionAmount',
                    type: 'int256'
                  },
                  {
                    internalType: 'uint256',
                    name: 'lastFeeGrowth',
                    type: 'uint256'
                  }
                ],
                internalType: 'struct ScaledAsset.UserStatus',
                name: 'stable',
                type: 'tuple'
              }
            ],
            internalType: 'struct Perp.UserStatus[]',
            name: 'openPositions',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct DataType.Vault',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256'
      }
    ],
    name: 'getVaultStatus',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'vaultId',
            type: 'uint256'
          },
          {
            internalType: 'int256',
            name: 'vaultValue',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'margin',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'positionValue',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'minDeposit',
            type: 'int256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'pairId',
                type: 'uint256'
              },
              {
                components: [
                  {
                    internalType: 'uint64',
                    name: 'pairId',
                    type: 'uint64'
                  },
                  {
                    internalType: 'int24',
                    name: 'rebalanceLastTickLower',
                    type: 'int24'
                  },
                  {
                    internalType: 'int24',
                    name: 'rebalanceLastTickUpper',
                    type: 'int24'
                  },
                  {
                    internalType: 'uint64',
                    name: 'lastNumRebalance',
                    type: 'uint64'
                  },
                  {
                    components: [
                      {
                        internalType: 'int256',
                        name: 'amount',
                        type: 'int256'
                      },
                      {
                        internalType: 'int256',
                        name: 'entryValue',
                        type: 'int256'
                      }
                    ],
                    internalType: 'struct Perp.PositionStatus',
                    name: 'perp',
                    type: 'tuple'
                  },
                  {
                    components: [
                      {
                        internalType: 'int256',
                        name: 'amount',
                        type: 'int256'
                      },
                      {
                        internalType: 'int256',
                        name: 'entryValue',
                        type: 'int256'
                      },
                      {
                        internalType: 'int256',
                        name: 'stableRebalanceEntryValue',
                        type: 'int256'
                      },
                      {
                        internalType: 'int256',
                        name: 'underlyingRebalanceEntryValue',
                        type: 'int256'
                      },
                      {
                        internalType: 'uint256',
                        name: 'entryTradeFee0',
                        type: 'uint256'
                      },
                      {
                        internalType: 'uint256',
                        name: 'entryTradeFee1',
                        type: 'uint256'
                      }
                    ],
                    internalType: 'struct Perp.SqrtPositionStatus',
                    name: 'sqrtPerp',
                    type: 'tuple'
                  },
                  {
                    components: [
                      {
                        internalType: 'int256',
                        name: 'positionAmount',
                        type: 'int256'
                      },
                      {
                        internalType: 'uint256',
                        name: 'lastFeeGrowth',
                        type: 'uint256'
                      }
                    ],
                    internalType: 'struct ScaledAsset.UserStatus',
                    name: 'underlying',
                    type: 'tuple'
                  },
                  {
                    components: [
                      {
                        internalType: 'int256',
                        name: 'positionAmount',
                        type: 'int256'
                      },
                      {
                        internalType: 'uint256',
                        name: 'lastFeeGrowth',
                        type: 'uint256'
                      }
                    ],
                    internalType: 'struct ScaledAsset.UserStatus',
                    name: 'stable',
                    type: 'tuple'
                  }
                ],
                internalType: 'struct Perp.UserStatus',
                name: 'position',
                type: 'tuple'
              },
              {
                internalType: 'int256',
                name: 'delta',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'unrealizedFee',
                type: 'int256'
              }
            ],
            internalType: 'struct DataType.SubVaultStatusResult[]',
            name: 'subVaults',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct DataType.VaultStatusResult',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_isolatedVaultId',
        type: 'uint256'
      },
      {
        internalType: 'bool',
        name: '_autoTransferDisabled',
        type: 'bool'
      }
    ],
    name: 'setAutoTransfer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        internalType: 'uint64',
        name: '_pairId',
        type: 'uint64'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'tradeAmount',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'tradeAmountSqrt',
            type: 'int256'
          },
          {
            internalType: 'uint256',
            name: 'lowerSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'upperSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'enableCallback',
            type: 'bool'
          },
          {
            internalType: 'bytes',
            name: 'data',
            type: 'bytes'
          }
        ],
        internalType: 'struct TradePerpLogic.TradeParams',
        name: '_tradeParams',
        type: 'tuple'
      }
    ],
    name: 'tradePerp',
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: 'int256',
                name: 'perpEntryUpdate',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'sqrtEntryUpdate',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'sqrtRebalanceEntryUpdateUnderlying',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'sqrtRebalanceEntryUpdateStable',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'perpPayoff',
                type: 'int256'
              },
              {
                internalType: 'int256',
                name: 'sqrtPayoff',
                type: 'int256'
              }
            ],
            internalType: 'struct Perp.Payoff',
            name: 'payoff',
            type: 'tuple'
          },
          {
            internalType: 'int256',
            name: 'fee',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'minDeposit',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TradeResult',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: '_pairGroupId',
        type: 'uint64'
      },
      {
        internalType: 'int256',
        name: '_marginAmount',
        type: 'int256'
      }
    ],
    name: 'updateMargin',
    outputs: [
      {
        internalType: 'uint256',
        name: 'vaultId',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: '_pairGroupId',
        type: 'uint64'
      },
      {
        internalType: 'uint256',
        name: '_isolatedVaultId',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_marginAmount',
        type: 'int256'
      },
      {
        internalType: 'bool',
        name: '_moveFromMainVault',
        type: 'bool'
      }
    ],
    name: 'updateMarginOfIsolated',
    outputs: [
      {
        internalType: 'uint256',
        name: 'isolatedVaultId',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export class IController__factory {
  static readonly abi = _abi
  static createInterface(): IControllerInterface {
    return new utils.Interface(_abi) as IControllerInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IController {
    return new Contract(address, _abi, signerOrProvider) as IController
  }
}
