/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type { VaultLib, VaultLibInterface } from '../VaultLib'

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'vaultId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'owner',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isMainVault',
        type: 'bool'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pairGroupId',
        type: 'uint256'
      }
    ],
    name: 'VaultCreated',
    type: 'event'
  }
]

const _bytecode =
  '0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122065e56657f961ab6b6d8e0467d217af3230c3df24329fa0a10397cb4b85472d6764736f6c63430008130033'

export class VaultLib__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<VaultLib> {
    return super.deploy(overrides || {}) as Promise<VaultLib>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): VaultLib {
    return super.attach(address) as VaultLib
  }
  connect(signer: Signer): VaultLib__factory {
    return super.connect(signer) as VaultLib__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): VaultLibInterface {
    return new utils.Interface(_abi) as VaultLibInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VaultLib {
    return new Contract(address, _abi, signerOrProvider) as VaultLib
  }
}
