import {
  DEFAULT_CHAIN,
  SLIPPAGE_TOLERANCE_NETWORK_MAP
} from '../../../constants'

export function useSlippageTolerance(chainId: number) {
  const slippageInfo = SLIPPAGE_TOLERANCE_NETWORK_MAP[chainId || DEFAULT_CHAIN]

  if (!slippageInfo) {
    return 0
  }

  return slippageInfo.value
}
