import { BigNumber } from 'ethers'
import create from 'zustand'
import { ZERO } from '../constants'

interface State {
  after2Squart: BigNumber
  afterPerp: BigNumber
  afterEntryUpdate: BigNumber
  afterMargin: BigNumber | null
  setAfterAmounts: (
    after2Squart: BigNumber,
    afterPerp: BigNumber,
    afterMargin: BigNumber | null
  ) => void
  setAfter2Squart: (after2Squart: BigNumber) => void
  setAfterPerp: (afterPerp: BigNumber) => void
  setAfterEntryUpdate: (afterEntryUpdate: BigNumber) => void
  setAdditionalMargin: (afterMargin: BigNumber) => void
  clearPosition: () => void
}

const useStore = create<State>(set => ({
  after2Squart: ZERO,
  afterPerp: ZERO,
  afterEntryUpdate: ZERO,
  afterMargin: ZERO,
  setAfterAmounts: (
    after2Squart: BigNumber,
    afterPerp: BigNumber,
    afterMargin: BigNumber | null
  ) =>
    set({
      after2Squart,
      afterPerp,
      afterMargin
    }),
  setAfter2Squart: (after2Squart: BigNumber) => set({ after2Squart }),
  setAfterPerp: (afterPerp: BigNumber) => set({ afterPerp }),
  setAfterEntryUpdate: (afterEntryUpdate: BigNumber) =>
    set({ afterEntryUpdate }),
  setAdditionalMargin: (afterMargin: BigNumber) => set({ afterMargin }),
  clearPosition: () =>
    set({
      afterPerp: ZERO,
      after2Squart: ZERO,
      afterMargin: ZERO,
      afterEntryUpdate: ZERO
    })
}))

export default useStore
