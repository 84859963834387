import React from 'react'
import AssetSummary from '../components/manage/AssetSummary'
import { DEFAULT_CHAIN } from '../constants'

const AssetView = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto">
        <AssetSummary chainId={DEFAULT_CHAIN} />
      </div>
    </>
  )
}

export default AssetView
