import { useQuery } from 'react-query'
import { STALE_TIME } from '../../constants'
import { useRebalanceHistory } from './useRebalanceHistory'

export function useRebalanceCounts(chainId: number) {
  const rebalanceHistory = useRebalanceHistory(chainId)

  return useQuery(
    ['rebalance_count', chainId],

    async () => {
      if (!rebalanceHistory) throw new Error('rebalance history not set')

      const rebalanceCount = rebalanceHistory.reduce((acc, item) => {
        const count = acc.get(item.pairId)
        return acc.set(item.pairId, (count || 0) + 1)
      }, new Map<number, number>())

      return rebalanceCount
    },

    {
      enabled: !!rebalanceHistory,
      staleTime: STALE_TIME
    }
  )
}
