import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import {
  LENDING_HISTORY_ITEM_QUERY,
  LendingUserHistoryItems
} from '../../../queries/lendingHistoryItemQuery'
import { toUnscaled } from '../../../utils/bn'
import { ASSET_INFOS, MARGIN_INFOS } from '../../../constants/assets'
import { PredyClient } from '../../../utils/apollo-client'

export type LendingHistoryItem = {
  id: string
  address: string
  assetId: number
  isStable: boolean
  account: string
  action: string
  assetAmount: number
  timestamp: number
}

export const NUM_ONE_PAGE_ITEMS = 500

export function useLendingHistory(
  chainId: number,
  account: string,
  contractAddress: string,
  assetId: number,
  isStable: boolean
) {
  const [skip, setSkip] = useState(0)
  const [lendingHistory, setLendingHistory] = useState<LendingHistoryItem[]>([])

  const { data } = useQuery<LendingUserHistoryItems>(
    LENDING_HISTORY_ITEM_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        owner: account.toLowerCase(),
        address: contractAddress.toLocaleLowerCase(),
        assetId: assetId,
        isStable,
        skip: skip,
        first: NUM_ONE_PAGE_ITEMS
      },
      pollInterval: 30000,
      client: PredyClient[chainId]
    }
  )

  useEffect(() => {
    if (data) {
      const newItems = data.lendingUserHistoryItems.map(entity => {
        const assetAmount = BigNumber.from(entity.assetAmount)

        const decimals = isStable
          ? MARGIN_INFOS[chainId][ASSET_INFOS[chainId][assetId].pairGroupId]
              .decimals
          : ASSET_INFOS[chainId][assetId].decimals

        return {
          id: entity.id,
          address: entity.address.toLowerCase(),
          assetId: Number(entity.pairId),
          isStable: entity.isStable,
          account: entity.account,
          action: entity.action === 'DEPOSIT' ? 'Supply' : 'Withdraw',
          assetAmount: toUnscaled(assetAmount, decimals),
          timestamp: Number(entity.createdAt)
        }
      })

      setLendingHistory(history => {
        return history
          .concat(newItems)
          .map(item => Object.assign({}, item))
          .sort((a, b) => b.timestamp - a.timestamp)
          .filter(
            val =>
              val.address === contractAddress.toLowerCase() &&
              val.account === account.toLowerCase() &&
              val.assetId === assetId &&
              val.isStable === isStable
          )
          .filter(
            (val, ind, self) =>
              ind === self.findIndex(item => item.id === val.id)
          )
      })
    }
  }, [chainId, account, data, contractAddress, assetId, isStable])

  return {
    lendingHistory,
    skip,
    setSkip
  }
}
