import React from 'react'
import usdcIcon from '../../assets/assets/usdc.svg'
import assetTypeStore from '../../store/assetType'
import { BasePopup } from '../common/BasePopup'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'
import { AiFillCaretDown } from 'react-icons/ai'
import { useAssetInterest } from '../../hooks/query/interest/useAssetInterest'
import { toUnscaled } from '../../utils/bn'
import { ZERO } from '../../constants'
import { useTradeRoute } from '../../hooks/useTradeRoute'
import InfoTooltip from '../common/InfoTooltip'

const LendingSelectorItem = ({
  chainId,
  pairId,
  onSelected
}: {
  chainId: number
  pairId: number
  onSelected: (pairId: number) => void
}) => {
  const stableInterest = useAssetInterest(chainId, pairId, true)
  const underlyingInterest = useAssetInterest(chainId, pairId, false)

  const pairInfo = ASSET_INFOS[chainId][pairId]
  const pairGroup = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  return (
    <div
      className="px-3 py-1 flex justify-between justify-items-end cursor-pointer hover:bg-white5"
      onClick={() => {
        onSelected(pairInfo.id)
      }}
    >
      <div className="w-36 flex justify-between items-center">
        {pairInfo.name}/{pairGroup.name}
        {pairInfo.exTooltip ? '.ex' : ''}
        {pairInfo.exTooltip ? (
          <InfoTooltip placement="bottom">
            <p className="text-center">
              This Lending Pool, with a higher Borrow Rate set, provides funding
              for Perpetual contract trades (ending with .ex). It is an
              experimental attempt to measure the supply elasticity of lending
              in relation to the Borrow Rate.
            </p>
          </InfoTooltip>
        ) : (
          <></>
        )}
      </div>

      <div className="w-28 text-right">
        {toUnscaled(underlyingInterest.data?.supplyInterest || ZERO, 16, 2)}%
      </div>
      <div className="w-28 text-right">
        {toUnscaled(stableInterest.data?.supplyInterest || ZERO, 16, 2)}%
      </div>
    </div>
  )
}

export const LendingSelector = ({ pairGroupId }: { pairGroupId: number }) => {
  const tradeRoute = useTradeRoute()
  const { selectedAsset, selectAsset } = assetTypeStore()

  const pairGroup = MARGIN_INFOS[tradeRoute.chainId][pairGroupId]

  const pairInfos = pairGroup.pairs.map(
    pairId => ASSET_INFOS[tradeRoute.chainId][pairId]
  )
  const assetInfo = ASSET_INFOS[tradeRoute.chainId][selectedAsset]

  return (
    <BasePopup
      isWide
      titleContent={
        <div className="flex justify-center items-center space-x-1 cursor-pointer">
          <div className="flex justify-center items-center space-x-0">
            <img src={assetInfo.icon} width={24} className="rounded-full" />
            <img src={usdcIcon} width={24} />
          </div>
          <span className="text-base">
            {assetInfo.name}/{pairGroup.name}
          </span>
          <div className="rounded-lg bg-white3 text-sm p-1">
            {assetInfo.fee}%
          </div>
          <AiFillCaretDown />
        </div>
      }
    >
      <div className="w-[332px]">
        <div className="px-3 pt-2 flex justify-between justify-items-end">
          <div className="w-36">Pair</div>
          <div className="w-28 text-right">Base APY</div>
          <div className="w-28 text-right">Quote APY</div>
        </div>
        <div className="py-4 flex flex-col space-y-1">
          {pairInfos.map(pairInfo => {
            return (
              <LendingSelectorItem
                chainId={tradeRoute.chainId}
                key={pairInfo.id}
                pairId={pairInfo.id}
                onSelected={pairId => {
                  selectAsset(pairId)
                  tradeRoute.setPairId(pairId)
                }}
              />
            )
          })}
        </div>
      </div>
    </BasePopup>
  )
}
