import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { metaMask } from '../../connectors'
import { AiOutlineUp, AiOutlineDown } from 'react-icons/ai'
import { DropdownMenu, DropdownMenuItem } from './DropdownMenu'
import { DepositModal } from './DepositModal'
import { WithdrawModal } from './WithdrawModal'
import Modal from 'react-modal'
import { useTradeRoute } from '../../hooks/useTradeRoute'

Modal.setAppElement('#root')

export const HeaderDepositButton = () => {
  const tradeRoute = useTradeRoute()
  const pairGroupId = tradeRoute.pairGroupId

  const { isActive } = useWeb3React()
  const [walletMenuOpened, setWalletMenuOpened] = useState(false)
  const [isMouseOnMenu, setIsMouseOnMenu] = useState(false)
  const [dropdownMenuSelected, setDropdownMenuSelected] = useState(
    DropdownMenuItem.NO_SELECTED
  )

  const [isOpen, setIsOpen] = useState(false)

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="w-20 md:w-32 h-8 rounded-md border-[1px] border-white text-sm">
      <BaseDepositButton
        isOpenning={walletMenuOpened || isMouseOnMenu}
        onClick={() => {
          setWalletMenuOpened(!walletMenuOpened)
        }}
        onMouseEnter={() => {
          setWalletMenuOpened(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setWalletMenuOpened(false)
          }, 200)
        }}
      />

      {isActive && pairGroupId ? (
        <>
          <DropdownMenu
            isShow={walletMenuOpened || isMouseOnMenu}
            onMouseEnter={() => {
              setIsMouseOnMenu(true)
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                setIsMouseOnMenu(false)
              }, 200)
            }}
            onSelected={async (item: number) => {
              if (item === DropdownMenuItem.DISCONNECT) {
                if (metaMask.deactivate) {
                  await metaMask.deactivate()
                }
                await metaMask.resetState()
                localStorage.removeItem('predy.connected')
                setWalletMenuOpened(false)
              } else {
                setDropdownMenuSelected(item)
                setIsOpen(true)
              }
            }}
          />
          <Modal
            isOpen={isOpen}
            onRequestClose={onCloseModal}
            shouldCloseOnOverlayClick={true}
            style={{
              overlay: {
                backgroundColor: '#ffffff70'
              },
              content: {
                borderWidth: 0,
                backgroundColor: '#1f2126',
                maxWidth: 380,
                height: 452,
                margin: 'auto',
                padding: 24
              }
            }}
          >
            {dropdownMenuSelected === DropdownMenuItem.DEPOSIT ? (
              <DepositModal
                chainId={tradeRoute.chainId}
                pairGroupId={pairGroupId}
                onClose={onCloseModal}
              />
            ) : dropdownMenuSelected === DropdownMenuItem.WITHDRAW ? (
              <WithdrawModal
                chainId={tradeRoute.chainId}
                pairGroupId={pairGroupId}
                onClose={onCloseModal}
              />
            ) : (
              <div />
            )}
          </Modal>
        </>
      ) : (
        <div />
      )}
    </div>
  )
}

const BaseDepositButton = ({
  isOpenning,
  onClick,
  onMouseEnter,
  onMouseLeave
}: {
  isOpenning: boolean
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) => {
  return (
    <>
      <div
        className="w-full h-full px-4 background-black text-sm text-white rounded-md flex flex-row items-center cursor-pointer"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="basis-5/6 my-auto">Deposit</div>
        {isOpenning ? (
          <AiOutlineUp className="basis-1/6 my-auto pointer-events-none" />
        ) : (
          <AiOutlineDown className="basis-1/6 my-auto pointer-events-none" />
        )}
      </div>
    </>
  )
}
