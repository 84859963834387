import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ReferenceLine
} from 'recharts'
import { toGreeksAmount } from '../../utils/number'
import { useInterestChartData } from '../../hooks/query/chart/useInterestChartData'

function createTrianglePath(x: number, y: number) {
  return `${x},${y} ${x - 5},${y + 8} ${x + 5},${y + 8}`
}

const InterestRateModelChart = ({
  chainId,
  pairId,
  isStable
}: {
  chainId: number
  pairId: number
  isStable: boolean
}) => {
  const chartData = useInterestChartData(chainId, pairId, isStable)

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dataDefs = (payload as { dataKey: string; value: number }[]).map(
        p => ({
          name: p.dataKey,
          value: p.value
        })
      )

      // sort by value
      dataDefs.sort((a: any, b: any) => b.value - a.value)

      return (
        <div className="text-xs rounded-lg p-1">
          {dataDefs.map((dataDef, i) => {
            return (
              <div key={i} className="text-white text-xs p-1">
                <p>Interest Rate: {toGreeksAmount(dataDef.value)}%</p>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const CustomizedLabel = (params: any) => {
    const x = params.viewBox.x
    const y = params.viewBox.y + 220

    const points = createTrianglePath(x, y)

    return (
      <g>
        <polygon points={points} fill={params.content.props.fill} />
      </g>
    )
  }

  const CustomizedXAxisTick = (props: any) => {
    const x = props.x || 0
    const y = props.y || 0
    const payload = props.payload

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          x={0}
          y={0}
          dy={6}
          textAnchor="middle"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {payload ? payload.value.toLocaleString() : ''}%
        </text>
      </g>
    )
  }

  const CustomizedYAxisTick = (props: any) => {
    const x = props.x || 0
    const y = props.y || 0
    const payload = props.payload

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={3}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {payload ? payload.value : ''}%
        </text>
      </g>
    )
  }

  const CustomizedCursor = (props: any) => {
    const { points, payload } = props

    const triangle = createTrianglePath(points[1].x, points[1].y)

    return (
      <g>
        <path
          d={`M ${points[0].x} ${points[0].y} L ${points[1].x} ${points[1].y}`}
          stroke="#ccc"
        ></path>
        <polygon points={triangle} fill="#ccc" />
        <text
          x={points[1].x + 10}
          y={points[1].y + 17}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={10}
        >
          {payload && payload.length > 0 ? payload[0].payload.ur : 0}%
        </text>
      </g>
    )
  }

  if (!chartData.isSuccess) {
    return <div></div>
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={276}>
        <LineChart
          data={chartData.data.data}
          margin={{
            top: 24,
            left: 20,
            bottom: 20,
            right: 20
          }}
        >
          <CartesianGrid stroke="#4c525f" />
          <XAxis
            type="number"
            dataKey="ur"
            tickCount={19}
            interval={3}
            tickLine={false}
            domain={['dataMin', 'dataMax']}
            tick={<CustomizedXAxisTick />}
            label={{
              value: 'UR.',
              angle: 0,
              position: 'insideBottomRight',
              offset: -12
            }}
          />
          <YAxis
            type="number"
            tickCount={5}
            tick={<CustomizedYAxisTick />}
            tickLine={false}
            label={{
              value: 'Interest Rate',
              angle: 0,
              position: 'insideTopLeft',
              offset: -22
            }}
          />
          <Tooltip cursor={<CustomizedCursor />} content={<CustomTooltip />} />
          <ReferenceLine
            x={chartData.data.current}
            stroke="#04ECA6"
            strokeDasharray="5 5"
            opacity={0.5}
            label={
              <CustomizedLabel price={chartData.data.current} fill="#04ECA6">
                Current
              </CustomizedLabel>
            }
          />
          <Line
            type="monotone"
            name="Current Position"
            dataKey="interest"
            stroke="#55F28A"
            strokeWidth={1}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default InterestRateModelChart
