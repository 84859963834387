import React from 'react'
import { Link } from 'wouter'
import { AiOutlineExport } from 'react-icons/ai'
import { usePairContext } from '../../hooks/usePairContext'
import { PairBadge } from '../common/PairBadge'
import { useLendingPosition } from '../../hooks/query/lending/useLendingSummary'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'

export const LendingPortfolio = ({
  chainId,
  pairId,
  isStable
}: {
  chainId: number
  pairId: number
  isStable: boolean
}) => {
  const position = useLendingPosition(chainId, pairId, isStable)
  const pairContext = usePairContext(chainId, pairId)
  const pairInfo = ASSET_INFOS[chainId][pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]

  const amount = position.isSuccess ? position.data : undefined

  if (amount && amount.gt(0)) {
    return (
      <div className="flex justify-between justify-items-start items-center text-left">
        <div className="w-[240px] flex justify-start">
          <PairBadge chainId={chainId} assetInfo={pairInfo} />
        </div>
        <div className="w-40">{isStable ? marginInfo.name : pairInfo.name}</div>
        <div className="w-40">
          {pairContext.isSuccess
            ? isStable
              ? pairContext.data.toMarginString(amount, {
                  showFull: true,
                  symbolRequired: false
                })
              : pairContext.data.toUnderlyingString(amount, {
                  showFull: true,
                  symbolRequired: false
                })
            : '-'}
        </div>
        <div className="w-40">
          <Link href={`/trade/${pairInfo.pairGroupId}/lending/${pairId}`}>
            <AiOutlineExport className="cursor-pointer" />
          </Link>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
