import React, { useEffect } from 'react'
import assetTypeStore from '../../../store/assetType'
import positionStore from '../../../store/position'
import { BasePopup } from '../../common/BasePopup'
import { ASSET_INFOS, MARGIN_INFOS } from '../../../constants/assets'
import { AiFillCaretDown } from 'react-icons/ai'
import { useTradeRoute } from '../../../hooks/useTradeRoute'
import { PairBadge } from '../../common/PairBadge'
import InfoTooltip from '../../common/InfoTooltip'

export const AssetSelector = ({
  chainId,
  pairGroupId,
  pairId,
  isIsolated
}: {
  chainId: number
  pairGroupId: number
  pairId: number
  isIsolated: boolean
}) => {
  const tradeRoute = useTradeRoute()
  const { selectAsset } = assetTypeStore()
  const { clearPosition } = positionStore()

  const pairGroup = MARGIN_INFOS[chainId][pairGroupId]

  const pairInfos = pairGroup.pairs
    .map(pairId => ASSET_INFOS[chainId][pairId])
    .filter(pairInfo => isIsolated || !pairInfo.isIsolated)

  const assetInfo = ASSET_INFOS[chainId][pairId]

  useEffect(() => {
    if (
      tradeRoute.pairGroupId ===
      ASSET_INFOS[chainId][tradeRoute.pairId].pairGroupId
    ) {
      selectAsset(tradeRoute.pairId)
    }
  }, [chainId, selectAsset, tradeRoute.pairGroupId, tradeRoute.pairId])

  return (
    <BasePopup
      titleContent={
        <PairBadge
          chainId={chainId}
          assetInfo={assetInfo}
          className="cursor-pointer"
        >
          <AiFillCaretDown />
        </PairBadge>
      }
    >
      <div className="w-60 py-4 flex flex-col space-y-1">
        {pairInfos.map(pairInfo => {
          return (
            <div
              key={pairInfo.id}
              className="px-4 py-1 flex justify-between cursor-pointer hover:bg-white5"
              onClick={() => {
                clearPosition()
                selectAsset(pairInfo.id)
                tradeRoute.setPairId(pairInfo.id)
              }}
            >
              <div className="flex justify-between">
                <div>
                  {pairInfo.name}/{pairGroup.name}
                  {pairInfo.exTooltip ? '.ex' : ''}
                </div>
                {pairInfo.exTooltip ? (
                  <InfoTooltip placement="bottom">
                    <p className="text-center">
                      This Perpetual contract is linked with a Lending Pool that
                      has a higher Borrow Rate set. It is an experimental
                      attempt to measure the supply elasticity of lending in
                      relation to the Borrow Rate.
                    </p>
                  </InfoTooltip>
                ) : (
                  <></>
                )}
              </div>
              <div>{pairInfo.fee}%</div>
            </div>
          )
        })}
      </div>
    </BasePopup>
  )
}
