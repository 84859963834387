import React, { ReactNode } from 'react'
import uniIcon from '../../../assets/uniswap.svg'
import { useUniswapPool } from '../../../hooks/query/uniswap/pool'
import { convertNotionalToString } from '../../../utils'
import { usePrice } from '../../../hooks/usePrice'
import { toPriceString } from '../../../utils/number'
import { useFee24h } from '../../../hooks/query/interest/useFee24h'
import { useIV } from '../../../hooks/query/interest/useIV'
import InfoTooltip from '../../common/InfoTooltip'
import { toUnscaled } from '../../../utils/bn'
import { PairScalers } from '../../../utils/helpers/scaler'
import { ASSET_INFOS } from '../../../constants/assets'

const RateOfChange = ({ roc = 0 }: { roc?: number }) => {
  return (
    <>
      {roc > 0 ? (
        <span className="text-green">+{roc.toFixed(2)}%</span>
      ) : (
        <span className="text-red">{roc.toFixed(2)}%</span>
      )}
    </>
  )
}

const ChartTop = ({
  chainId,
  assetId,
  children
}: {
  chainId: number
  assetId: number
  children: ReactNode
}) => {
  const pool = useUniswapPool(chainId, assetId)
  const price = usePrice(chainId, assetId)
  const feesUSD = useFee24h(chainId, assetId)
  const iv = useIV(chainId, assetId)
  const scalers = new PairScalers(assetId, chainId)
  const assetInfo = ASSET_INFOS[chainId][assetId]
  const assetSymbol = assetInfo.name

  return (
    <div className="flex justify-between w-[768px]">
      <div>
        {children}
        <div className="flex justify-end items-center space-x-1 text-sm">
          <img src={uniIcon} width={24} />
          <span className="text-white5 flex justify-start">
            Uniswap Pool Info
            <InfoTooltip placement="bottom">
              <p className="text-center">
                Predy protocol utilizes Uniswap&apos;s LP position to compose √
                {assetSymbol}. Gamma Trading&apos;s Premium is based on
                Uniswap&apos;s trading volume.
              </p>
            </InfoTooltip>
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7">{assetSymbol} Current Price</div>
        <div>
          {price.isSuccess
            ? toPriceString(
                toUnscaled(price.data.price, scalers.marginDecimals),
                assetInfo.fractionDigits
              )
            : 0}
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7">TVL</div>
        <div>
          ${pool.isSuccess ? convertNotionalToString(pool.data.tvlUSD) : '-'}
        </div>
        <RateOfChange roc={pool.data?.diffTvlUSD} />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7">Volume 24h</div>
        <div>
          ${pool.isSuccess ? convertNotionalToString(pool.data.volumeUSD) : '-'}
        </div>
        <RateOfChange roc={pool.data?.diffVolumeUSD} />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7 flex justify-start">
          Fee 24h
          <InfoTooltip placement="bottom">
            <p className="text-center">
              This is the commission income earned by Predy&apos;s LP position
              in Uniswap v3 in 24 hours. Liquidity range and other information
              can be found in Stats and Dune.
            </p>
          </InfoTooltip>
        </div>
        <div>
          ${feesUSD.isSuccess ? convertNotionalToString(feesUSD.data) : '...'}
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7 flex justify-start">
          Implied Volatility
          <InfoTooltip placement="bottom">
            <p className="text-center">
              Implied volatility = 2*Sqrt( Fee24H/TickLiquidity )<br />
              Fee24H is shown on the left. TickLiquidity is the value of total
              Squart positions.
            </p>
          </InfoTooltip>
        </div>
        <div>{iv.isSuccess ? (iv.data * 100).toFixed(2) : '...'}%</div>
      </div>
    </div>
  )
}

export default ChartTop
