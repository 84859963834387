import { useBlockNumber } from './blockNumber'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import { useWeb3Provider } from './useWeb3Provider'
import { useChainId } from './network'

export function useBlockTimestamp(chainId: number) {
  const web3Provider = useWeb3Provider(chainId)
  const blockNumber = useBlockNumber()
  const [timestamp, setTimestamp] = useState(0)

  const timestampQuery = useQuery(
    ['timestamp', chainId, blockNumber],
    async () => {
      if (!web3Provider.isSuccess) throw new Error('web3Provider not loaded')
      const { provider } = web3Provider.data

      const block = await provider.getBlock('latest')

      return block.timestamp
    },
    {
      enabled: web3Provider.isSuccess
    }
  )

  useEffect(() => {
    if (timestampQuery.isSuccess) {
      setTimestamp(timestampQuery.data)
    }
  }, [timestampQuery.isSuccess, timestampQuery.data])

  return timestamp
}

export function useDeadline() {
  const chainId = useChainId()
  const blocktime = useBlockTimestamp(chainId)

  return useQuery(
    ['deadline', chainId, blocktime],
    async () => {
      return blocktime + 20 * 60 * 60
    },
    {
      enabled: blocktime > 0
    }
  )
}
