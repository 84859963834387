import React, { ReactNode, useState } from 'react'

type Props = {
  titleContent: ReactNode
  children: ReactNode
  isWide?: boolean
}

export const BasePopup = ({
  titleContent,
  children,
  isWide = false
}: Props) => {
  const [isEnteringTitle, setIsEnteringTitle] = useState(false)
  const [isEnteringContent, setIsEnteringContent] = useState(false)

  return (
    <div>
      <div
        onMouseEnter={() => {
          setIsEnteringTitle(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsEnteringTitle(false)
          }, 400)
        }}
      >
        {titleContent}
      </div>
      <BasePopupContent
        isWide={isWide}
        isShowing={isEnteringTitle || isEnteringContent}
        onMouseEnter={() => {
          setIsEnteringContent(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsEnteringContent(false)
          }, 400)
        }}
      >
        {children}
      </BasePopupContent>
    </div>
  )
}

const BasePopupContent = ({
  isWide,
  children,
  isShowing,
  onMouseEnter,
  onMouseLeave
}: {
  isWide: boolean
  children: ReactNode
  isShowing: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}) => {
  return (
    <div>
      <div
        className={`z-10 absolute mt-[2px] ${
          isWide ? 'w-[520px]' : 'w-60'
        } rounded-xl bg-black9 text-sm font-light z-20 pointer-cursor ${
          isShowing ? 'visible' : 'invisible'
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    </div>
  )
}
