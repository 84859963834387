import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { STALE_TIME, ZERO } from '../../constants'
import { useMemo } from 'react'
import { PairScalers } from '../../utils/helpers/scaler'
import { Position } from '../../utils/helpers/position'
import { ASSET_INFOS } from '../../constants/assets'
import { useWeb3Provider } from '../useWeb3Provider'

interface PerpStatus {
  amount: BigNumber
  entryValue: BigNumber
  entryPrice: BigNumber
}

export interface PositionStatusResult {
  assetId: number
  sqrtStatus: PerpStatus
  perpStatus: PerpStatus
  stableAmount: BigNumber
  underlyingAmount: BigNumber
  position: Position
  delta: BigNumber
  fee: BigNumber
  lossThreshold: BigNumber
  liqPrice1: BigNumber
  liqPrice2: BigNumber
}

export interface VaultStatusResult {
  id: number
  isMainVault: boolean
  vaultValue: BigNumber
  margin: BigNumber
  available: BigNumber
  withdrawable: BigNumber
  minDeposit: BigNumber
  positionValue: BigNumber
  openPositionStatusResults: PositionStatusResult[]
}

export interface VaultStatusResults {
  mainVault: VaultStatusResult
  isolatedVaults: VaultStatusResult[]
}

export function useIsolatedVault(
  chainId: number,
  pairGroupId: number,
  pairId: number
) {
  const vaultStatus = useVaultStatus(chainId, pairGroupId)

  return useMemo(() => {
    if (vaultStatus.isSuccess) {
      const isolatedVaults = vaultStatus.data.isolatedVaults.filter(
        isolatedVault => {
          const positions = isolatedVault.openPositionStatusResults.filter(
            position => position.assetId === pairId
          )

          return positions.length > 0
        }
      )

      if (isolatedVaults.length > 0) {
        return isolatedVaults[0]
      }
    }

    return null
  }, [pairId, vaultStatus.isSuccess, vaultStatus.data])
}

export const decodeVaultStatus: (
  vaultStatus: any,
  isMainVault: boolean,
  chainId: number
) => VaultStatusResult = (
  vaultStatus: any,
  isMainVault: boolean,
  chainId: number
) => {
  const margin = vaultStatus[2]
  const available = vaultStatus[1].sub(vaultStatus[4])
  const withdrawable = available.lt(margin) ? available : margin

  return {
    id: vaultStatus[0].toNumber(),
    isMainVault,
    vaultValue: vaultStatus[1],
    margin,
    available,
    withdrawable,
    minDeposit: vaultStatus[4],
    positionValue: vaultStatus[3],
    openPositionStatusResults: vaultStatus[5].map((openPositionStatus: any) => {
      const assetId = openPositionStatus[0].toNumber()
      const scalers = new PairScalers(assetId, chainId)

      const perpAmount = openPositionStatus[1][4][0]
      const perpEntryValue = openPositionStatus[1][4][1]
      const perpEntryPrice = perpAmount.eq(0)
        ? ZERO
        : perpEntryValue.mul(scalers.underlyingScaler).div(perpAmount).abs()
      const sqrtAmount = openPositionStatus[1][5][0]
      const sqrtEntryValue = openPositionStatus[1][5][1]
      const sqrtEntryPrice = sqrtAmount.eq(0)
        ? ZERO
        : sqrtEntryValue.mul(scalers.squartScaler).div(sqrtAmount).div(2).abs()

      const stableAmount = openPositionStatus[1][6][0]
      const underlyingAmount = openPositionStatus[1][7][0]

      const position = new Position(
        perpEntryValue.add(sqrtEntryValue),
        sqrtAmount,
        perpAmount,
        scalers,
        BigNumber.from(ASSET_INFOS[chainId][assetId].riskRatio)
      )

      const delta = openPositionStatus[2]

      return {
        assetId,
        perpStatus: {
          amount: perpAmount,
          entryValue: perpEntryValue,
          entryPrice: perpEntryPrice
        },
        sqrtStatus: {
          amount: sqrtAmount,
          entryValue: sqrtEntryValue,
          entryPrice: sqrtEntryPrice
        },
        stableAmount,
        underlyingAmount,
        delta,
        position,
        fee: openPositionStatus[3],
        lossThreshold: position.calculateLossThreshold(margin),
        liqPrice1: position.calculateLiquidationPrice1(margin),
        liqPrice2: position.calculateLiquidationPrice2(margin)
      }
    })
  }
}

export function useVaultStatus(chainId: number, pairGroupId: number) {
  const web3Provider = useWeb3Provider(chainId)
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)

  const vaultStatusQuery = useQuery<VaultStatusResults>(
    ['vault_status', chainId, web3Provider.data?.account, pairGroupId],

    async () => {
      if (!web3Provider.isSuccess) throw new Error('web3Provider not set')
      if (!addresses) throw new Error('addresses not set')

      const { provider, account } = web3Provider.data

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const result = await controller.callStatic.getVaultStatusWithAddress(
        pairGroupId,
        {
          from: account
        }
      )

      const vaultStatusResult = result[1].map(r =>
        decodeVaultStatus(r, false, chainId)
      )

      return {
        mainVault: decodeVaultStatus(result[0], true, chainId),
        isolatedVaults: vaultStatusResult.filter(vault => !vault.isMainVault)
      }
    },

    {
      enabled:
        web3Provider.isSuccess &&
        web3Provider.data.isConnected &&
        supportedChain &&
        !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 10000
    }
  )

  return vaultStatusQuery
}

export function useVaultStatusById(chainId: number, vaultId: number) {
  const web3Provider = useWeb3Provider(chainId)
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)

  const vaultStatusQuery = useQuery<VaultStatusResult>(
    ['vault_status_by_id', chainId, vaultId],

    async () => {
      if (!addresses) throw new Error('addresses not set')
      if (!web3Provider.isSuccess) throw new Error('web3provider not set')

      const { account, provider } = web3Provider.data

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const result = await controller.callStatic.getVaultStatus(vaultId, {
        from: account
      })

      return decodeVaultStatus(result, false, chainId)
    },

    {
      enabled: web3Provider.isSuccess && supportedChain && !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 10000
    }
  )

  return vaultStatusQuery
}

export type VaultSummary = {
  mainVaultId: number
  totalValue: BigNumber
  totalMargin: BigNumber
  portfolioVaultValue: BigNumber
  minValue: BigNumber
  marginAvailable: BigNumber
  marginWithdrawal: BigNumber
  isolatedVaultValue: BigNumber
  isolatedTotalDelta: BigNumber
}

export function useVaultSummary(chainId: number, pairGroupId: number) {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const vaultsStatus = useVaultStatus(chainId, pairGroupId)

  return useQuery<VaultSummary>(
    ['vault_summary', chainId, account, pairGroupId],

    async () => {
      if (!vaultsStatus.isSuccess) throw new Error('vaultsStatus not set')

      const isolatedVaultValue = vaultsStatus.data.isolatedVaults.reduce(
        (total, isolatedVault) => total.add(isolatedVault.vaultValue),
        ZERO
      )

      const isolatedVaultMargin = vaultsStatus.data.isolatedVaults.reduce(
        (total, isolatedVault) => total.add(isolatedVault.margin),
        ZERO
      )

      const isolatedTotalDelta = vaultsStatus.data.isolatedVaults.reduce(
        (total, isolatedVault) =>
          total.add(
            isolatedVault.openPositionStatusResults.reduce(
              (acc, item) => acc.add(item.delta),
              ZERO
            )
          ),
        ZERO
      )

      if (vaultsStatus.data.mainVault) {
        const mainVault = vaultsStatus.data.mainVault
        return {
          mainVaultId: mainVault.id,
          totalValue: mainVault.vaultValue.add(isolatedVaultValue),
          totalMargin: isolatedVaultMargin,
          portfolioVaultValue: mainVault.vaultValue,
          minValue: mainVault.minDeposit,
          marginAvailable: mainVault.available,
          marginWithdrawal: mainVault.withdrawable,
          isolatedVaultValue,
          isolatedTotalDelta
        }
      } else {
        return {
          mainVaultId: 0,
          totalValue: ZERO,
          totalMargin: ZERO,
          portfolioVaultValue: ZERO,
          minValue: ZERO,
          marginAvailable: ZERO,
          marginWithdrawal: ZERO,
          isolatedVaultValue,
          isolatedTotalDelta
        }
      }
    },

    {
      enabled: vaultsStatus.isSuccess,
      refetchInterval: 5000
    }
  )
}

export function useUnrealizedFee(chainId: number, vaultId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)

  const unrealizedFeeQuery = useQuery(
    ['unrealized_fee', chainId, vaultId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const result = await controller.callStatic.getVaultStatus(vaultId, {
        from: account
      })

      return result[5].map(openPositionStatus => ({
        assetId: openPositionStatus[0].toNumber(),
        unrealizedFee: openPositionStatus[3]
      }))
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )

  return unrealizedFeeQuery
}
