import React, { useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import AmountRangeInput from '../common/AmountRangeInput'
import SideSelector from '../common/SideSelector'
import { useDepositToStrategy } from '../../hooks/contracts/useDepositToStrategy'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useAddresses } from '../../hooks/useAddress'
import { calculateMargin } from '../../utils'
import {
  useDepositToStrategyQuoter,
  useWithdrawFromStrategyQuoter
} from '../../hooks/query/strategy/useStrategyQuoter'
import { marginAmountToString } from '../../utils/number'
import { useWithdrawFromStrategy } from '../../hooks/contracts/useWithdrawFromStrategy'
import { useERC20BalanceQueryCached } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import { reasonToErrorMessage } from '../../utils/error'
import { useStrategyApr } from '../../hooks/query/strategy/useStrategyApr'
import { BigNumber } from 'ethers'
import pendingStore from '../../store/pending'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  STRATEGY_INFOS
} from '../../constants/assets'
import { PairScalers } from '../../utils/helpers/scaler'
import { LoadingIndicatorPt } from '../common/LoadingIndicator'

const AprComponent = ({ apr }: { apr: BigNumber }) => (
  <div className={apr.gt(0) ? 'text-green' : 'text-red'}>
    {toUnscaled(apr, 16, 2)}%
  </div>
)

const StrategyTradeForm = ({
  chainId,
  strategyId
}: {
  chainId: number
  strategyId: number
}) => {
  const addresses = useAddresses(chainId)
  const [squartSide, setSquartSide] = useState(false)
  const [strategyAmount, setStrategyAmount] = useState(0)
  const { isPendingApproval, isPendingTx, setPendingApproval, setPendingTx } =
    pendingStore()

  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const assetId = strategyInfo.pairId
  const assetInfo = ASSET_INFOS[chainId][assetId]
  const marginInfo = MARGIN_INFOS[chainId][assetInfo.pairGroupId]
  const quoteToken = marginInfo.tokenAddress

  const balanceQuery = useERC20BalanceQueryCached(chainId, quoteToken)
  const strategyBalanceQuery = useERC20BalanceQueryCached(
    chainId,
    STRATEGY_INFOS[chainId][strategyId].strategyTokenAddress
  )

  const scalers = new PairScalers(assetId, chainId)

  const allowanceQuery = useAllowanceQuery(
    chainId,
    addresses.GammaShortStrategy,
    quoteToken
  )

  const quote = useDepositToStrategyQuoter(chainId, assetId, {
    strategyId,
    strategyTokenAmount: toScaled(strategyAmount, scalers.marginDecimals),
    maxDepositAmount: toScaled(strategyAmount, scalers.marginDecimals)
      .mul(11)
      .div(10)
  })
  const quoteWithdraw = useWithdrawFromStrategyQuoter(chainId, assetId, {
    strategyId,
    strategyTokenAmount: toScaled(strategyAmount, scalers.marginDecimals)
  })
  const strategyApy = useStrategyApr(chainId, strategyId)

  const deposit = useDepositToStrategy(assetId)
  const withdraw = useWithdrawFromStrategy(assetId)
  const approve = useApproveMutation()

  const onApprove = async () => {
    if (addresses === undefined || !quote.data) {
      return
    }

    const tx = await approve.mutateAsync({
      spender: addresses.GammaShortStrategy,
      address: quoteToken,
      amount: calculateMargin(quote.data)
    })

    setPendingApproval(tx)
  }
  const onTrade = async () => {
    if (squartSide) {
      if (quoteWithdraw.data) {
        const tx = await withdraw.mutateAsync({
          strategyId,
          strategyTokenAmount: toScaled(strategyAmount, scalers.marginDecimals),
          minWithdrawAmount: quoteWithdraw.data.mul(99).div(100)
        })

        await setPendingTx(tx)
        setStrategyAmount(0)
      }
    } else {
      if (!quote.data) {
        return
      }

      const tx = await deposit.mutateAsync({
        strategyId,
        strategyTokenAmount: toScaled(strategyAmount, scalers.marginDecimals),
        maxDepositAmount: calculateMargin(quote.data)
      })

      await setPendingTx(tx)
      setStrategyAmount(0)
    }
  }

  const requiredUSDCAmount = calculateMargin(
    quote.data ? quote.data : toScaled(strategyAmount, scalers.marginDecimals)
  )
  const requiredStrategyAmount = toScaled(
    strategyAmount,
    scalers.marginDecimals
  )

  const hasEnoughUSDC = balanceQuery.gte(requiredUSDCAmount)
  const enoughUSDCApproved = allowanceQuery.gte(requiredUSDCAmount)
  const hasEnoughStrategyToken = strategyBalanceQuery.gte(
    requiredStrategyAmount
  )

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white leading-5">
        <div className="mx-5 my-5">
          <SideSelector side={squartSide} onChange={setSquartSide} />
        </div>
        <div className="mx-5 my-5 p-4 rounded-xl bg-black4 shadow-sm">
          <AmountRangeInput
            amount={0}
            max={1000}
            title={'Strategy'}
            step={1}
            onChange={setStrategyAmount}
          />
        </div>
      </div>
      <div className="mx-4 my-5 p-2 flex justify-between rounded-full bg-black3">
        <div className="py-1 px-2 rounded-full bg-white1 text-base font-semibold">
          {squartSide ? 'You Get' : 'You Pay'}
        </div>
        <div className="py-1 pr-2">
          <span className="text-white5 pr-1">
            {squartSide
              ? quoteWithdraw.data
                ? marginAmountToString(marginInfo, quoteWithdraw.data, false)
                : '-'
              : quote.data
              ? marginAmountToString(marginInfo, quote.data, false)
              : '-'}
          </span>
          {marginInfo.name}
        </div>
      </div>

      <div className="mx-5 my-5">
        <div className="text-base">Historical Performance based on UTC</div>
        <div className="mt-4 px-1 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>1 Day</div>
            <AprComponent apr={strategyApy.dayApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>7 Days</div>
            <AprComponent apr={strategyApy.weekApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>30 Days</div>
            <AprComponent apr={strategyApy.monthApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>365 Days (or as available converted)</div>
            <AprComponent apr={strategyApy.yearApr} />
          </div>
        </div>
      </div>
      <div className="mx-5 my-5 h-12 flex justify-between items-center space-x-2">
        <PrimaryButton
          onClick={onApprove}
          disabled={
            strategyInfo.disabled
              ? true
              : squartSide ||
                isPendingApproval ||
                enoughUSDCApproved ||
                !hasEnoughUSDC
          }
        >
          Approve
        </PrimaryButton>
        <PrimaryButton
          onClick={onTrade}
          disabled={
            strategyInfo.disabled
              ? true
              : (squartSide && !hasEnoughStrategyToken) ||
                (!squartSide &&
                  (!enoughUSDCApproved || isPendingTx || !hasEnoughUSDC)) ||
                strategyAmount === 0
          }
        >
          {isPendingTx ? (
            <LoadingIndicatorPt />
          ) : squartSide ? (
            'Withdraw'
          ) : (
            'Deposit'
          )}
        </PrimaryButton>
      </div>

      {strategyInfo.disabled ? (
        <div className="mx-5 my-5 text-xs">
          <span>Deposit function is currently disabled.</span>
        </div>
      ) : (
        <></>
      )}

      {(!squartSide && hasEnoughUSDC) ||
      (squartSide && hasEnoughStrategyToken) ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>
            Insufficient {squartSide ? 'Strategy' : 'USDC'} amount in your
            wallet.
          </span>
        </div>
      )}

      {quote.error === null ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>{reasonToErrorMessage(quote.error)}</span>
        </div>
      )}
    </div>
  )
}

export default StrategyTradeForm
