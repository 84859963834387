import { BigNumber } from 'ethers'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../typechain'
import { useBlockNumber } from '../blockNumber'
import { useEffect, useState } from 'react'
import { useWeb3Provider } from '../useWeb3Provider'

export function useAllowanceQuery(
  chainId: number,
  spender: string,
  address: string
) {
  const web3Provider = useWeb3Provider(chainId)
  const blockNumber = useBlockNumber()

  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))

  const allowanceQuery = useQuery(
    [
      'allowance',
      web3Provider.data?.account,
      spender,
      address,
      chainId,
      blockNumber
    ],
    async () => {
      if (!web3Provider.isSuccess) throw new Error('web3provider not set')

      const { account, provider } = web3Provider.data

      const contract = ERC20__factory.connect(address, provider)
      return await contract.allowance(account, spender)
    },
    {
      enabled: web3Provider.isSuccess && web3Provider.data.isConnected
    }
  )

  useEffect(() => {
    if (allowanceQuery.isSuccess) {
      setAllowance(allowanceQuery.data)
    }
  }, [allowanceQuery.isSuccess, allowanceQuery.data])

  return allowance
}
