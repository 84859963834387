import React from 'react'

export enum DropdownMenuItem {
  NO_SELECTED,
  DEPOSIT,
  WITHDRAW,
  DISCONNECT
}

type Props = {
  isShow: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  onSelected: (item: DropdownMenuItem) => void
}

export const DropdownMenu = ({
  isShow,
  onMouseEnter,
  onMouseLeave,
  onSelected
}: Props) => {
  return (
    <ul
      className={`absolute mt-[2px] w-40 bg-[#1F2126] text-sm font-light z-20 cursor-pointer ${
        isShow ? 'visible' : 'invisible'
      }`}
      onMouseEnter={() => {
        onMouseEnter()
      }}
      onMouseLeave={() => {
        onMouseLeave()
      }}
    >
      <li
        className="w-full pl-4 pr-2 py-3 hover:bg-[#2F3136]"
        onClick={() => {
          onSelected(DropdownMenuItem.DEPOSIT)
        }}
      >
        Deposit
      </li>
      <li
        className="w-full pl-4 pr-2 py-3 hover:bg-[#2F3136]"
        onClick={() => {
          onSelected(DropdownMenuItem.WITHDRAW)
        }}
      >
        Withdraw
      </li>
      <li
        className="w-full pl-4 pr-2 py-3 hover:bg-[#2F3136]"
        onClick={() => {
          onSelected(DropdownMenuItem.DISCONNECT)
        }}
      >
        Disconnect
      </li>
    </ul>
  )
}
