import { BigNumber } from 'ethers'
import { useQuery } from 'react-query'
import { ONE, Q128, ZERO } from '../../../constants'
import { usePrice } from '../../usePrice'
import { createEmptyPosition, useVaults } from '../useVault'
import {
  calculateAssetInterest,
  calculateSquartInterest
} from './useAssetInterest'
import { useUniswapTradeFee24H } from './useUniswapTradeFee'
import { Asset, AssetPool, useAsset } from '../useAsset'
import { useTradeQuoter } from '../useQuoter'
import { priceMul, sqrtPriceMul } from '../../../utils/price'
import {
  calculateAmount0Offset,
  calculateAmount1Offset
} from '../../../utils/uniswap'
import { ASSET_INFOS } from '../../../constants/assets'
import { usePairContext } from '../../usePairContext'

export function estimateFee(
  chainId: number,
  pair: Asset,
  price: BigNumber,
  position: {
    stableAmount: BigNumber
    underlyingAmount: BigNumber
    sqrt2Amount: BigNumber
  },
  diff: {
    stableAmount: BigNumber
    underlyingAmount: BigNumber
    sqrt2Amount: BigNumber
  },
  uniswapTradeFee: {
    fee0: BigNumber
    fee1: BigNumber
  }
) {
  const squartInterest = calculateSquartInterest(
    pair,
    position.sqrt2Amount.sub(diff.sqrt2Amount),
    diff.sqrt2Amount
  )

  let tradeFeeStable = ZERO
  let tradeFeeUnderlying = ZERO
  if (pair.isMarginZero) {
    tradeFeeStable = uniswapTradeFee.fee0.mul(position.sqrt2Amount).div(Q128)
    tradeFeeUnderlying = uniswapTradeFee.fee1
      .mul(position.sqrt2Amount)
      .div(Q128)
  } else {
    tradeFeeUnderlying = uniswapTradeFee.fee0
      .mul(position.sqrt2Amount)
      .div(Q128)
    tradeFeeStable = uniswapTradeFee.fee1.mul(position.sqrt2Amount).div(Q128)
  }

  let tradePremiumStable = ZERO
  let tradePremiumUnderlying = ZERO

  if (position.sqrt2Amount.gt(0)) {
    if (squartInterest.supply.eq(0)) {
      tradePremiumUnderlying = tradeFeeUnderlying
      tradePremiumStable = tradeFeeStable
    } else {
      tradePremiumUnderlying = tradeFeeUnderlying
        .mul(
          squartInterest.supply.add(
            squartInterest.borrow.mul(squartInterest.spread).div(1000)
          )
        )
        .div(squartInterest.supply)
      tradePremiumStable = tradeFeeStable
        .mul(
          squartInterest.supply.add(
            squartInterest.borrow.mul(squartInterest.spread).div(1000)
          )
        )
        .div(squartInterest.supply)
    }
  } else {
    tradePremiumUnderlying = tradeFeeUnderlying
      .mul(squartInterest.spread.add(1000))
      .div(1000)
    tradePremiumStable = tradeFeeStable
      .mul(squartInterest.spread.add(1000))
      .div(1000)
  }

  // Calculates Asset interest
  const interestStable = calculateDailyAssetInterest(
    pair.stablePool,
    position.stableAmount,
    diff.stableAmount
  )

  const interestUnderlying = calculateDailyAssetInterest(
    pair.underlyingPool,
    position.underlyingAmount,
    diff.underlyingAmount
  )

  const scaler = BigNumber.from(10).pow(
    ASSET_INFOS[chainId][pair.id.toNumber()].decimals
  )

  const totalInterestEst = interestStable.add(
    interestUnderlying.mul(price).div(scaler)
  )
  const totalPremiumEst = tradePremiumStable.add(
    tradePremiumUnderlying.mul(price).div(scaler)
  )

  const totalFeeEst = totalInterestEst.add(totalPremiumEst)

  return {
    total: totalFeeEst,
    totalInterestEst,
    totalPremiumEst
  }
}

function calculateDailyAssetInterest(
  assetPool: AssetPool,
  position: BigNumber,
  trade: BigNumber
) {
  const stableInterest = calculateAssetInterest(
    assetPool.tokenStatus,
    assetPool.irmParams,
    position.sub(trade),
    trade
  )

  if (position.gt(0)) {
    return position.mul(stableInterest.supplyInterest).div(ONE).div(365)
  } else {
    return position.mul(stableInterest.borrowInterest).div(ONE).div(365)
  }
}

export function useFeeEst(
  chainId: number,
  assetId: number,
  vaultIds: number[],
  tradeAmountPerp: BigNumber,
  tradeAmount2Sqrt: BigNumber
) {
  const asset = useAsset(chainId, assetId)
  const vaults = useVaults(chainId, vaultIds)
  const price = usePrice(chainId, assetId)
  const scalers = usePairContext(chainId, assetId)
  const quote = useTradeQuoter(chainId, {
    vaultId: vaultIds[0],
    assetId,
    tradeAmount: tradeAmountPerp,
    tradeAmountSqrt: tradeAmount2Sqrt
  })
  const tradeFeeIn24h = useUniswapTradeFee24H(
    chainId,
    ASSET_INFOS[chainId][assetId].poolAddress,
    true
  )

  return useQuery(
    ['fee_est', assetId, vaultIds, tradeAmountPerp, tradeAmount2Sqrt],

    async () => {
      if (!asset.isSuccess) throw new Error('asset not set')
      if (!vaults.isSuccess) throw new Error('vaults not set')
      if (!price.isSuccess) throw new Error('price not set')
      if (!tradeFeeIn24h.isSuccess) throw new Error('tradeFeeIn24h not set')
      if (!scalers.isSuccess) throw new Error('scalers not set')

      const positions = vaults.isSuccess
        ? vaults.data.length === 0
          ? [createEmptyPosition(assetId)]
          : vaults.data.map(vault => {
              return (
                vault.openPositions.find(
                  openPosition => openPosition.assetId === assetId
                ) || createEmptyPosition(assetId)
              )
            })
        : []

      const tradeAmountStable =
        quote.isSuccess && quote.data.data
          ? quote.data.data.perpEntryUpdate
              .add(quote.data.data.sqrtEntryUpdate)
              .add(quote.data.data.sqrtRebalanceEntryUpdateStable)
          : priceMul(price.data.price, tradeAmountPerp.mul(-1), scalers.data)
              .add(sqrtPriceMul(price.data.sqrtPrice, tradeAmount2Sqrt.mul(-2)))
              .add(
                calculateAmount1Offset(
                  asset.data.sqrtAssetStatus.tickUpper,
                  tradeAmount2Sqrt
                )
              )

      const tradeAmountUnderlying = tradeAmountPerp.add(
        quote.isSuccess && quote.data.data
          ? quote.data.data.sqrtRebalanceEntryUpdateUnderlying
          : calculateAmount0Offset(
              asset.data.sqrtAssetStatus.tickLower,
              tradeAmount2Sqrt
            )
      )

      const feeEsts = positions
        .filter(position => position.assetId === assetId)
        .map(position => {
          const positionDiffSquart = tradeAmount2Sqrt.add(
            position.sqrtPerpPosition
          )
          const positionDiffStable = tradeAmountStable.add(
            position.stableAssetOrDebtAmount
          )
          const positionDiffUnderlying = tradeAmountUnderlying.add(
            position.underlyingAssetOrDebtAmount
          )

          return estimateFee(
            chainId,
            asset.data,
            price.data.price,
            {
              stableAmount: positionDiffStable,
              underlyingAmount: positionDiffUnderlying,
              sqrt2Amount: positionDiffSquart
            },
            {
              stableAmount: tradeAmountStable,
              underlyingAmount: tradeAmountUnderlying,
              sqrt2Amount: tradeAmount2Sqrt
            },
            {
              fee0: tradeFeeIn24h.data.fee0,
              fee1: tradeFeeIn24h.data.fee1
            }
          )
        })

      return feeEsts.length > 0
        ? feeEsts[0]
        : {
            total: ZERO,
            totalInterestEst: ZERO,
            totalPremiumEst: ZERO
          }
    },
    {
      enabled:
        asset.isSuccess &&
        vaults.isSuccess &&
        price.isSuccess &&
        tradeFeeIn24h.isSuccess &&
        scalers.isSuccess
    }
  )
}
