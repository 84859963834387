import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useCachedPrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { useChainId } from '../network'

type TradePerpParams = {
  vaultId: number
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
}

export function useTradePerp(assetId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const deadline = useDeadline()
  const chainId = useChainId()
  const price = useCachedPrice(chainId, assetId)
  const addresses = useAddresses(chainId)

  return useMutation(async (params: TradePerpParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!chainId) throw new Error('chainId not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.tradePerp(params.vaultId, params.assetId, {
      tradeAmount: params.tradeAmount,
      tradeAmountSqrt: params.tradeAmountSqrt,
      lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
      upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
      deadline: deadline.data,
      enableCallback: false,
      data: '0x'
    })
  })
}
