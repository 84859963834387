import { useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { useQuery } from 'react-query'
import { BigNumber } from 'ethers'
import { toScaled, toUnscaled } from '../../utils/bn'
import { priceMul, sqrtPriceMul, toUnscaledSqrtPrice } from '../../utils/price'
import { PairScalers } from '../../utils/helpers/scaler'
import { usePrice } from '../usePrice'
import { PositionStyle } from '../../constants/enum'
import { computeTradeAmounts } from '../../utils/helpers/targetStrategy'
import { Position } from '../../utils/helpers/position'
import { ZERO } from '../../constants'
import { roundMargin } from '../../utils'
import { Vault, useVault } from './useVault'
import {
  queryIsolatedTradeQuoter,
  queryQuoteBase,
  queryTradeQuoter
} from './useQuoter'
import { Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useUnrealizedFee, useVaultStatusById } from './useVaultStatus'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'
import { useAsset } from './useAsset'
import { useWeb3Provider } from '../useWeb3Provider'

function getBeforePerpAmount(assetId: number, vault?: Vault) {
  if (!vault) {
    return {
      squartAmount: ZERO,
      perpAmount: ZERO,
      marginAmount: ZERO
    }
  }
  const openPositions = vault.openPositions.filter(
    openPosition => openPosition.assetId === assetId
  )

  const squartAmounts = openPositions.map(
    openPosiition => openPosiition.sqrtPerpPosition
  )
  const perpAmounts = openPositions.map(
    openPosiition => openPosiition.perpPosition
  )

  return {
    squartAmount: squartAmounts.length > 0 ? squartAmounts[0] : ZERO,
    perpAmount: perpAmounts.length > 0 ? perpAmounts[0] : ZERO,
    marginAmount: vault.margin
  }
}

/**
 * useTradeAfter
 * @param vaultId
 * @param pairGroupId
 * @param assetId
 * @param squartSide
 * @param size
 * @param marketStrategy
 * @param leverage in case of trade in MainVault leverage should be undefined.
 * @returns
 */
export function useTradeAfter(
  chainId: number,
  vaultId: number,
  pairGroupId: number,
  assetId: number,
  squartSide: boolean,
  size: number,
  marketStrategy: PositionStyle,
  leverage?: number
) {
  const web3Provider = useWeb3Provider(chainId)
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)
  const price = usePrice(chainId, assetId)
  const pair = useAsset(chainId, assetId)
  const vault = useVault(chainId, vaultId)
  const vaultStatus = useVaultStatusById(chainId, vaultId)
  const deadline = useDeadline()
  const unrealizedFees = useUnrealizedFee(chainId, vaultId)

  return useQuery(
    [
      'trade_after',
      chainId,
      vaultId,
      pairGroupId,
      assetId,
      squartSide,
      size,
      marketStrategy,
      leverage
    ],

    async () => {
      if (!price.isSuccess) throw new Error('price not set')
      if (!pair.isSuccess) throw new Error('pair not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!chainId) throw new Error('chainId not set')
      if (!web3Provider.isSuccess) throw new Error('web3provider not set')

      const { provider, account } = web3Provider.data

      const scalers = new PairScalers(assetId, chainId)
      const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

      const beforeAmounts = getBeforePerpAmount(assetId, vault.data)
      const beforeValue = vaultStatus.isSuccess
        ? vaultStatus.data.vaultValue
        : ZERO

      const sqrtAmount = toScaled(
        size * marginInfo.sizeToSquart,
        scalers.squartDecimals
      )
        .div(2)
        .mul(squartSide ? -1 : 1)

      const perpAmount = toScaled(
        computeTradeAmounts(
          squartSide,
          marketStrategy,
          size * marginInfo.sizeToSquart,
          toUnscaledSqrtPrice(price.data.sqrtPrice, scalers)
        ),
        scalers.underlyingDecimals
      )

      const position = new Position(
        ZERO,
        sqrtAmount,
        perpAmount,
        scalers,
        pair.data.riskParams.riskRatio
      )
      const gamma = position.calculateGamma(price.data.sqrtPrice)
      const delta = toUnscaled(
        position.calculateDelta(price.data.sqrtPrice),
        scalers.underlyingDecimals
      )
      const minDeposit = position.calculateMinDeposit(price.data.sqrtIndexPrice)
      const afterValue = leverage
        ? roundMargin(
            MARGIN_INFOS[chainId][pairGroupId].rounder,
            minDeposit
              .mul(ASSET_INFOS[chainId][assetId].maxLeverage)
              .div(leverage)
          )
        : null

      const tradeAmountSqrt = sqrtAmount.sub(beforeAmounts.squartAmount)
      const tradeAmountPerp = perpAmount.sub(beforeAmounts.perpAmount)
      const tradeMarginAmount = afterValue
        ? roundMargin(
            MARGIN_INFOS[chainId][pairGroupId].rounder,
            afterValue.sub(beforeValue)
          )
        : null

      const afterMargin = beforeAmounts.marginAmount.add(
        tradeMarginAmount || ZERO
      )

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const tradeResult = tradeMarginAmount
        ? await queryQuoteBase(
            controller,
            {
              assetId,
              vaultId,
              tradeAmount: tradeAmountPerp,
              tradeAmountSqrt,
              marginAmount: tradeMarginAmount
            },
            price.data.sqrtPrice,
            deadline.data,
            account,
            chainId,
            queryIsolatedTradeQuoter
          )
        : await queryQuoteBase(
            controller,
            {
              assetId,
              vaultId,
              tradeAmount: tradeAmountPerp,
              tradeAmountSqrt
            },
            price.data.sqrtPrice,
            deadline.data,
            account,
            chainId,
            queryTradeQuoter
          )

      let afterEntryUpdate = ZERO
      if (tradeResult.data) {
        const result = tradeResult.data

        afterEntryUpdate = result.perpEntryUpdate
          .add(result.sqrtEntryUpdate)
          .add(result.perpPayoff)
          .add(result.sqrtPayoff)
      } else {
        afterEntryUpdate = priceMul(
          price.data.price,
          tradeAmountPerp.mul(-1),
          scalers
        ).add(sqrtPriceMul(price.data.sqrtPrice, tradeAmountSqrt.mul(-2)))
      }

      let margin = afterMargin

      if (vault.isSuccess && unrealizedFees.isSuccess) {
        if (margin === null) {
          // in case of main vault
          margin = vault.data.margin
        }

        const stableEntryValue = vault.data.openPositions
          .filter(openPosition => openPosition.assetId === assetId)
          .map(openPosition => openPosition.stableEntryValue)[0]

        const unrealizedFee = unrealizedFees.data.filter(
          unrealizedFee => unrealizedFee.assetId === assetId
        )

        margin = margin.add(
          unrealizedFee.length > 0 ? unrealizedFee[0].unrealizedFee : ZERO
        )

        position.stable = position.stable.add(stableEntryValue || ZERO)
      }
      position.stable = position.stable.add(afterEntryUpdate)

      let liqPrices: BigNumber[] = []

      if (margin) {
        const liqPrice1 = position.calculateLiquidationPrice1(margin)
        const liqPrice2 = position.calculateLiquidationPrice2(margin)

        liqPrices = [liqPrice1, liqPrice2]

        liqPrices.sort((a, b) => {
          return toUnscaled(a.sub(b), scalers.marginDecimals)
        })
      }

      return {
        sqrtAmount,
        perpAmount,
        minDeposit,
        afterValue,
        afterMargin,
        delta,
        gamma,
        tradeAmountSqrt,
        tradeAmountPerp,
        tradeMarginAmount,
        beforeAmounts,
        liqPrices,
        quoterResult: tradeResult,
        expectedEntryUpdate: afterEntryUpdate
      }
    },

    {
      enabled:
        web3Provider.isSuccess &&
        supportedChain &&
        price.isSuccess &&
        pair.isSuccess &&
        deadline.isSuccess
    }
  )
}
