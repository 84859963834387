import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useChainId } from '../network'

type UpdateMarginParams = {
  pairGroupId: number
  marginAmount: BigNumberish
}

export function useUpdateMargin() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const addresses = useAddresses(chainId)

  return useMutation(async (params: UpdateMarginParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.updateMargin(params.pairGroupId, params.marginAmount)
  })
}
