import React from 'react'
import Portfolio from '../components/portfolio/Portfolio'
import { useTradeRoute } from '../hooks/useTradeRoute'
import NotFound from '../components/NotFound'

const PortfolioView = () => {
  const route = useTradeRoute()

  if (route.isNotFound || route.pairGroupId === undefined) {
    return <NotFound />
  }

  return (
    <div className="max-w-7xl mx-auto content-start">
      <Portfolio chainId={route.chainId} pairGroupId={route.pairGroupId} />
    </div>
  )
}

export default PortfolioView
