import React from 'react'
import { reasonToErrorMessage } from '../../../utils/error'

export const TradeErrorArea = ({ error }: { error: string }) => {
  return (
    <div className="my-1 flex justify-center items-center">
      <div className="text-red text-xs">{reasonToErrorMessage(error)}</div>
    </div>
  )
}
