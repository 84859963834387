import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { NETWORK_PARAMS } from '../constants'
import { useQuery } from 'react-query'

const PROVIDERS: {
  [key: number]: ethers.providers.StaticJsonRpcProvider
} = {
  8453: new ethers.providers.StaticJsonRpcProvider(
    NETWORK_PARAMS[8453].rpcUrls[0]
  ),
  42161: new ethers.providers.StaticJsonRpcProvider(
    NETWORK_PARAMS[42161].rpcUrls[0]
  )
}

export function useWeb3Provider(specifiedChainId: number) {
  const { chainId, provider, account } =
    useWeb3React<ethers.providers.Web3Provider>()

  return useQuery(
    ['web3probider', specifiedChainId, chainId, account],
    () => {
      if (provider && account && specifiedChainId === chainId) {
        return {
          provider,
          account,
          isConnected: true
        }
      } else {
        const jsonProvider = PROVIDERS[specifiedChainId]

        return {
          provider: jsonProvider,
          account: ethers.constants.AddressZero,
          isConnected: false
        }
      }
    },
    {
      enabled: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  )
}
