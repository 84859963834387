import React from 'react'
import { useLendingSummary } from '../../hooks/query/lending/useLendingSummary'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'

type Props = {
  chainId: number
  pairGroupId: number
  pairId: number
  isStable: boolean
  onChange: (isStable: boolean) => void
}

const StableSelector = ({
  chainId,
  pairGroupId,
  pairId,
  isStable,
  onChange
}: Props) => {
  const pairInfo = ASSET_INFOS[chainId][pairId]
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]
  const riskSummary = useLendingSummary(chainId, pairId, false)
  const stableSummary = useLendingSummary(chainId, pairId, true)

  return (
    <div className="w-full p-3 rounded-xl bg-black3 text-base font-light shadow-sm">
      <span className="text-subtext">Asset for Lending</span>
      <div className="flex justify-between">
        <button
          className={`w-[140px] h-24 rounded-xl bg-white1 text-white space-y-2 ${
            !isStable ? 'border-[1px] border-white' : ''
          }`}
          onClick={() => {
            onChange(false)
          }}
        >
          <span>{pairInfo.name}</span>
          <div className="flex justify-around items-center">
            <img className="rounded-full" src={pairInfo.icon} width={40} />
            <div>
              <div>{riskSummary.apy.toFixed(2)}%</div>
              <div>APY</div>
            </div>
          </div>
        </button>
        <button
          className={`w-[140px] h-24 rounded-xl bg-white1 text-white space-y-2 ${
            isStable ? 'border-[1px] border-white' : ''
          }`}
          onClick={() => {
            onChange(true)
          }}
        >
          <span>{marginInfo.name}</span>
          <div className="flex justify-around items-center">
            <img className="rounded-full" src={marginInfo.icon} width={40} />
            <div>
              <div>{stableSummary.apy.toFixed(2)}%</div>
              <div>APY</div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default StableSelector
