import { BigNumber } from 'ethers'
import { useQuery } from 'react-query'
import { useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import {
  GammaShortStrategy__factory,
  Multicall__factory
} from '../../../typechain'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { toUnscaled } from '../../../utils/bn'
import { ONE, Q96, REFETCH_INTERVAL } from '../../../constants'
import {
  ASSET_INFOS,
  MARGIN_INFOS,
  STRATEGY_INFOS
} from '../../../constants/assets'
import { computePrice } from '../../../utils/helpers/position'
import { PairScalers } from '../../../utils/helpers/scaler'
import { useWeb3Provider } from '../../useWeb3Provider'
import { getAmountsForLiquidity } from '../../../utils/uniswap'
import { TickMath } from '@uniswap/v3-sdk'
import { Asset, ScaledAssetStatus, useAsset } from '../useAsset'
import { usePrice } from '../../usePrice'

dayjs.extend(duration)

type StrategySummary = {
  issueingCap: number
  nextHedgeIn: {
    hours: number
    minutes: number
    seconds: number
  }
  totalIssuing: number
  price: number
  lowerPrice: number
  upperPrice: number
  lastHedgePrice: number
  hedgeSqrtPriceThreshold: BigNumber
  hedgePriceThreshold: string
}

function getAvailableStrategy(
  chainId: number,
  strategyId: number,
  sqrtPrice: BigNumber,
  pairStatus: Asset
) {
  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const scaler = new PairScalers(strategyInfo.pairId, chainId)
  const squartPerStrategy = strategyInfo.squartPerSt

  if (strategyInfo.isGammaShort) {
    const amounts = getAmountsForLiquidity(
      sqrtPrice,
      BigNumber.from(
        TickMath.getSqrtRatioAtTick(
          pairStatus.sqrtAssetStatus.tickLower
        ).toString()
      ),
      BigNumber.from(
        TickMath.getSqrtRatioAtTick(
          pairStatus.sqrtAssetStatus.tickUpper
        ).toString()
      ),
      scaler.squartScaler
    )

    const stableAvailable = getAvailable(pairStatus.stablePool.tokenStatus)
    const underlyingAvailable = getAvailable(
      pairStatus.underlyingPool.tokenStatus
    )

    let availableS = 0
    let availableU = 0

    if (pairStatus.isMarginZero) {
      availableS = amounts[0].eq(0)
        ? Infinity
        : stableAvailable.div(amounts[0]).toNumber()
      availableU = amounts[1].eq(0)
        ? Infinity
        : underlyingAvailable.div(amounts[1]).toNumber()
    } else {
      availableS = amounts[1].eq(0)
        ? Infinity
        : stableAvailable.div(amounts[1]).toNumber()
      availableU = amounts[0].eq(0)
        ? Infinity
        : underlyingAvailable.div(amounts[0]).toNumber()
    }

    return (
      (availableS < availableU ? availableS : availableU) / squartPerStrategy
    )
  } else {
    return (
      pairStatus.sqrtAssetStatus.totalAmount
        .sub(pairStatus.sqrtAssetStatus.borrowedAmount)
        .mul(2)
        .div(scaler.squartScaler)
        .toNumber() / squartPerStrategy
    )
  }
}

function getAvailable(scaledAssetStatus: ScaledAssetStatus) {
  return scaledAssetStatus.totalCompoundDeposited
    .mul(scaledAssetStatus.assetScaler)
    .div(ONE)
    .add(scaledAssetStatus.totalNormalDeposited)
    .sub(scaledAssetStatus.totalNormalBorrowed)
}

export function useStrategySummary(chainId: number, strategyId: number) {
  const web3Provider = useWeb3Provider(chainId)
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses(chainId)
  const pairId = STRATEGY_INFOS[chainId]
    ? STRATEGY_INFOS[chainId][strategyId].pairId
    : 1
  const pairStatus = useAsset(chainId, pairId)
  const price = usePrice(chainId, pairId)

  return useQuery<StrategySummary>(
    ['st_summary', chainId, strategyId],

    async () => {
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!pairStatus.isSuccess) throw new Error('pairStatus not set')
      if (!price.isSuccess) throw new Error('price not set')
      if (!web3Provider.isSuccess) throw new Error('web3Provider not set')

      const { provider } = web3Provider.data

      const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
      const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]
      const marginInfo = MARGIN_INFOS[chainId][pairInfo.pairGroupId]
      const scalers = new PairScalers(pairInfo.id, chainId)

      const contract = GammaShortStrategy__factory.connect(
        addresses.GammaShortStrategy,
        provider
      )
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const calls = [
        {
          target: contract.address,
          callData: contract.interface.encodeFunctionData('getTotalSupply', [
            strategyId
          ])
        },
        {
          target: contract.address,
          callData: contract.interface.encodeFunctionData('strategies', [
            strategyId
          ])
        },
        {
          target: contract.address,
          callData: contract.interface.encodeFunctionData('getPrice', [
            strategyId
          ])
        }
      ]

      const result = await multicall.callStatic.aggregate(calls)

      const totalSupply = contract.interface.decodeFunctionResult(
        'getTotalSupply',
        result.returnData[0]
      )[0]
      const strategyStatus = contract.interface.decodeFunctionResult(
        'strategies',
        result.returnData[1]
      )
      const strategyPrice = contract.interface.decodeFunctionResult(
        'getPrice',
        result.returnData[2]
      )[0]

      const lastHedgeTimestamp = strategyStatus[7][0]
      const hedgeInterval = strategyStatus[7][2]

      const lastHedgePrice = strategyStatus[7][1]
      const hedgeSqrtPriceThreshold = strategyStatus[7][3]
      const hedgePriceThreshold = toUnscaled(
        hedgeSqrtPriceThreshold.mul(hedgeSqrtPriceThreshold).div(ONE),
        18
      )

      const nextHedgeTime = dayjs(
        lastHedgeTimestamp.add(hedgeInterval).toNumber() * 1000
      )
      const now = dayjs()

      const nextHedgeIn = nextHedgeTime.diff(now)

      const nextHedgeInDuration = dayjs.duration(nextHedgeIn)

      const daysUntil = nextHedgeInDuration.days()

      return {
        nextHedgeIn: {
          hours: nextHedgeInDuration.hours() + daysUntil * 24,
          minutes: nextHedgeInDuration.minutes(),
          seconds: nextHedgeInDuration.seconds()
        },
        totalIssuing: toUnscaled(totalSupply, marginInfo.decimals),
        issueingCap: Math.floor(
          toUnscaled(totalSupply, marginInfo.decimals) +
            getAvailableStrategy(
              chainId,
              strategyId,
              price.data.sqrtPrice,
              pairStatus.data
            )
        ),
        price: toUnscaled(strategyPrice, 18),
        lowerPrice: toUnscaled(
          computePrice(
            lastHedgePrice.mul(ONE).div(hedgeSqrtPriceThreshold),
            scalers
          ).div(Q96),
          marginInfo.decimals
        ),
        upperPrice: toUnscaled(
          computePrice(
            lastHedgePrice.mul(hedgeSqrtPriceThreshold).div(ONE),
            scalers
          ).div(Q96),
          marginInfo.decimals
        ),
        lastHedgePrice: toUnscaled(
          computePrice(lastHedgePrice, scalers).div(Q96),
          marginInfo.decimals,
          pairInfo.fractionDigits
        ),
        hedgeSqrtPriceThreshold,
        hedgePriceThreshold: ((hedgePriceThreshold - 1) * 100).toFixed(1)
      }
    },
    {
      enabled:
        web3Provider.isSuccess &&
        supportedChain &&
        !!addresses &&
        pairStatus.isSuccess &&
        price.isSuccess,
      refetchInterval: REFETCH_INTERVAL
    }
  )
}
