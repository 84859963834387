import React from 'react'
import { toUnscaled } from '../../utils/bn'
import { toTimeString } from '../../utils/string'
import { useLendingHistory } from '../../hooks/query/lending/useLendingHistory'
import { useLendingPosition } from '../../hooks/query/lending/useLendingSummary'
import { Addresses } from '../../constants/addresses'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'
import { PrimaryButton } from '../common/Button'
import { useWithdraw } from '../../hooks/contracts/useSupply'
import pendingStore from '../../store/pending'
import { LoadingIndicatorPt } from '../common/LoadingIndicator'
import { useWeb3Provider } from '../../hooks/useWeb3Provider'

const LendingHistory = ({
  chainId,
  account,
  assetId,
  isStable,
  addresses
}: {
  chainId: number
  account: string
  assetId: number
  isStable: boolean
  addresses: Addresses
}) => {
  const lendingHistory = useLendingHistory(
    chainId,
    account,
    addresses.Controller,
    assetId,
    isStable
  )

  return (
    <div className="text-xs font-normal">
      <table>
        <thead className="block font-normal text-left">
          <tr>
            <th className="w-40 pb-2">Time</th>
            <th className="w-36 pb-2">Symbol</th>
            <th className="w-36 pb-2">Side</th>
            <th className="w-36 pb-2">Quantity</th>
          </tr>
        </thead>
        <tbody className="block overflow-x-hidden overflow-y-scroll h-40 text-xs">
          {lendingHistory.lendingHistory.map((item, i) => {
            return (
              <tr className="h-8" key={i}>
                <td className="w-40 py-2">{toTimeString(item.timestamp)}</td>
                <td className="w-36">Lending</td>
                <td className="w-36">{item.action}</td>
                <td className="w-36">{item.assetAmount.toFixed(2)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const LendingPosition = ({
  chainId,
  assetId,
  addresses,
  isStable
}: {
  chainId: number
  assetId: number
  addresses: Addresses | undefined
  isStable: boolean
}) => {
  const { isPendingTx, setPendingTx } = pendingStore()
  const web3Provider = useWeb3Provider(chainId)
  const lendingPosition = useLendingPosition(chainId, assetId, isStable)

  const pairInfo = ASSET_INFOS[chainId][assetId]
  const pairGroupId = pairInfo.pairGroupId
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]

  const withdraw = useWithdraw()

  const onWithdrawAll = async () => {
    if (!lendingPosition.isSuccess) {
      throw new Error('value not loaded')
    }

    const tx = await withdraw.mutateAsync({
      assetId,
      isStable,
      supplyAmount: lendingPosition.data.mul(101).div(100)
    })

    await setPendingTx(tx)
  }

  const assetSymbol = isStable ? marginInfo.name : pairInfo.name

  return (
    <div className="p-4 rounded-2xl bg-secondaly text-xs font-semibold">
      <div className="mb-2 px-8 flex justify-between items-center space-x-10">
        <div className="flex justify-start items-center space-x-10">
          <div className="text-sm">Trade History</div>
          <div className="flex justify-center items-center space-x-10">
            <div className="flex flex-col space-y-2">
              <div className="text-subtext">Supplying {assetSymbol}</div>
              <div>
                {lendingPosition.isSuccess
                  ? toUnscaled(
                      lendingPosition.data,
                      isStable ? marginInfo.decimals : pairInfo.decimals
                    )
                  : 0}{' '}
                {assetSymbol}
              </div>
            </div>
          </div>
        </div>
        <div className="w-40 h-8">
          <PrimaryButton
            onClick={onWithdrawAll}
            disabled={
              isPendingTx ||
              (lendingPosition.isSuccess ? lendingPosition.data.eq(0) : true)
            }
          >
            {isPendingTx ? <LoadingIndicatorPt /> : 'Withdraw All'}
          </PrimaryButton>
        </div>
      </div>
      <div className="mt-2">
        {web3Provider.isSuccess &&
        web3Provider.data.isConnected &&
        addresses ? (
          <LendingHistory
            chainId={chainId}
            account={web3Provider.data.account}
            assetId={assetId}
            addresses={addresses}
            isStable={isStable}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default LendingPosition
