import React, { ReactNode } from 'react'
import ArbitrumIcon from '../../assets/chain/arbitrum.svg'
import BaseIcon from '../../assets/chain/base.svg'

const ChainDropdownMenuItem = ({
  children,
  onClick
}: {
  children: ReactNode
  onClick: () => void
}) => {
  return (
    <li
      className="w-full pl-4 pr-2 py-3 flex justify-start items-center space-x-4 hover:bg-[#2F3136]"
      onClick={onClick}
    >
      {children}
    </li>
  )
}

type Props = {
  isShow: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  onSelect: (chainTag: string) => void
}

export const ChainDropdownMenu = ({
  isShow,
  onMouseEnter,
  onMouseLeave,
  onSelect
}: Props) => {
  return (
    <ul
      className={`absolute mt-[2px] w-40 bg-[#1F2126] text-sm font-light z-20 cursor-pointer ${
        isShow ? 'visible' : 'invisible'
      }`}
      onMouseEnter={() => {
        onMouseEnter()
      }}
      onMouseLeave={() => {
        onMouseLeave()
      }}
    >
      <ChainDropdownMenuItem
        onClick={() => {
          onSelect('arbitrum')
        }}
      >
        <img src={ArbitrumIcon} width={22} />
        <div>Arbitrum</div>
      </ChainDropdownMenuItem>
      <ChainDropdownMenuItem
        onClick={() => {
          onSelect('base')
        }}
      >
        <img src={BaseIcon} width={22} />
        <div>Base</div>
      </ChainDropdownMenuItem>
    </ul>
  )
}
