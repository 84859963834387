import { useQuery } from 'react-query'
import { PairScalers } from '../utils/helpers/scaler'
import { STALE_TIME } from '../constants'

export function usePairContext(chainId: number, pairId: number) {
  return useQuery(
    ['pair_context', chainId, pairId],
    async () => {
      const scalers = new PairScalers(pairId, chainId)

      return scalers
    },
    {
      enabled: pairId > 0,
      staleTime: STALE_TIME
    }
  )
}
