import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { IRMParams } from '../../utils/irm'
import { DEFAULT_CHAIN } from '../../constants'

type AddPairParams = {
  pairGroupId: number
  uniswapPool: string
  poolOwner: string
  isIsolatedMode: boolean
  assetRiskParams: {
    riskRatio: BigNumber
    rangeSize: number
    rebalanceThreshold: number
  }
  stableIrmParams: IRMParams
  underlyingIrmParams: IRMParams
  fee: number
}

export function useAddPair() {
  const { chainId, account, provider } =
    useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses(chainId || DEFAULT_CHAIN)

  return useMutation(async (params: AddPairParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.addPair(params)
  })
}
