import { BigNumber } from 'ethers'
import {
  SLIPPAGE_TOLERANCE_LOWER_MAP,
  SLIPPAGE_TOLERANCE_NETWORK_MAP,
  SLIPPAGE_TOLERANCE_UPPER_MAP
} from '../constants'
import { toUnscaled } from './bn'

export function calculateGasMargin(estimatedGas: BigNumber) {
  return estimatedGas.mul(140).div(100)
}

// used for lending and strategy
export function calculateMargin(estimatedMargin: BigNumber) {
  return estimatedMargin.mul(1002).div(1000)
}

// slippage tolerance is 0.2%
export function computeLowerSqrtPrice(sqrtPrice: BigNumber, chainId: number) {
  // 0.2%
  // sqrtPrice * sqrt(1000/1002)
  //return sqrtPrice.mul(999001).div(1000000)
  const index = SLIPPAGE_TOLERANCE_NETWORK_MAP[chainId].index
  return sqrtPrice.mul(SLIPPAGE_TOLERANCE_LOWER_MAP[index]).div(1000000)
}

export function computeUpperSqrtPrice(sqrtPrice: BigNumber, chainId: number) {
  // 0.2%
  // sqrtPrice * sqrt(1002/1000)
  // return sqrtPrice.mul(1001000).div(1000000)
  const index = SLIPPAGE_TOLERANCE_NETWORK_MAP[chainId].index
  return sqrtPrice.mul(SLIPPAGE_TOLERANCE_UPPER_MAP[index]).div(1000000)
}

export function roundContractSize(tradeAmount: BigNumber) {
  return Math.round(toUnscaled(tradeAmount.mul(1000), 18, 3)) / 1000
}

export function roundToMinutes(currentTimestamp: number) {
  return Math.floor(currentTimestamp / 60)
}

export function convertNotionalToString(value: number) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(2) + 'm'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + 'k'
  } else {
    return value.toFixed(2)
  }
}

export function roundMargin(rounder: number, amount: BigNumber) {
  const rounderScaler = BigNumber.from(10).pow(rounder)

  return amount.div(rounderScaler).mul(rounderScaler)
}

export function recoverSize(
  squartAmount: BigNumber,
  squartDecimals: number,
  sizeToSquart: number
) {
  const defaultSize =
    Math.ceil((toUnscaled(squartAmount, squartDecimals) / sizeToSquart) * 100) /
    100

  return defaultSize
}
