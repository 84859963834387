import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { GammaShortStrategy__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useCachedPrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { useChainId } from '../network'

type DepositToStrategyParams = {
  strategyId: number
  strategyTokenAmount: BigNumberish
  maxDepositAmount: BigNumberish
}

export function useDepositToStrategy(assetId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const deadline = useDeadline()
  const chainId = useChainId()
  const addresses = useAddresses(chainId)
  const price = useCachedPrice(chainId, assetId)

  return useMutation(async (params: DepositToStrategyParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!chainId) throw new Error('chainId not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')

    const contract = GammaShortStrategy__factory.connect(
      addresses.GammaShortStrategy,
      provider.getSigner()
    )

    return await contract.deposit(
      params.strategyId,
      params.strategyTokenAmount,
      account,
      params.maxDepositAmount,
      false,
      {
        lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
        upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
        deadline: deadline.data
      }
    )
  })
}
