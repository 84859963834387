/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type { TickMath, TickMathInterface } from '../TickMath'

const _abi = [
  {
    inputs: [],
    name: 'R',
    type: 'error'
  },
  {
    inputs: [],
    name: 'T',
    type: 'error'
  }
]

const _bytecode =
  '0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212204705f69ee51b3f7c39e29a3a81e6ec9d62e11f811b92825b8e113af0a58ab90564736f6c63430008130033'

export class TickMath__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<TickMath> {
    return super.deploy(overrides || {}) as Promise<TickMath>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): TickMath {
    return super.attach(address) as TickMath
  }
  connect(signer: Signer): TickMath__factory {
    return super.connect(signer) as TickMath__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): TickMathInterface {
    return new utils.Interface(_abi) as TickMathInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TickMath {
    return new Contract(address, _abi, signerOrProvider) as TickMath
  }
}
