import React from 'react'
import { BigNumber } from 'ethers'
import { PositionStyle } from '../../../constants/enum'

export function getStylePrice(
  squartSide: boolean,
  style: PositionStyle,
  price: BigNumber
) {
  if (style === PositionStyle.Neutral) {
    return price
  } else if (
    (!squartSide && style === PositionStyle.Bearish25) ||
    (squartSide && style === PositionStyle.Bullish25)
  ) {
    return price.mul(1000).div(1025)
  } else if (
    (!squartSide && style === PositionStyle.Bullish25) ||
    (squartSide && style === PositionStyle.Bearish25)
  ) {
    return price.mul(1025).div(1000)
  } else {
    throw new Error('unknown style')
  }
}

type Props = {
  style: PositionStyle
  onChange: (style: PositionStyle) => void
}

const StyleSelector = ({ style, onChange }: Props) => {
  return (
    <button className="w-full flex justify-between space-x-2 text-base">
      <div
        className={`w-24 px-2 py-[2px] text-center font-normal rounded-md bg-red border-[3px] ${
          style === PositionStyle.Bearish25 ? 'border-white' : 'border-red'
        }`}
        onClick={() => {
          onChange(PositionStyle.Bearish25)
        }}
      >
        ▼Down
      </div>
      <div
        className={`w-24 px-2 py-[2px] text-center font-normal rounded-md bg-subtext border-[3px] ${
          style === PositionStyle.Neutral ? 'border-white' : 'border-subtext'
        }`}
        onClick={() => {
          onChange(PositionStyle.Neutral)
        }}
      >
        &nbsp;Stable
      </div>
      <div
        className={`w-24 px-2 py-[2px] text-center font-normal rounded-md bg-green border-[3px] ${
          style === PositionStyle.Bullish25 ? 'border-white' : 'border-green'
        }`}
        onClick={() => {
          onChange(PositionStyle.Bullish25)
        }}
      >
        ▲Up
      </div>
    </button>
  )
}

export default StyleSelector
