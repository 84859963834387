import React, { useEffect } from 'react'
import StrategyTradeForm from '../components/strategy/StrategyTradeForm'
import StrategyPosition from '../components/strategy/StrategyPosition'
import strategyStore from '../store/strategy'
import StrategyCenterArea from '../components/strategy/StrategyCenterArea'
import { useTradeRoute } from '../hooks/useTradeRoute'
import { ASSET_INFOS, MARGIN_INFOS, STRATEGY_INFOS } from '../constants/assets'
import NotFound from '../components/NotFound'
import { useStrategySummary } from '../hooks/query/strategy/useStrategySummary'

const TradeStrategyView = ({
  chainId,
  pairGroupId,
  strategyId,
  setStrategyId
}: {
  chainId: number
  pairGroupId: number
  strategyId: number
  setStrategyId: (pairId: number) => void
}) => {
  const { selectedStrategyId, selectStrategyId } = strategyStore()
  const strategySummary = useStrategySummary(chainId, selectedStrategyId)

  useEffect(() => {
    const strategyInfo = STRATEGY_INFOS[chainId][strategyId]

    if (strategyInfo) {
      const pairInfo = ASSET_INFOS[chainId][strategyInfo.pairId]

      if (pairGroupId === pairInfo.pairGroupId) {
        if (strategyId !== selectedStrategyId) {
          selectStrategyId(strategyId)
        }
        return
      }
    }

    const defaultStrategyId = MARGIN_INFOS[chainId][pairGroupId].strategies[0]

    setStrategyId(defaultStrategyId)
  }, [
    chainId,
    pairGroupId,
    strategyId,
    selectStrategyId,
    setStrategyId,
    selectedStrategyId
  ])

  useEffect(() => {
    if (strategySummary.isSuccess) {
      const strategyInfo = STRATEGY_INFOS[chainId][strategyId]

      document.title = `${strategySummary.data.price} | ${strategyInfo.name} | Strategy | Predy V5`
    }
  }, [
    chainId,
    strategyId,
    pairGroupId,
    strategySummary.isSuccess,
    strategySummary.data
  ])

  if (!STRATEGY_INFOS[chainId][selectedStrategyId]) {
    return <NotFound />
  }

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-trade grid-rows-trade-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2 mx-1 md:ml-2">
        <StrategyTradeForm chainId={chainId} strategyId={selectedStrategyId} />
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <StrategyCenterArea chainId={chainId} strategyId={selectedStrategyId} />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="mx-5">
          <StrategyPosition chainId={chainId} strategyId={selectedStrategyId} />
        </div>
      </div>
    </div>
  )
}

const TradeStrategyViewWrapper = () => {
  const tradeRoute = useTradeRoute()

  if (tradeRoute.isNotFound || tradeRoute.pairGroupId === undefined) {
    return <NotFound />
  }
  return (
    <TradeStrategyView
      chainId={tradeRoute.chainId}
      pairGroupId={tradeRoute.pairGroupId}
      strategyId={tradeRoute.pairId}
      setStrategyId={tradeRoute.setPairId}
    />
  )
}

export default TradeStrategyViewWrapper
