import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryButton } from '../common/Button'
import { LoadingIndicatorPt } from '../common/LoadingIndicator'
import pendingStore from '../../store/pending'
import positionStore from '../../store/position'
import gammaStore from '../../store/gamma'
import { toUnscaled } from '../../utils/bn'
import { InterestEstPanel } from '../main/InterestEstPanel'
import { Vault, useVault } from '../../hooks/query/useVault'
import StrangleSideSelector from '../main/trade/StrangleSideSelector'
import StyleSelector from '../main/trade/StyleSelector'
import SizeForm from '../main/trade/SizeForm'
import { ZERO } from '../../constants'
import { useSlippageTolerance } from '../../hooks/query/common/slippage'
import { checkIsTradeFirstTime } from '../../utils/positions'
import TradeInfo from '../tooltips/TradeInfo'
import SizeInfo from '../tooltips/SizeInfo'
import MarketViewInfo from '../tooltips/MarketViewInfo'
import TradeSummaryInfo from '../tooltips/TradeSummaryInfo'
import { LeverageForm } from '../main/trade/LeverageForm'
import { useOpenIsolatedVault } from '../../hooks/contracts/useOpenIsolatedVault'
import { useCloseIsolatedVault } from '../../hooks/contracts/useCloseIsolatedVault'
import { PairScalers } from '../../utils/helpers/scaler'
import { useTradeAfter } from '../../hooks/query/useTradeAfter'
import { useFeeEst } from '../../hooks/query/interest/useFeeEst'
import { BigNumber } from 'ethers'
import { recoverSize } from '../../utils'
import { TradeErrorArea } from '../main/trade/TradeErrorArea'
import { ASSET_INFOS, MARGIN_INFOS } from '../../constants/assets'

function getBeforeSqrtAmount(assetId: number, vault?: Vault) {
  const openPositions = (vault?.openPositions || []).filter(
    openPosition => openPosition.assetId === assetId
  )

  const squartAmounts = openPositions.map(
    openPosiition => openPosiition.sqrtPerpPosition
  )

  return squartAmounts.length > 0 ? squartAmounts[0] : ZERO
}

const EstimatedLifetime = ({
  chainId,
  vaultId,
  assetId,
  tradeAmountPerp,
  tradeAmountSqrt,
  avaiableMargin
}: {
  chainId: number
  vaultId: number
  assetId: number
  tradeAmountPerp: BigNumber
  tradeAmountSqrt: BigNumber
  avaiableMargin: BigNumber
}) => {
  const feeEstQuery = useFeeEst(
    chainId,
    assetId,
    vaultId ? [vaultId] : [],
    tradeAmountPerp,
    tradeAmountSqrt
  )

  if (!feeEstQuery.isSuccess) {
    return <div>- days</div>
  }

  if (feeEstQuery.data.total.eq(0)) {
    return <div>- days</div>
  }

  return (
    <>
      <div>Live until est.</div>
      <div>
        {(
          avaiableMargin
            .mul(24)
            .div(feeEstQuery.data?.total)
            .mul(-1)
            .toNumber() / 24
        ).toFixed(1)}{' '}
        days
      </div>
    </>
  )
}

const IsolatedTradeForm = ({
  chainId,
  pairGroupId,
  vaultId,
  assetId
}: {
  chainId: number
  pairGroupId: number
  vaultId: number
  assetId: number
}) => {
  const { isPendingTx, setPendingTx } = pendingStore()
  const { afterMargin, setAfterAmounts, setAfterEntryUpdate } = positionStore()
  const { targetStrategy, setTargetStrategy } = gammaStore()

  const vault = useVault(chainId, vaultId)
  const slippageTolerance = useSlippageTolerance(chainId)

  const [squartSide, setSquartSide] = useState(false)
  const [size, setSize] = useState(0)
  const [leverage, setLeverage] = useState(1)

  const scalers = new PairScalers(assetId, chainId)
  const marginInfo = MARGIN_INFOS[chainId][pairGroupId]
  const pairInfo = ASSET_INFOS[chainId][assetId]

  const tradeAfterResult = useTradeAfter(
    chainId,
    vaultId,
    pairGroupId,
    assetId,
    squartSide,
    size,
    targetStrategy,
    leverage
  )

  const squartDecimals = scalers.squartDecimals
  const underlyingDecimals = scalers.underlyingDecimals

  const afterMarginAmount = afterMargin || ZERO

  useEffect(() => {
    if (tradeAfterResult.isSuccess) {
      setAfterAmounts(
        tradeAfterResult.data.sqrtAmount,
        tradeAfterResult.data.perpAmount,
        tradeAfterResult.data.afterMargin || ZERO
      )

      if (tradeAfterResult.data.quoterResult.data) {
        const quoterResult = tradeAfterResult.data.quoterResult.data
        const entryUpdate = quoterResult.perpEntryUpdate
          .add(quoterResult.sqrtEntryUpdate)
          .add(quoterResult.perpPayoff)
          .add(quoterResult.sqrtPayoff)

        setAfterEntryUpdate(entryUpdate)
      } else {
        setAfterEntryUpdate(tradeAfterResult.data.expectedEntryUpdate)
      }
    }
  }, [
    tradeAfterResult.isSuccess,
    tradeAfterResult.data,
    setAfterAmounts,
    setAfterEntryUpdate
  ])

  const beforeSqrtAmount = getBeforeSqrtAmount(assetId, vault.data)

  useEffect(() => {
    const defaultSize = recoverSize(
      beforeSqrtAmount.mul(2).abs(),
      squartDecimals,
      marginInfo.sizeToSquart
    )

    setSize(defaultSize)
    setSquartSide(beforeSqrtAmount.lt(0))
  }, [beforeSqrtAmount.toString()])

  const tradeOpen = useOpenIsolatedVault(assetId)
  const tradeClose = useCloseIsolatedVault(assetId)

  const onTrade = useCallback(async () => {
    if (tradeAfterResult.data && tradeAfterResult.data.tradeMarginAmount) {
      if (tradeAfterResult.data.tradeMarginAmount.gt(ZERO)) {
        const tx = await tradeOpen.mutateAsync({
          marginAmount: tradeAfterResult.data.tradeMarginAmount,
          vaultId,
          assetId,
          tradeAmount: tradeAfterResult.data.tradeAmountPerp,
          tradeAmountSqrt: tradeAfterResult.data.tradeAmountSqrt
        })

        setPendingTx(tx)
      } else {
        const tx = await tradeClose.mutateAsync({
          marginAmount: tradeAfterResult.data.tradeMarginAmount.mul(-1),
          isolatedVaultId: vaultId,
          assetId,
          tradeAmount: tradeAfterResult.data.tradeAmountPerp,
          tradeAmountSqrt: tradeAfterResult.data.tradeAmountSqrt
        })

        setPendingTx(tx)
      }
    }
  }, [
    vaultId,
    assetId,
    tradeAfterResult.data,
    tradeOpen,
    tradeClose,
    setPendingTx
  ])

  const isTradeFirstTime = !(
    vault.isSuccess && !checkIsTradeFirstTime(vault.data, assetId)
  )

  const tradeAmountPerp = tradeAfterResult.data?.tradeAmountPerp || ZERO
  const tradeAmountSqrt = tradeAfterResult.data?.tradeAmountSqrt || ZERO

  const marginUtilizing: BigNumber | undefined =
    tradeAfterResult.isSuccess && tradeAfterResult.data.quoterResult.data
      ? tradeAfterResult.data.quoterResult.data.minDeposit
      : tradeAfterResult.isSuccess
      ? tradeAfterResult.data.minDeposit
      : undefined

  const liqPrices = tradeAfterResult.isSuccess
    ? tradeAfterResult.data.liqPrices
    : [ZERO, ZERO]

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] p-5 rounded-3xl bg-white0 border-[1px] border-white5 leading-5">
        <div className="mx-0 my-1 p-3 rounded-xl bg-black4 shadow-sm">
          <div className="flex justify-start text-base text-subtext">
            <span>
              {isTradeFirstTime ? 'Create Position' : 'Update Position'}
            </span>
            <TradeInfo isTradeFirstTime={isTradeFirstTime} />
          </div>
          <StrangleSideSelector side={squartSide} onChange={setSquartSide} />
        </div>
        <div className="mx-0 my-1 p-4 rounded-xl bg-black4 shadow-sm">
          <div className="flex justify-start text-base text-subtext mb-1">
            Market View
            <MarketViewInfo />
          </div>
          <StyleSelector style={targetStrategy} onChange={setTargetStrategy} />
        </div>
        <div className="mx-0 my-1 p-4 rounded-xl bg-black4 shadow-sm flex justify-between items-center space-x-2">
          <span className="flex justify-start text-base text-subtext">
            Size
            <SizeInfo
              symbol={pairInfo.name}
              sizeToSquart={marginInfo.sizeToSquart}
            />
          </span>
          <SizeForm amount={size} onChange={setSize} />
        </div>
        <div className="mx-0 my-1 p-4 rounded-xl bg-black4 shadow-sm flex justify-between items-center space-x-2">
          <span className="flex justify-start text-base text-subtext">
            Leverage
          </span>
          <LeverageForm
            leverage={leverage}
            onChange={setLeverage}
            maxLeverage={pairInfo.maxLeverage}
          />
        </div>
      </div>
      <div className="mx-0 my-5 px-5">
        <div className="flex justify-start items-center space-x-1">
          <div className="flex justify-center items-center rounded-full p-[6px] bg-green" />
          <div className="flex justify-center items-center">
            {isTradeFirstTime ? 'After Created' : 'After Updated'}
          </div>
        </div>
        <InterestEstPanel
          chainId={chainId}
          pairId={assetId}
          vaultId={vaultId}
          isSuccess={
            tradeAfterResult.isSuccess &&
            (!!tradeAfterResult.data.quoterResult.data ||
              tradeAfterResult.data.quoterResult.error.indexOf('V1') >= 0)
          }
          trade={{
            tradeAmountPerp,
            tradeAmountSqrt
          }}
        />

        <div className="mt-2 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Margin Utilizing</div>
            <div>
              {marginUtilizing
                ? toUnscaled(
                    marginUtilizing,
                    scalers.marginDecimals,
                    marginInfo.fractionDigits
                  )
                : '-'}
            </div>
          </div>

          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Margin Amount</div>
            <div>
              {toUnscaled(
                afterMarginAmount,
                scalers.marginDecimals,
                marginInfo.fractionDigits
              )}
            </div>
          </div>

          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            {!squartSide ? (
              <>
                <div>Liquidation Price est.</div>
                <div>
                  {liqPrices[0].eq(0)
                    ? '-'
                    : scalers.toPriceString(liqPrices[0])}
                  ,{' '}
                  {liqPrices[1].eq(0)
                    ? '-'
                    : scalers.toPriceString(liqPrices[1])}
                </div>
              </>
            ) : (
              <>
                <EstimatedLifetime
                  chainId={chainId}
                  assetId={assetId}
                  vaultId={vaultId}
                  tradeAmountPerp={tradeAmountPerp}
                  tradeAmountSqrt={tradeAmountSqrt}
                  avaiableMargin={
                    marginUtilizing
                      ? afterMarginAmount.sub(marginUtilizing)
                      : ZERO
                  }
                />
              </>
            )}
          </div>

          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Gamma, Delta</div>
            <div>
              <span>
                {tradeAfterResult.isSuccess
                  ? tradeAfterResult.data?.gamma.toFixed(4)
                  : ''}
              </span>
              <span>, </span>
              <span>
                {tradeAfterResult.isSuccess
                  ? tradeAfterResult.data?.delta.toFixed(2)
                  : ''}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2 flex justify-start">
          Trade Summary
          <TradeSummaryInfo symbol={pairInfo.name} />
        </div>

        <div className="mt-2 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Structure</div>
            <div>
              {tradeAmountSqrt.lt(0) ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              √{pairInfo.name}{' '}
              {toUnscaled(tradeAmountSqrt.mul(2), squartDecimals, 2)},{' '}
              {tradeAmountPerp.lt(0) ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              {pairInfo.name}{' '}
              {toUnscaled(tradeAmountPerp, underlyingDecimals, 2)}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Price</div>
            <div>
              {scalers.toPriceString(
                tradeAfterResult.data?.quoterResult.data?.sqrtEntryPrice.div(2)
              )}
              ,{' '}
              {scalers.toPriceString(
                tradeAfterResult.data?.quoterResult.data?.perpEntryPrice
              )}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Max Slippage</div>
            <div>{slippageTolerance}%</div>
          </div>
        </div>
      </div>

      <div className="mx-5 my-5 h-12">
        <PrimaryButton
          onClick={onTrade}
          disabled={
            isPendingTx ||
            !!(
              tradeAfterResult.isSuccess &&
              tradeAfterResult.data.quoterResult.error
            )
          }
        >
          {isPendingTx ? <LoadingIndicatorPt /> : 'Trade'}
        </PrimaryButton>
      </div>
      {tradeAfterResult.isSuccess &&
      tradeAfterResult.data.quoterResult.error ? (
        <TradeErrorArea error={tradeAfterResult.data.quoterResult.error} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default IsolatedTradeForm
