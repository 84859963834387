import { useQuery as useReactQuery } from 'react-query'
import { useQuery } from '@apollo/client'
import { VaultEntities, VAULTS_QUERY } from '../../queries/vaultQuery'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useMemo } from 'react'
import { STALE_TIME } from '../../constants'
import { PredyClient } from '../../utils/apollo-client'

export type ExtraOpenPosition = {
  id: number
  assetId: number
  perpUpdatedAt: number
  squartUpdatedAt: number
  createdAt: number
  updatedAt: number
}

export type ExtraVaultStatus = {
  vaultId: number
  owner: string
  margin: BigNumber
  isMainVault: boolean
  openPositions: ExtraOpenPosition[]
}

type ExtraVaultResult =
  | { isAvailable: false; mainVault: null; isolatedVaults: [] }
  | {
      isAvailable: true
      mainVault: ExtraVaultStatus
      isolatedVaults: ExtraVaultStatus[]
    }

export function useExtraVaultStatus(
  chainId: number,
  pairId: number,
  isIsolated: boolean
) {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const extraStatusList = useExtraVaultStatusList(chainId, account)

  return useReactQuery(
    ['ex_vault_status', account, pairId, isIsolated],

    async () => {
      if (!extraStatusList.isAvailable)
        throw new Error('extraStatusList is not available')

      const extraPositions = isIsolated
        ? extraStatusList.isolatedVaults
            .map(vault => vault.openPositions)
            .flat()
        : extraStatusList.mainVault.openPositions

      return extraPositions?.find(
        openPosition => openPosition.assetId === pairId
      )
    },
    {
      enabled: !!account && extraStatusList.isAvailable,
      staleTime: STALE_TIME
    }
  )
}

export function useExtraVaultStatusList(
  chainId: number,
  account: string | undefined
): ExtraVaultResult {
  const { data } = useQuery<VaultEntities>(VAULTS_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      owner: (account || '').toLowerCase()
    },
    client: PredyClient[chainId]
  })

  const extraVaultStatus = useMemo(() => {
    if (data) {
      const ownVaults: ExtraVaultStatus[] = data.vaultEntities.map(entity => {
        const margin = BigNumber.from(entity.margin)

        return {
          vaultId: Number(entity.vaultId),
          owner: entity.owner,
          margin,
          isMainVault: entity.isMainVault,
          openPositions: entity.openPositions.map(openPosition => {
            return {
              id: Number(openPosition.id),
              assetId: Number(openPosition.pair.pairId),
              perpUpdatedAt: Number(openPosition.perpUpdatedAt),
              squartUpdatedAt: Number(openPosition.squartUpdatedAt),
              createdAt: Number(openPosition.createdAt),
              updatedAt: Number(openPosition.updatedAt)
            }
          })
        }
      })

      const mainVault = ownVaults.find(vault => vault.isMainVault)
      const isolatedVaults = ownVaults.filter(vault => !vault.isMainVault)

      return {
        mainVault,
        isolatedVaults
      }
    }

    return null
  }, [data])

  if (
    data === undefined ||
    extraVaultStatus === null ||
    extraVaultStatus.mainVault === undefined
  ) {
    return {
      isAvailable: false,
      mainVault: null,
      isolatedVaults: []
    }
  }

  return {
    isAvailable: true,
    mainVault: extraVaultStatus.mainVault,
    isolatedVaults: extraVaultStatus.isolatedVaults
  }
}
