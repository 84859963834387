import { useState, useEffect } from 'react'
import { hooks, metaMask } from '../connectors'

const { useIsActive } = hooks

export function useEagerConnect() {
  const isActive = useIsActive()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('predy.connected') !== null) {
      metaMask.connectEagerly().catch(error => {
        console.log(error)
      })
    }
    setTried(true)
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && isActive) {
      setTried(true)
    }
  }, [tried, isActive])

  return tried
}
