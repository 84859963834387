import React from 'react'
import { useStrategySummary } from '../../hooks/query/strategy/useStrategySummary'
import PriceChart from '../common/PriceChart'
import ChartTop from '../main/center/ChartTop'
import { StrategySelector } from './StrategySelector'
import InfoTooltip from '../common/InfoTooltip'
import { useStrategyPrices } from '../../hooks/query/strategy/useStrategyPrices'
import { STRATEGY_INFOS } from '../../constants/assets'

const StrategyCenterArea = ({
  chainId,
  strategyId
}: {
  chainId: number
  strategyId: number
}) => {
  const strategySummary = useStrategySummary(chainId, strategyId)

  const strategyInfo = STRATEGY_INFOS[chainId][strategyId]
  const assetId = strategyInfo.pairId

  const priceData = useStrategyPrices(chainId, strategyId)

  return (
    <div>
      <ChartTop chainId={chainId} assetId={assetId}>
        <StrategySelector chainId={chainId} />
      </ChartTop>
      <div className="mt-4 flex justify-center">
        <div className="w-[696px] flex justify-start space-x-2">
          <h1 className="text-3xl font-bold">Strategy Trading</h1>
          <InfoTooltip placement="bottom">
            <p className="text-center">
              Strategy trade offers the opportunity to put USDC in
              Auto-Delta-hedge&apos;s Gamma short vault with Buy the unit
              &quot;Strategy&quot;. Strategy = Squart with Delta-hedge taking
              place in the display time interval. Note that this is a swap
              between Strategy(ERC20) and USDC in Wallet.
            </p>
          </InfoTooltip>
        </div>
      </div>
      <div className="mt-8 flex justify-center items-start space-x-2">
        <div className="w-[596px] h-full">
          <PriceChart height={288} data1={priceData.data || []} />
        </div>
        <div className="w-[146px] h-full flex flex-col space-y-2">
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Next hedge in</div>
            <div className="text-xl text-right">
              {strategySummary.data?.nextHedgeIn.hours || '0'}H{' '}
              {strategySummary.data?.nextHedgeIn.minutes || '0'}M{' '}
              {strategySummary.data?.nextHedgeIn.seconds || '0'}S<br />
            </div>
          </div>

          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Last Hedge Price</div>
            <div className="text-xl">
              {strategySummary.data?.lastHedgePrice}
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Total issuing</div>
            <div className="text-xl">{strategySummary.data?.totalIssuing}</div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Issuing cap</div>
            <div className="text-xl">{strategySummary.data?.issueingCap}</div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Squart/Strategy</div>
            <div className="text-xl">{strategyInfo.squartPerSt}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StrategyCenterArea
