import React, { useEffect, useState } from 'react'
import { useFeeEst } from '../../hooks/query/interest/useFeeEst'
import { BigNumber } from 'ethers'
import { ZERO } from '../../constants'
import InfoTooltip from '../common/InfoTooltip'
import { usePairContext } from '../../hooks/usePairContext'

type Prop = {
  chainId: number
  pairId: number
  vaultId?: number
  isSuccess: boolean
  trade?: {
    tradeAmountSqrt: BigNumber
    tradeAmountPerp: BigNumber
  }
}

const InterestEstBreakdown = ({
  chainId,
  pairId,
  feeEst
}: {
  chainId: number
  pairId: number
  feeEst: {
    total: BigNumber
    totalInterestEst: BigNumber
    totalPremiumEst: BigNumber
  }
}) => {
  const pairContext = usePairContext(chainId, pairId)

  return (
    <InfoTooltip placement="top-end">
      <div className="text-center space-y-2">
        <div>Breakdown</div>
        <div className="flex justify-center space-x-4">
          <div className="text-left">Interest</div>
          <div className="text-right">
            {pairContext.isSuccess
              ? pairContext.data.toMarginString(feeEst.totalInterestEst)
              : 0}
          </div>
        </div>
        <div className="flex justify-center space-x-4">
          <div className="text-left">Premium</div>
          <div className="text-right">
            {pairContext.isSuccess
              ? pairContext.data.toMarginString(feeEst.totalPremiumEst)
              : 0}
          </div>
        </div>
      </div>
    </InfoTooltip>
  )
}

export const InterestEstPanel = ({ chainId, pairId, vaultId, trade }: Prop) => {
  const pairContext = usePairContext(chainId, pairId)

  const [feeEst, setFeeEst] = useState({
    total: ZERO,
    totalInterestEst: ZERO,
    totalPremiumEst: ZERO
  })

  const feeEstQuery = useFeeEst(
    chainId,
    pairId,
    vaultId ? [vaultId] : [],
    trade ? trade.tradeAmountPerp : ZERO,
    trade ? trade.tradeAmountSqrt : ZERO
  )

  useEffect(() => {
    if (feeEstQuery.isSuccess) {
      setFeeEst(feeEstQuery.data)
    }
  }, [feeEstQuery.isSuccess, feeEstQuery.data])

  return (
    <div className="w-full mt-2 flex justify-between items-end text-2xl">
      <div
        className={`font-semibold whitespace-nowrap ${
          feeEst.total.gte(0) ? 'text-green' : 'text-red'
        }`}
      >
        {feeEst.total.gte(0) ? 'GET +' : 'PAY -'}
        {pairContext.isSuccess
          ? pairContext.data.toMarginString(feeEst.total.abs(), {
              showFull: false,
              symbolRequired: true
            })
          : 0}
      </div>
      <div className="font-semibold text-white5 whitespace-nowrap text-base">
        Daily est.
      </div>
      <InterestEstBreakdown chainId={chainId} pairId={pairId} feeEst={feeEst} />
    </div>
  )
}
