import { useQuery } from 'react-query'
import { STALE_TIME } from '../../../constants'
import { AggregationInterval } from '../../../queries/aggregatedPriceQuery'
import { useAddresses } from '../../useAddress'
import { toUnscaled } from '../../../utils/bn'
import { getNow } from '../../../utils/time'
import { GammaShortStrategy__factory } from '../../../typechain'
import { useWeb3Provider } from '../../useWeb3Provider'
import { BigNumber } from 'ethers'

const SUBGRAPH_ENDPOINT_ARBITRUM =
  'https://gateway-arbitrum.network.thegraph.com/api/8a5b8a52f2a8383c47be7e623c1c296d/subgraphs/id/Ab6Yh29tjhvhSFKbUYdXLYMnf3aiL8BUKdErNTLGx8b1'

const SUBGRAPH_ENDPOINT_BASE =
  'https://api.studio.thegraph.com/query/47113/predy-v5-fee-base-mainnet/version/latest'

const SUBGRAPH_ENDPOINTS: { [key: number]: string } = {
  8453: SUBGRAPH_ENDPOINT_BASE,
  42161: SUBGRAPH_ENDPOINT_ARBITRUM
}

async function queryStrategyPrices(
  chainId: number,
  contractAddress: string,
  strategyId: number,
  interval: string,
  first: number
) {
  const body = {
    variables: {
      address: contractAddress.toLowerCase(),
      strategyId,
      interval,
      first
    },
    query: `query ($address: String, $strategyId: Int, $interval: String, $first: Int) {
      aggregatedUniswapPriceEntities(
        first: $first
        where: { strategyId: $strategyId, interval: $interval }
        orderBy: openTimestamp
        orderDirection: desc
      ) {
        id
        address
        strategyId
        interval
        openPrice
        closePrice
        openTimestamp
        closeTimestamp
        __typename
      }
    }`
  }

  const res = await fetch(SUBGRAPH_ENDPOINTS[chainId], {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  const resBody = await res.json()

  const pricesData: any[] = resBody.data.aggregatedUniswapPriceEntities

  return pricesData
    .map(item => ({
      address: item.address,
      strategyId: Number(item.strategyId),
      openPrice: BigNumber.from(item.openPrice),
      closePrice: BigNumber.from(item.closePrice),
      openTimestamp: Number(item.openTimestamp),
      timestamp: Number(item.closeTimestamp)
    }))
    .filter(
      data => ![2, 4].includes(data.strategyId) || data.timestamp > 1689400000
    )
    .filter(data => data.address === contractAddress.toLowerCase())
    .filter(data => !data.openPrice.eq(0) && !data.closePrice.eq(0))
    .reverse()
}

export function useStrategyPriceHistory(
  chainId: number,
  strategyId: number,
  interval: string,
  first: number
) {
  const addresses = useAddresses(chainId)

  return useQuery(
    ['st_price_history', chainId, strategyId, interval, first],

    async () => {
      const pricesData = await queryStrategyPrices(
        chainId,
        addresses.GammaShortStrategy,
        strategyId,
        interval,
        first
      )

      return pricesData
    },

    {
      enabled: true,
      staleTime: STALE_TIME
    }
  )
}

export function useStrategyPrices(chainId: number, strategyId: number) {
  const web3Provider = useWeb3Provider(chainId)
  const addresses = useAddresses(chainId)
  const priceHistory = useStrategyPriceHistory(
    chainId,
    strategyId,
    AggregationInterval.Daily,
    60
  )

  return useQuery(
    ['st_prices', chainId, strategyId],

    async () => {
      if (!web3Provider.isSuccess) throw new Error('web3Provider not set')
      if (!priceHistory.isSuccess) throw new Error('priceHistory not set')

      const { provider } = web3Provider.data

      const contract = GammaShortStrategy__factory.connect(
        addresses.GammaShortStrategy,
        provider
      )

      const price = await contract.callStatic.getPrice(strategyId)

      const data = priceHistory.data
        .map(d => [d.openTimestamp * 1000, toUnscaled(d.openPrice, 18)])
        .concat([[getNow() * 1000, toUnscaled(price, 18)]])

      return data
    },

    {
      enabled: web3Provider.isSuccess && priceHistory.isSuccess,
      staleTime: STALE_TIME
    }
  )
}
